import React from 'react';
import AppRoutes from './AppRoutes';
import './App.scss';
import LoadingScreen from './screens/LoadingScreen';
import useAuth from './utils/auth/useAuth';
import LoginScreen from './screens/LoginScreen';

// This is the main functional component
const App: React.FC = (): JSX.Element => {
  // destructuring from useAuth hook
  const { isLoading, isAuthenticated } = useAuth();

  // display loading while checking if user is logged in or not
  if (isLoading) {
    return <LoadingScreen />;
  }

  // if user is authenticated, then allow access to app
  if (isAuthenticated) return <AppRoutes />;

  /* if user is not authenticated, then showing login screen with internal and external login buttons.
     These buttons redirect to respective SSO pages */
  return <LoginScreen />;
};

export default App;
