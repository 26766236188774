import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Spin, message } from 'antd';
import * as yup from 'yup';
import { measurementsSystemWithDefaultUnits, unitLabels } from '@ecp32/calculations';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from '../../components/Select';
import {
  Enum_Measurement_System_Enum,
  Enum_User_Role_Enum,
  Farm,
  FarmOptionsQuery,
  FarmOptionsQueryVariables,
  UpdateFarmSettingsMutation,
  UpdateFarmSettingsMutationVariables,
} from '../../graphql/graphql-types';
import FormItem from '../../components/FormItem';
import InputNumber from '../../components/InputNumberComponent';
import measurementSystemOptions from '../../utils/colostrum-replacer/measurementSystemOptions';
import { logger } from '../../utils/helpers';

const farmOptionsQuery = loader('../../graphql/queries/farmOptionsQuery.graphql');
const updateFarmSettingsMutation = loader(
  '../../graphql/mutations/updateFarmSettingsMutation.graphql',
);

// type for farm settings form
type FarmSettingsFormType = Pick<Farm, 'col_rep_measurement_system_id' | 'id'> & {
  // type definition for colostrum max first feeding
  colostrum_max_first_fdg: number | null;
  // type definition for colostrum max second feeding
  colostrum_max_second_fdg: number | null;
};

// const to define labelColSpan and inputCol span value
const labelInputSpan = {
  labelColSpan: 4,
  inputColSpan: 20,
};

// validation schema for farm settings fields
const schema = yup.object().shape({
  id: yup.string().required('Please select farm Id'),
  col_rep_measurement_system_id: yup.string().required('Please select unit').nullable(),
  colostrum_max_first_fdg: yup
    .number()
    .min(2, 'Out of normal range')
    .max(5, 'Out of normal range')
    .required('Please enter desired max first feeding ')
    .nullable(),
  colostrum_max_second_fdg: yup
    .number()
    .max(
      yup.ref('colostrum_max_first_fdg'),
      'This must be less than or equal to desired max first feeding',
    )
    .min(1, 'Out of normal range')
    .required('Please enter desired max second feeding')
    .nullable(),
});

// react functional component
const ColostrumReplacerSettings: React.FC = () => {
  // Query to fetch all farms data
  const { loading, data, error } = useQuery<FarmOptionsQuery, FarmOptionsQueryVariables>(
    farmOptionsQuery,
    {
      fetchPolicy: 'network-only',
      context: {
        /** Explicitly passing x-hasura role to handle farm so that user can perform calculations on farms which are created by them only */
        headers: {
          'x-hasura-role': Enum_User_Role_Enum.User,
        },
      },
    },
  );

  // mutation to update farm settings
  const [updateFarmSettings] = useMutation<
    UpdateFarmSettingsMutation,
    UpdateFarmSettingsMutationVariables
  >(updateFarmSettingsMutation);

  // state use to show loading indicator on save button
  const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false);

  // navigate variable contain useNavigate hooks
  const navigate = useNavigate();

  // extracting farm id param from useParam
  const { farmId } = useParams();

  // useForm declaration
  const {
    control,
    clearErrors,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FarmSettingsFormType>({
    defaultValues: {
      id: undefined,
      col_rep_measurement_system_id: null,
      colostrum_max_first_fdg: null,
      colostrum_max_second_fdg: null,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  // const to store all farms data
  const farmOptionsData = data?.farm;

  // Callback function to set form data on page load or on change of farm selection
  const setFarmSettingsBasedOnFarmIdFunc = useCallback(
    (selectedFarmId: string) => {
      // const to store selected farm data
      const selectedFarmData = farmOptionsData
        ? farmOptionsData.find((ele) => ele.id === selectedFarmId)
        : null;

      if (selectedFarmData) {
        reset({
          id: selectedFarmData.id,
          col_rep_measurement_system_id: selectedFarmData.col_rep_measurement_system_id,
          colostrum_max_first_fdg: selectedFarmData.colostrum_max_first_fdg as number,
          colostrum_max_second_fdg: selectedFarmData.colostrum_max_second_fdg as number,
        });
      }
    },
    [farmOptionsData, reset],
  );

  // useEffect to set farmSettings when settings screen is called from calculator screen
  useEffect(() => {
    setFarmSettingsBasedOnFarmIdFunc(farmId);
  }, [farmId, setFarmSettingsBasedOnFarmIdFunc]);

  // Showing loading indicator while fetching farm options
  if (loading) {
    return (
      <div className="loadingIndicator">
        <Spin size="large" />
      </div>
    );
  }

  // If any error occurs while fetching farm options data
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  // function will be called on click of "save" button
  const onSubmit = (formData: FarmSettingsFormType) => {
    setSaveBtnLoading(true);

    // calling mutation to update settings value
    updateFarmSettings({
      variables: formData as UpdateFarmSettingsMutationVariables,
    })
      .then(() => {
        setSaveBtnLoading(false);

        navigate('/colostrum-management/calculators', {
          state: { farmId: watch('id') },
        });

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Settings has been successfully updated');
      })
      .catch((err: ApolloError) => {
        setSaveBtnLoading(false);
        logger(err);
      });
  };

  // const to store unit value used on label based on unit selection
  const labelVolumeUnit =
    unitLabels[
      measurementsSystemWithDefaultUnits[
        watch('col_rep_measurement_system_id') || Enum_Measurement_System_Enum.UsCustomary
      ].defaultVolumeUnit as keyof typeof unitLabels
    ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormItem
        label="Select a farm:"
        isRequired
        errorText={errors && errors.id ? errors.id.message : undefined}
        {...labelInputSpan}
      >
        <Select
          rhfControllerProps={{ control }}
          name="id"
          placeholder="Select farm"
          customStyles={{ width: 400 }}
          onChange={(value) => {
            setFarmSettingsBasedOnFarmIdFunc(value);
          }}
          selectProps={{ disabled: farmId !== 'all', showSearch: true, optionFilterProp: 'label' }}
          options={
            Array.isArray(farmOptionsData) && farmOptionsData.length > 0
              ? farmOptionsData.map((ele) => ({ label: ele.name, value: ele.id }))
              : []
          }
        />
      </FormItem>
      <FormItem
        label="Select a unit:"
        isRequired
        errorText={
          errors && errors.col_rep_measurement_system_id
            ? errors.col_rep_measurement_system_id.message
            : undefined
        }
        {...labelInputSpan}
      >
        <Select
          rhfControllerProps={{ control }}
          name="col_rep_measurement_system_id"
          placeholder="Select unit"
          customStyles={{ width: 400 }}
          options={measurementSystemOptions}
        />
      </FormItem>

      <FormItem
        label={`Desired max ${labelVolumeUnit} first feeding:`}
        isRequired
        errorText={
          errors && errors.colostrum_max_first_fdg
            ? errors.colostrum_max_first_fdg.message
            : undefined
        }
        {...labelInputSpan}
      >
        <InputNumber
          rhfControllerProps={{ control }}
          name="colostrum_max_first_fdg"
          placeholder="Enter desired max first feeding"
          onChange={() => {
            clearErrors('colostrum_max_second_fdg');
          }}
        />
      </FormItem>
      <FormItem
        label={`Desired max ${labelVolumeUnit} second feeding:`}
        isRequired
        errorText={
          errors && errors.colostrum_max_second_fdg
            ? errors.colostrum_max_second_fdg.message
            : undefined
        }
        {...labelInputSpan}
      >
        <InputNumber
          rhfControllerProps={{ control }}
          name="colostrum_max_second_fdg"
          placeholder="Enter desired max second feeding"
        />
      </FormItem>
      <Row style={{ paddingLeft: 35, paddingTop: 20 }}>
        <Col offset={labelInputSpan.labelColSpan} span={20}>
          <Button className="buttonColorRed" htmlType="submit" loading={saveBtnLoading}>
            Save
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default ColostrumReplacerSettings;
