import React from 'react';
import { Switch } from 'antd';

/* Type for SectionHeaderComponent props */
type SectionHeaderComponentProps = {
  /* Props for title of the section */
  title: string;
  /* Props used to decide whether to show toggle btn in section header or not */
  showToggleBtn?: boolean;
  /* Prop used to store whether switch is on or off */
  isSwitchOn?: boolean;
  /* Prop used to update the state of the switch */
  updateSwitchState?: React.Dispatch<React.SetStateAction<boolean>>;
};

/* React functional component */
const SectionHeaderComponent: React.FC<SectionHeaderComponentProps> = ({
  title,
  showToggleBtn,
  isSwitchOn,
  updateSwitchState,
}) => (
  <div style={{ marginTop: showToggleBtn ? 15 : 0 }}>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <h3 style={{ margin: 0 }}>{title}</h3>
      {showToggleBtn ? (
        <Switch
          checked={isSwitchOn}
          onChange={updateSwitchState}
          style={{ position: 'absolute', right: 10 }}
        />
      ) : null}
    </div>
    <hr style={{ borderColor: '#000000' }} />
  </div>
);

export default SectionHeaderComponent;
