import { Row, Col } from 'antd';
import React from 'react';
import { PooledColostrumReplacerDataType } from '../../calculator/colostrum-replacer-calculator/Pooled';
import renderLabelBasedOnUnit from '../../utils/colostrum-replacer/renderLabelBasedOnUnit';
import { Enum_Measurement_System_Enum } from '../../graphql/graphql-types';
import { ByCalfColostrumReplacerDataType } from '../../calculator/colostrum-replacer-calculator/ByCalf';

//  prop type definition for component
type CalculatedOutputFieldsComponentPropsType = {
  // prop use to store form fields data array
  fieldData: Array<PooledColostrumReplacerDataType | ByCalfColostrumReplacerDataType>;
  // prop use to store array of object returning keys and value sof the fields
  outputValues: Record<string, string | number | undefined> | undefined;
  // prop use to store selected unit
  selectedUnitValue: Enum_Measurement_System_Enum;
};

// react functional component
const CalculatedOutputFieldsComponent = ({
  fieldData,
  outputValues,
  selectedUnitValue,
}: CalculatedOutputFieldsComponentPropsType): JSX.Element => (
  <>
    {fieldData.map((ele) => {
      // const use to store field label based on unit
      const fieldLabel = renderLabelBasedOnUnit(
        ele.description.label,
        selectedUnitValue,
        ele.description.unitKey,
      );

      return fieldLabel ? (
        <Row gutter={50} style={{ marginTop: 10, marginBottom: 20 }} key={ele.key}>
          <Col span={13}>{fieldLabel}</Col>
          {outputValues && (outputValues[ele.key] || outputValues[ele.key] === 0) ? (
            <Col span={8}>
              {/* rounding off the value from frontend for label Lbs/KG of Colostrum Replacer powder needed and Total Liters needed for pooled calculator */}
              {(ele.key === 'amtOfCrPowderNeeded' || ele.key === 'totalAmtNeeded') &&
              outputValues[ele.key]
                ? Math.round((outputValues[ele.key] as number) * 100) / 100
                : outputValues[ele.key]}
            </Col>
          ) : (
            <Col span={8}>-</Col>
          )}
        </Row>
      ) : null;
    })}
  </>
);

export default CalculatedOutputFieldsComponent;
