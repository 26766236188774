import React, { useState } from 'react';
import { Button, Col, Collapse, Row, Spin } from 'antd';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { CalfListByFarmIdQuery, CalfListByFarmIdQueryVariables } from '../../graphql/graphql-types';
import { CalfSelectedForCalculationType } from '../../calculator/colostrum-replacer-calculator/ByCalf';
import generateTemporaryId from '../../utils/colostrum-replacer/generateTemporaryId';
import ColostrumReplacerByCalfCalculatorForm from '../../forms/ColostrumReplacerByCalfCalculatorForm';
import styles from '../../forms/calculator/CalculatorForms.module.scss';
import ResearchProviderInfoBar from '../../components/colostrum-replacer/ResearchProviderInfoBar';

const calfListByFarmId = loader('../../graphql/queries//calfListByFarmIdQuery.graphql');

const { Panel } = Collapse;

const ColostrumReplacerByCalfCalculator: React.FC = () => {
  // navigate variable contain useNavigate hooks
  const navigate = useNavigate();

  // extracting farm id param from useParam
  const { farmId } = useParams();

  // state use to show by-calf form based on number of times user hits "Add new calf" button
  const [calfSelectedForCalculation, setCalfSelectedForCalculation] = useState<
    CalfSelectedForCalculationType[]
  >([{ temporaryId: generateTemporaryId(), calfId: '' }]);

  // state use to store list of panel keys which are active/open
  const [panelActiveKey, setPanelActiveKey] = useState<string[]>(
    calfSelectedForCalculation.map((ele) => ele.temporaryId),
  );

  // function will be use to toggle panel state on click of panel header
  const togglePanel = (panelKey: string) => {
    // Toggle the state of the panel with the given key
    const updatedActiveKey = panelActiveKey.includes(panelKey)
      ? panelActiveKey.filter((key) => key !== panelKey)
      : [...panelActiveKey, panelKey];

    setPanelActiveKey(updatedActiveKey);
  };

  // calling query to get calf id and farm settings data for selected farm
  const { loading, data, error: queryError } = useQuery<
    CalfListByFarmIdQuery,
    CalfListByFarmIdQueryVariables
  >(calfListByFarmId, {
    variables: {
      farmId,
    },
  });

  // Showing loading indicator while fetching farm options
  if (loading) {
    return (
      <div className="loadingIndicator">
        <Spin size="large" />
      </div>
    );
  }

  // If any error occurs while fetching farm options data
  if (queryError) {
    return <div className="errorMessage">{queryError.message}</div>;
  }

  return (
    <div style={{ paddingLeft: 35, paddingTop: 10 }}>
      <h2>By Calf Calculator</h2>

      {Array.isArray(calfSelectedForCalculation) &&
      calfSelectedForCalculation.length > 0 &&
      data ? (
        <>
          <Collapse activeKey={panelActiveKey}>
            {calfSelectedForCalculation.map((ele) => (
              <Panel
                showArrow={false}
                header={
                  <Button
                    onClick={() => {
                      togglePanel(ele.temporaryId);
                    }}
                    type="text"
                    style={{
                      width: '100%',
                      textAlign: 'start',
                    }}
                    icon={
                      panelActiveKey.includes(ele.temporaryId) ? (
                        <DownOutlined />
                      ) : (
                        <RightOutlined />
                      )
                    }
                  >
                    <span>
                      <strong>Calf ID :</strong> {`${ele.calfId}`}
                    </span>
                  </Button>
                }
                key={ele.temporaryId}
              >
                {/* calling by-calf form component each time user click on "add new calf" button.  */}
                <ColostrumReplacerByCalfCalculatorForm
                  setCalfSelectedForCalculation={setCalfSelectedForCalculation}
                  calfSelectedForCalculation={calfSelectedForCalculation}
                  temporaryCalfId={ele.temporaryId}
                  farmAndCalfData={data}
                />
              </Panel>
            ))}
          </Collapse>
        </>
      ) : null}
      <Row style={{ marginTop: 20 }}>
        <Col span={2}>
          <Button
            className={`${styles.saveBtn} buttonColorRed`}
            onClick={() => {
              // const use to store random number as temporaryId
              const temporaryIdValue = generateTemporaryId();
              setCalfSelectedForCalculation([
                ...calfSelectedForCalculation,
                { temporaryId: temporaryIdValue, calfId: '' },
              ]);
              setPanelActiveKey([...panelActiveKey, temporaryIdValue]);
            }}
          >
            Add Calf
          </Button>
        </Col>

        <Col span={3}>
          <Button
            onClick={() => {
              navigate('/colostrum-management/calculators');
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <ResearchProviderInfoBar />
    </div>
  );
};

export default ColostrumReplacerByCalfCalculator;
