import { Button, Popconfirm } from 'antd';
import React from 'react';
import { WarningFilled, DeleteOutlined } from '@ant-design/icons';
import { ApolloError, Reference, useMutation } from '@apollo/client';
import { MessageApi } from 'antd/lib/message';
import { loader } from 'graphql.macro';
import {
  AllPooledCalculationsQuery,
  DeleteCalculationMutation,
  DeleteCalculationMutationVariables,
} from '../graphql/graphql-types';
import { FarmCalculationsDataType } from '../utils/types';

const deleteCalculationMutation = loader('../graphql/mutations/deleteCalculationMutation.graphql');

// type for handle delete calculations
type CalculationDltBtnWithPopconfirmType = {
  // type definition for calculation id
  calculationId: string;
  // type definition for set state
  setCalculationIdToDltAndShowLoading: React.Dispatch<React.SetStateAction<string | undefined>>;
  // type definition for antd message
  message: MessageApi;
  // type definition for logger
  logger: (error: ApolloError) => void;
  // type definition for farm calculation data type
  record: FarmCalculationsDataType | AllPooledCalculationsQuery['calculation'][0];
  // type definition for state
  calculationIdToDltAndShowLoading: string | undefined;
};

const CalculationDltBtnWithPopconfirm = ({
  calculationId,
  logger,
  message,
  setCalculationIdToDltAndShowLoading,
  record,
  calculationIdToDltAndShowLoading,
}: CalculationDltBtnWithPopconfirmType) => {
  // mutation to delete calculation
  const [deleteCalculation] = useMutation<
    DeleteCalculationMutation,
    DeleteCalculationMutationVariables
  >(deleteCalculationMutation);

  return (
    <Popconfirm
      title="Delete calculation. Are you sure?"
      onConfirm={(): void => {
        setCalculationIdToDltAndShowLoading(record.id);
        // mutation to delete calculation
        deleteCalculation({
          variables: {
            calculation_id: calculationId,
          },
          // updating cached data to reflect updated data after deletion at the same time
          update: (cache, { data: deletedData }) => {
            cache.modify({
              fields: {
                calculation(existingRefs: Array<Reference>, { readField }) {
                  // const to store id of the deleted to data to filter from query data after deletion
                  const idToFilter = deletedData?.delete_calculation_by_pk?.id;
                  if (idToFilter) {
                    // filtering deleted data from query data
                    return existingRefs.filter((ref) => idToFilter !== readField('id', ref));
                  }
                  return existingRefs;
                },
              },
            });
          },
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Calculation deleted successfully.');
            setCalculationIdToDltAndShowLoading(undefined);
          })
          .catch((err: ApolloError) => {
            logger(err);
            setCalculationIdToDltAndShowLoading(undefined);
          });
      }}
      icon={<WarningFilled style={{ color: 'red' }} />}
      cancelText="No"
      okText="Yes"
    >
      <Button
        loading={calculationIdToDltAndShowLoading === record.id}
        icon={<DeleteOutlined />}
        size="small"
      >
        Delete
      </Button>
    </Popconfirm>
  );
};

export default CalculationDltBtnWithPopconfirm;
