import { ApolloError, MutationFunctionOptions, FetchResult } from '@apollo/client';
import { message } from 'antd';
import localforage from 'localforage';
import { saveAs } from 'file-saver';
import * as Sentry from '@sentry/react';
import {
  CmrSetSolidsDataFieldsType,
  CmrSetSolidsFormFieldsWithoutReferenceNameType,
} from '../calculator/CMRSetSolids';
import {
  PmAndWaterDataFieldsType,
  PmAndWaterFormFieldsWithoutReferenceNameType,
} from '../calculator/PMAndWater';
import {
  PmrbNOWaterDataFieldsType,
  PmrbNoWaterFormFieldsWithoutReferenceNameType,
} from '../calculator/PMRBNoWater';
import { Enum_Calculator_Enum, Exact, GenerateReportMutation } from '../graphql/graphql-types';

// This is a function by which we get the object of particular key
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return key in obj;
}

// used to log errors
export const logger = (error: Error | ApolloError, customErrorMsg?: string): void => {
  console.log('message', error.message);
  console.log('error name', error.name);
  // If ApolloError
  if (error instanceof ApolloError) {
    console.log('ApolloError.extraInfo', error.extraInfo);
    console.log('ApolloError.graphQLErrors', error.graphQLErrors);
    // Sentry is not able to detect Apollo errors directly
    // Hence we are creating a new Javascript error based on apollo error message and then passing it to sentry
    Sentry.captureException(new Error(error.message));
  }
  // any other type of error
  else {
    console.log(error.stack);
    console.log(error);
    // capturing sentry error
    Sentry.captureException(error);
  }
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  message.error(customErrorMsg || error.message);
};

// This addressFormat func is used for showing address
export const addressFormat = (addInfo: {
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string;
}): string => {
  const address = ['addressLine1', 'addressLine2', 'city', 'state', 'zip'];
  const addressSend: string[] = [];
  address.forEach((add) => {
    if (addInfo && add && hasKey(addInfo, add)) {
      const addr: string | null = addInfo[add];
      if (addr) {
        addressSend.push(addr);
      }
    }
  });
  return addressSend.join(', ');
};

/* This function is used to filter array passed to it as argument based on passed fieldType as argument to the
 function used in calculator form */
export const filterArrayBasedOnType = (
  fieldArray: (CmrSetSolidsDataFieldsType | PmrbNOWaterDataFieldsType | PmAndWaterDataFieldsType)[],
  fieldType: 'primary' | 'secondary',
): CmrSetSolidsDataFieldsType[] | PmrbNOWaterDataFieldsType[] | PmAndWaterDataFieldsType[] => {
  /* Typecasting passed array to use it for further filtering logic so that it has all required fields in it that are
  being used for calculation */
  const arrayToFilter = fieldArray as CmrSetSolidsDataFieldsType[] &
    PmrbNOWaterDataFieldsType[] &
    PmAndWaterDataFieldsType[];
  return arrayToFilter.filter(
    (item) => item.type === 'calculated' && item.calculatedType === fieldType,
  );
};

/* Function to get upto how many decimal places values should rounded off */
export const roundOffTo = (value: number, roundOff: number): number => {
  const roundValUpto = 10 ** roundOff;
  return Math.round(value * roundValUpto) / roundValUpto;
};

export const capitalizeFirstLetterFunc = (text: string): string =>
  text.charAt(0).toUpperCase() + text.substring(1);

// dealing with localforage
// localforage setItem function to save the calculator's input values locally
export const saveCalculatorInputs = async (
  value: string,
  key: Enum_Calculator_Enum,
): Promise<void> => {
  try {
    await localforage.setItem(key, value);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.success('Values are locally saved.');
  } catch (e) {
    logger(e as Error);
  }
};

// localforage getItem function to read the input values from localforage
export const readCalculatorInputs = async (
  key: Enum_Calculator_Enum,
): Promise<
  | CmrSetSolidsFormFieldsWithoutReferenceNameType
  | PmrbNoWaterFormFieldsWithoutReferenceNameType
  | PmAndWaterFormFieldsWithoutReferenceNameType
  | undefined
  | null
> => {
  try {
    const calculatorInputs: string | null = await localforage.getItem(key);
    return calculatorInputs != null
      ? (JSON.parse(calculatorInputs) as
          | CmrSetSolidsFormFieldsWithoutReferenceNameType
          | PmrbNoWaterFormFieldsWithoutReferenceNameType
          | PmAndWaterFormFieldsWithoutReferenceNameType)
      : null;
  } catch (e) {
    logger(e as Error);
  }
  return null;
};

// localforage removeItem function to remove stored input values
export const removeCalculatorInputs = async (key: Enum_Calculator_Enum): Promise<void> => {
  try {
    await localforage.removeItem(key);
  } catch (e) {
    logger(e as Error);
  }
};

/**
 *
 * @param setShowGenerateReportLoader Used to show/hide loader on generate report btn
 * @param inputsData Stores inout data provided by user for calculations
 * @param calculatedFieldsValue Stores calculated data after calculations using input data
 * @param fieldValues Stores array of values based on calculator type which contains all information of each field like description, decimalPlaces, etc.
 * @param generateReport Mutation used to generate report (As we can't import it directly here)
 *
 * This functions generate report for selected calculation by the user and downloads the generated PDF to user's device
 */
export const generatePdfFunc = (
  setShowGenerateReportLoader: React.Dispatch<React.SetStateAction<boolean>>,
  inputsData: Record<string, string | number | undefined>,
  calculatedFieldsValue: Record<string, number | undefined>,
  fieldValues: (
    | CmrSetSolidsDataFieldsType
    | PmrbNOWaterDataFieldsType
    | PmAndWaterDataFieldsType
  )[],
  generateReport: (
    options?: MutationFunctionOptions<GenerateReportMutation, Exact<{ data: string }>> | undefined,
  ) => Promise<FetchResult<GenerateReportMutation, Record<string, string>, Record<string, string>>>,
  setShowModal: React.Dispatch<
    React.SetStateAction<{
      /* Store inputs data */
      inputsData: Record<string, string | number | undefined | null>;
      /* Stores calculated fields values */
      calculatedFieldsValue: Record<string, number | undefined>;
      /* Stores all field values based on calculator type */
      fieldValues: (
        | CmrSetSolidsDataFieldsType
        | PmrbNOWaterDataFieldsType
        | PmAndWaterDataFieldsType
      )[];
    } | null>
  >,
): void => {
  setShowGenerateReportLoader(true);

  /* Variable to store mutation values */
  let mutationValues = inputsData;

  /* Updating mutation values with primary and secondary calculated fields which are visible to user */
  Object.entries(calculatedFieldsValue).forEach(([key, value]) => {
    if (
      fieldValues.some(
        (val: CmrSetSolidsDataFieldsType | PmAndWaterDataFieldsType | PmrbNOWaterDataFieldsType) =>
          val.key === key && val.calculatedType !== 'hidden',
      )
    ) {
      mutationValues = { ...mutationValues, [key]: value };
    }
  });

  /* Generating report PDF and saving it to user's device */
  generateReport({ variables: { data: JSON.stringify(mutationValues) } })
    .then((res) => {
      if (res.data && res.data.generateReport && res.data.generateReport.report_url) {
        fetch(res.data.generateReport.report_url)
          .then((response) => response.blob())
          .then((blob) => {
            saveAs(blob, res.data?.generateReport?.fileName);
            setShowGenerateReportLoader(false);
            setShowModal(null);
          })
          .catch((err) => {
            logger(err);
            setShowGenerateReportLoader(false);
          });
      }
    })
    .catch((err: ApolloError) => {
      logger(err);
      setShowGenerateReportLoader(false);
    });
};

export default null;
