/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Row, Col } from 'antd';
import styles from './FormItem.module.scss';
import { DisplayModeType } from '../utils/types';

// This is the types of props
type FormItemProps = {
  // This is the label of form field
  label?: string | JSX.Element;
  // This is the span which is passed in the <Col> for label
  labelColSpan?: number;
  // This is the span which is passed in the <Col> for input
  inputColSpan?: number;
  // This is the help text which describes the input field
  helpText?: string;
  // This is the error text of the field
  errorText?: string;
  // This is the JSX element which we define inside this component
  children: JSX.Element;
  // when field is required then this is true
  isRequired?: boolean;
  /* Should the label and children be shown in row or column view */
  displayMode?: DisplayModeType;
  /* This is the style which we pass from parent component */
  customStyle?: React.CSSProperties;
};

// This is the main functional component
const FormItem: React.FC<FormItemProps> = (props) => {
  const {
    label,
    labelColSpan = 3,
    inputColSpan = 21,
    children,
    helpText = '',
    errorText = '',
    isRequired = false,
    displayMode = 'row' as DisplayModeType,
    customStyle = {},
  } = props;
  return (
    <>
      <Row
        className={styles.inputContainer}
        style={{ paddingTop: label && displayMode === 'row' ? 25 : 5, ...customStyle }}
      >
        {label && displayMode === 'row' ? (
          <Col
            span={labelColSpan}
            className={`${styles.labelName} ${isRequired ? 'requiredField' : ''}`}
          >
            <label className="form-label">{label}</label>
          </Col>
        ) : null}
        <Col
          span={inputColSpan}
          style={label && displayMode === 'row' ? { paddingLeft: 30 } : undefined}
        >
          {label && displayMode === 'column' ? (
            <div style={{ marginBottom: 5 }} className={`${isRequired ? 'requiredField' : ''}`}>
              <label className="form-label">{label}</label>
            </div>
          ) : null}
          {children}
          {helpText ? <div className={styles.helpText}>{helpText}</div> : null}
          {errorText ? <div className={styles.errorText}>{errorText}</div> : null}
        </Col>
      </Row>
    </>
  );
};

export default FormItem;
