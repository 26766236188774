import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { InputNumber, InputNumberProps } from 'antd';
import styles from './InputComponent.module.scss';

// This is the type of props coming from parent component
type NumberInputPropsType = {
  /* Name is the name of form field */
  name: string;
  // This is the default value of our input
  defaultValue?: number;
  // This is the controller props of react-hook-form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rhfControllerProps: Omit<UseControllerProps<any>, 'name' | 'defaultValue'>;
  // This is the custom style for input box
  customStyles?: React.CSSProperties;
  // This is the props for input field
  inputNumberProps?: Omit<InputNumberProps, 'onChange' | 'value' | 'onBlur' | 'ref'>;
  // This function is called when functionality is different from the regular onChange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (val: number) => void;

  onBlur?: () => void;

  placeholder?: string;
};

// This is the main functional component
const InputNumberComponent: React.FC<NumberInputPropsType> = ({
  name,
  customStyles = {},
  defaultValue = undefined,
  rhfControllerProps,
  inputNumberProps = {},
  onChange,
  onBlur,
  placeholder = undefined,
}) => {
  // destructuring fields from useController hook
  const { field } = useController({
    name,
    defaultValue,
    ...rhfControllerProps,
  });

  // destructuring onChange function & rhfFields, and then will pass it to input field
  const { onChange: rhfOnChange, onBlur: rhfOnBlur, ...rhfFields } = field;

  return (
    <InputNumber
      placeholder={placeholder}
      className={styles.textInput}
      style={{ ...customStyles }}
      {...inputNumberProps}
      {...rhfFields}
      onChange={(value) => {
        if (onChange) {
          const inputValue = value as number;
          onChange(inputValue);
        }
        rhfOnChange(value);
      }}
      onBlur={() => {
        if (onBlur) {
          onBlur();
        }
        rhfOnBlur();
      }}
    />
  );
};

export default InputNumberComponent;
