import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Select as AntdSelect, SelectProps as AntdSelectPropType, Tooltip } from 'antd';
import styles from './Select.module.scss';

// type for options data which we will pass to select option
type OptionsDataType = {
  // prop type for value which will be return when that option is selected
  value: string | number;
  // prop type for option label which we see in select dropdown
  label: string | number;
};

// type for props of selectComponent
type PropTypes = {
  // Name is the name of form field
  name: string;
  // This is the default value of our select field
  defaultValue?: string;
  // This is the placeholder for our select field
  placeholder?: string;
  // This is the controller props of react-hook-form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rhfControllerProps: Omit<UseControllerProps<any>, 'name' | 'defaultValue'>;
  // This is the custom style for select
  customStyles?: React.CSSProperties;
  // This will contain actual prop of antd select
  selectProps?: Omit<AntdSelectPropType<any>, 'onChange' | 'value'>;
  //  this will contain array of data we will pass to option so that we will see its label in select dropdown
  options: Array<OptionsDataType>;
  // This function is called when functionality is different from the regular onChange
  onChange?: (value: any) => void;
  /* prop to decide if the field is valid or not */
  hasError?: boolean;
};

// react functional component
const Select: React.FC<PropTypes> = ({
  name,
  defaultValue,
  rhfControllerProps,
  placeholder,
  customStyles,
  onChange,
  options,
  selectProps,
  hasError,
}) => {
  // destructuring fields from useController hook
  const { field } = useController({
    name,
    defaultValue,
    ...rhfControllerProps,
  });

  // destructuring onChange function & rhfFields, and then will pass it to input field
  const { onChange: rhfOnChange, ...rhfFields } = field;

  // This is a common props which is used in antd select
  const commonSelectProps: AntdSelectPropType<any> = {
    placeholder,
    onChange: (value) => {
      if (onChange) {
        onChange(value);
      }
      rhfOnChange(value);
    },
    className: hasError ? styles.selectError : styles.select,
    style: {
      width: '100%',
      ...customStyles,
    },
    ...rhfFields,
    ...selectProps,
  };

  return <AntdSelect options={options} {...commonSelectProps} />;
};

Select.defaultProps = {
  defaultValue: undefined,
  placeholder: undefined,
  customStyles: {},
  selectProps: {},
  onChange: undefined,
  hasError: false,
};

export default Select;
