import React, { ReactNode, useEffect, useState } from 'react';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import useAuth from './auth/useAuth';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const cache = new InMemoryCache();

const initialClient = new ApolloClient({
  cache,
  link: httpLink,
});

const ApolloClientProvider = (props: { children: ReactNode }): JSX.Element => {
  const { children } = props;

  // State to store the apollo client to be provided to ApolloProvider
  const [client, setClient] = useState(initialClient);

  // extracting get access token function from useAuth hook
  const { getAccessToken } = useAuth();

  useEffect(() => {
    const authLink = setContext(async (_, { headers }) => {
      // token
      const token = await getAccessToken();

      return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: { ...headers, authorization: `Bearer ${token}` },
      };
    });

    setClient(
      new ApolloClient({
        cache,
        link: authLink.concat(httpLink),
      }),
    );
  }, [getAccessToken]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default ApolloClientProvider;
