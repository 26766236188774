import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import 'react-tabs/style/react-tabs.css';
import AuthProvider from './utils/auth/AuthProvider';
import ApolloClientProvider from './utils/ApolloClient';

Sentry.init({
  dsn: 'https://d07694c7f6b9edc805224cc443ecb022@o1204593.ingest.sentry.io/4506782940463104',
  enabled:
    process.env.REACT_APP_ENVIRONMENT === 'development' ||
    process.env.REACT_APP_ENVIRONMENT === 'production',
  environment: process.env.REACT_APP_ENVIRONMENT || 'development',
  release: process.env.REACT_APP_ENVIRONMENT || 'development',
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ApolloClientProvider>
        <App />
      </ApolloClientProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
