import React, { useState } from 'react';
import { Input, Space, Button, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import styles from './ColumnsFilterComponent.module.scss';

/* ColumnsFilterComponent prop type */
type ColumnsFilterComponentPropsType = {
  /* setState function to set selectedKeys in table for search */
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  /* State to store selectedKeys in table for search */
  selectedKeys: React.Key[];
  /* Function to confirm column search  */
  confirm: () => void;
  /* Function to reset search/filters in column search */
  clearFilters: (() => void) | undefined;
  /* Data index of a column whose search functionality i s being used */
  dataIndex: string;
  /* Filters for columns if given */
  filters?: ColumnFilterItem[];
  /* Type of filter to be added on columns */
  type: 'search' | 'options';
};

/* React functional component */
const ColumnsFilterComponent: React.FC<ColumnsFilterComponentPropsType> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  filters,
  type,
}) => {
  /* State to store search text for columns search options when type is 'search' */
  const [searchText, setSearchText] = useState<string>('');
  /* State to store checked filtered options when type is 'options' */
  const [filterOptions, setFilterOptions] = useState<CheckboxValueType[]>([]);

  /* Function to handle column search functionality */
  const handleFilter = () => {
    confirm();
    if (type === 'search') {
      setSearchText(selectedKeys[0] as string);
    }
  };

  /* Function to reset column search functionality */
  const handleReset = () => {
    if (clearFilters) clearFilters();
    if (type === 'search') {
      setSearchText('');
    } else {
      setFilterOptions([]);
    }
  };

  /* Function to render search filter */
  const renderSearchFilter = () => (
    <>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={handleFilter}
        style={{ width: 190, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          className="buttonColorRed"
          onClick={handleFilter}
          icon={<SearchOutlined />}
          size="small"
          style={{ padding: '0px 15px' }}
        >
          Search
        </Button>
        <Button onClick={handleReset} size="small" style={{ padding: '0px 25px' }}>
          Reset
        </Button>
      </Space>
    </>
  );

  /* Function to render options filter */
  const renderOptionsFilter = () => {
    /* Variable to store checkbox options to be rendered */
    const checkboxOptions = filters
      ? filters.map((item) => ({
          label: item.text,
          value: item.value,
        }))
      : [];

    return (
      <>
        <Checkbox.Group
          value={filterOptions}
          options={checkboxOptions}
          style={{ width: 140 }}
          onChange={(val) => {
            setSelectedKeys(val as React.Key[]);
            setFilterOptions(val);
          }}
        />
        <div className={styles.optionFilterBtnContainer}>
          <Button
            className="buttonColorRed"
            onClick={handleFilter}
            size="small"
            style={{ width: 50, marginRight: 15 }}
          >
            Ok
          </Button>
          <Button onClick={handleReset} type="text" size="small">
            Reset
          </Button>
        </div>
      </>
    );
  };

  return (
    <div style={{ padding: 10 }}>
      {type === 'search' ? renderSearchFilter() : renderOptionsFilter()}
    </div>
  );
};

export default ColumnsFilterComponent;
