// todo: later when list script is written for enum remove this array and fetch data directly from list enum
import { Enum_Measurement_System_Enum } from '../../graphql/graphql-types';

// static const to define unit
const measurementSystemOptions = [
  { label: 'English', value: Enum_Measurement_System_Enum.UsCustomary },
  { label: 'Metric', value: Enum_Measurement_System_Enum.Metric },
];

export default measurementSystemOptions;
