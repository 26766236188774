import { WarningFilled } from '@ant-design/icons';
import React from 'react';
import styles from './WarningText.module.scss';

// This is the type of props coming from parent component
type WarningTextProps = {
  // this is the children of this component
  children: JSX.Element | string;
  /* Custom style for warning container */
  customStyles?: React.CSSProperties;
};

// This is the main functional component
const WarningText: React.FC<WarningTextProps> = (props) => {
  // destructuring props
  const { children, customStyles = {} } = props;
  return (
    <div className={styles.warningText} style={customStyles}>
      <WarningFilled className={styles.warningIcon} />
      {children}
    </div>
  );
};

export default WarningText;
