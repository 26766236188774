// function use to render label of calf id, brix reading and previous brix reading fields based on type of feeding selected.
// brix reading label will be "first feeding brix reding" when type of feeding is "first feeding" else it will be "Second feeding brix reading"
// previous brix reading label will be "first feeding brix reding" when type of feeding is "second feeding" else it will be hidden field.

const renderLabelBasedOnTypeOfFeeding = (
  // type definition for label
  label: string | ((type: string) => string),
  // type definition for feeding type
  feedingType: 'firstFeeding' | 'secondFeeding',
) => `${typeof label === 'function' ? label(feedingType) : label}`;

export default renderLabelBasedOnTypeOfFeeding;
