import { Col, Row } from 'antd';
import React from 'react';
import { CmrSetSolidsDataFieldsType } from '../../calculator/CMRSetSolids';
import {
  PmAndWaterDataFieldsType,
  PowderFeedingCountSelectionType,
  PowderType,
} from '../../calculator/PMAndWater';
import { PmrbNOWaterDataFieldsType } from '../../calculator/PMRBNoWater';
import WarningText from '../../components/WarningText';
import { roundOffTo } from '../../utils/helpers';
import { UnitType } from '../../utils/types';

/* Calculator all data fields type */
type CalculatorAllDataFieldsType =
  | CmrSetSolidsDataFieldsType
  | PmrbNOWaterDataFieldsType
  | PmAndWaterDataFieldsType;

/* Type for CalculatedFieldsListViewComponent props */
type CalculatedFieldsListViewComponentPropsType = {
  /* Prop stores list data to be rendered */
  // listData: CmrSetSolidsDataFieldsType[] | PmrbNOWaterDataFieldsType[] | PmAndWaterDataFieldsType[];
  listData: CalculatorAllDataFieldsType[];
  /* Prop stores Calculated fields based on type  */
  calculatedFields: Record<string, number | undefined>;
  // this is optional prop which will store the no of calves entered by user
  noOfCalves?: number;
  // this is optional prop  used to store the unit selected by user
  unit?: UnitType;
  // this is optional prop used to pass powder type when user deal with pm water form and that to feeding only one powder
  powderType?: PowderType;
  // powderFeeding
  powderFeedingCount?: PowderFeedingCountSelectionType;
};

// array to store data key whose decimalPlaces value vary based on no of calves
const arrayOfKeyWithVaryingDecimalPlace = [
  'waterNeededPerFdg',
  'poundsOfPowderNeededPerFdg',
  'totalPasteurizedMilkNeededPerFdg',
];

// array to store fields whose UI contain extra margin on left (ie tab space)
const fieldsWithExtraMargin = [
  'percentCPDryBasis',
  'percentFatDryBasis',
  'percentCPToPercentFatRatio',
  'percentCPToPercentFatRatio',
  'totalMilkNeededPerFdg',
  'totalBalancerNeededPerFdg',
  'milkFedPerCalfPerFdg',
  'pasteurizedMilkBalancerFedPerCalfPerFdg',
  'totalSolidsFedFromPowderPerCalfDaily',
  'totalSolidsFedFromPasteurizedMilkPerCalfDaily',
];

// const to store array of keys which will be ommit/hide when unit is pounds
const ommitKeyWhenUnitIsPounds = [
  'actualSolutionVolPerCalfPerFdg',
  'pasteurizedMilkFedPerCalfPerFdg',
  'pasteurizedMilkFedPerCalfDaily',
];

// const to store output fields to hide when feeding only one type of powder.
const ommitKeyWhenFeedingOnlyOnePowder = [
  'totalBalancerNeededPerFdg',
  'pasteurizedMilkBalancerFedPerCalfPerFdg',
];

/* React functional component */
const CalculatedFieldsListViewComponent: React.FC<CalculatedFieldsListViewComponentPropsType> = ({
  listData,
  calculatedFields,
  noOfCalves,
  unit,
  powderFeedingCount,
  powderType,
}) => {
  // function to show unit
  const displayUnitFunction = (inputData: CalculatorAllDataFieldsType): string => {
    if (unit) {
      if (
        (inputData.key === 'totalPasteurizedMilkNeededPerFdg' ||
          inputData.key === 'totalSolutionVolPerFdg') &&
        typeof inputData.description.unit === 'function'
      ) {
        return inputData.description.unit(unit);
      }
    }
    return inputData.description.unit as string;
  };

  // function to show label
  const displayLabelFunction = (inputData: CalculatorAllDataFieldsType): string => {
    if (
      (inputData.key === 'milkFedPerCalfPerFdg' || inputData.key === 'totalMilkNeededPerFdg') &&
      typeof inputData.description.label === 'function'
    ) {
      return inputData.description.label(powderType === 'milkBalancer' ? 'Balancer' : 'Replacer');
    }

    return inputData.description.label as string;
  };

  // function to  format calculated value
  const formatCalculatedValueFunction = (
    calculatedResult: number,
    key: string,
    decimalPlaceValue: number,
  ) => {
    if (key === 'solidsOfFinalSolution') {
      const result = roundOffTo(calculatedResult * 100, decimalPlaceValue).toFixed(
        decimalPlaceValue,
      );
      return `${result}%`;
    }

    return roundOffTo(calculatedResult, decimalPlaceValue).toFixed(decimalPlaceValue);
  };

  // function to set the containerLeft margin to greater than zero when key are percentCPToPercentFatRatio, percentFatDryBasis, percentCPDryBasis
  const containerMarginFunc = (item: CalculatorAllDataFieldsType) => {
    // variable to store the left margin of calculatedDataFieldsContainer
    let containerLeftMargin = 0;
    if (fieldsWithExtraMargin.includes(item.key)) {
      containerLeftMargin = 25;
    }
    return containerLeftMargin;
  };

  return (
    <>
      {Array.isArray(listData) && listData.length > 0
        ? listData.map((item: CalculatorAllDataFieldsType, index: number) => {
            /* Variable to store calculated value */
            const calculatedValue = calculatedFields[item.key];
            // variable to store the decimal place value for each entry
            let decimalPlace = item.decimalPlaces;

            // logic to set value of decimal based on no of calves for item whose key present in  arrayOfKeyWithVaryingDecimalPlace array
            if (
              noOfCalves &&
              noOfCalves <= 10 &&
              arrayOfKeyWithVaryingDecimalPlace.includes(item.key)
            ) {
              decimalPlace = 2;
            }

            return (
              <div key={item.key}>
                {index ===
                listData.findIndex(
                  (ele: CalculatorAllDataFieldsType) => ele.key === 'percentCPDryBasis',
                ) ? (
                  <Row style={{ marginTop: 10 }}>Expected Final Solution:</Row>
                ) : null}
                {(unit === 'pounds' && ommitKeyWhenUnitIsPounds.includes(item.key)) ||
                (powderFeedingCount === 1 &&
                  ommitKeyWhenFeedingOnlyOnePowder.includes(item.key)) ? null : (
                  <Row style={{ marginTop: 10, marginLeft: containerMarginFunc(item) }}>
                    <Col span={16}>
                      {displayLabelFunction(item)}
                      <span> {displayUnitFunction(item) || ''}</span>
                    </Col>
                    <Col span={8} style={{ textAlign: 'end' }}>
                      <span style={{ color: 'grey' }}>
                        {calculatedValue
                          ? formatCalculatedValueFunction(
                              calculatedValue,
                              item.key,
                              decimalPlace as number,
                            )
                          : '-'}
                      </span>
                    </Col>
                  </Row>
                )}

                {item.key === 'solidsOfFinalSolution' &&
                calculatedValue &&
                calculatedValue * 100 > 18 ? (
                  <WarningText customStyles={{ marginTop: 10, width: '100%' }}>
                    Solids TOO HIGH! Either raise the volume to feed or lower the amount of powder.
                  </WarningText>
                ) : null}
              </div>
            );
          })
        : null}
    </>
  );
};

export default CalculatedFieldsListViewComponent;
