import React, { useState } from 'react';
import { message, Modal } from 'antd';
import { Control, UseFormGetValues, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  AddCalculationMutation,
  AddCalculationMutationVariables,
  Enum_Calculator_Enum,
} from '../../graphql/graphql-types';
import InputComponent from '../../components/InputComponent';
import { logger, saveCalculatorInputs } from '../../utils/helpers';
import FormItem from '../../components/FormItem';
import { FarmCalculationsDataType } from '../../utils/types';

const addCalculationMutation = loader('../../graphql/mutations/addCalculationMutation.graphql');
const calculationsOfFarmQuery = loader('../../graphql/queries/calculationsOfFarmQuery.graphql');

/* Type for ReferenceNameModalComponent prop */
type ReferenceNameModalComponentProps = {
  /* Prop to decide whether to show modal or not */
  showModal: boolean;
  /* Prop containing setState function to update visibility of modal */
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  /* Control of useForm */
  control: Control<any>;
  /* Prop used to store selected farm ID */
  selectedFarmId: string | undefined;
  /* Prop used update selected farm ID value using this setState function */
  setSelectedFarmId: React.Dispatch<React.SetStateAction<string | undefined>> | undefined;
  /* getValues function from useForm to get form values */
  getValues: UseFormGetValues<any>;
  /* Prop used to determine type of the calculator */
  type: Enum_Calculator_Enum;
  /* Prop storing number of calves value */
  noOfCalves: number;
  /* Prop storing feedings per day value */
  feedingsPerDay: number;
  /* setValue function from useForm to update form values */
  setValue: UseFormSetValue<any>;
  /* Error text for reference name field if any */
  errorText?: string;
  /* setError function from useForm to set errors manually if needed */
  setError: UseFormSetError<any>;
  setCalculationData?: React.Dispatch<React.SetStateAction<FarmCalculationsDataType | undefined>>;
};

/* React functional component */
const ReferenceNameModalComponent: React.FC<ReferenceNameModalComponentProps> = ({
  showModal,
  setShowModal,
  selectedFarmId,
  setSelectedFarmId,
  control,
  getValues,
  type,
  noOfCalves,
  feedingsPerDay,
  setValue,
  errorText,
  setError,
  setCalculationData,
}) => {
  /* State used to show loading indicator on save btn while mutation is in progress */
  const [showLoaderOnSave, setShowLoaderOnSave] = useState<boolean>(false);

  /* Mutation to add new calculation */
  const [addCalculation] = useMutation<AddCalculationMutation, AddCalculationMutationVariables>(
    addCalculationMutation,
  );

  /* Function invoked when save btn is clicked */
  const handleSaveBtnClick = (): void => {
    if (!getValues('referenceName')) {
      setError('referenceName', {
        type: 'required',
        message: 'Please enter the reference name and try again',
      });
      return;
    }

    setShowLoaderOnSave(true);

    /* Object to store user inputs entered by user */
    let userInputVariables: Record<string, number | string>;

    /* Creating user input variables based on type of calculator that can be used in mutation input variable */
    if (type === Enum_Calculator_Enum.CmrSetSolids) {
      userInputVariables = {
        targetVolPerFdgQtPerCalf: parseFloat(getValues('targetVolPerFdgQtPerCalf')),
        targetSolidsOfFinalSolution: parseFloat(getValues('targetSolidsOfFinalSolution')),
      };
    } else if (type === Enum_Calculator_Enum.PmrbNoWater) {
      userInputVariables = {
        targetPasteurizedMilkBalancerLbsPerCalfDaily: parseFloat(
          getValues('targetPasteurizedMilkBalancerLbsPerCalfDaily'),
        ),
        labSolidsOrRefractometerSelection: parseFloat(
          getValues('labSolidsOrRefractometerSelection'),
        ),
        targetVolPerFdgQtPerCalf: parseFloat(getValues('targetVolPerFdgQtPerCalf')),
        pasteurizedMilkBalancerCP: parseFloat(getValues('pasteurizedMilkBalancerCP')),
        pasteurizedMilkBalancerFat: parseFloat(getValues('pasteurizedMilkBalancerFat')),
        pasteurizedMilkCP: parseFloat(getValues('pasteurizedMilkCP')),
        pasteurizedMilkFat: parseFloat(getValues('pasteurizedMilkFat')),
        labSolidsOrRefractometer: getValues('labSolidsOrRefractometer') as string,
        unit: getValues('unit') as string,
      };
    } else {
      userInputVariables = {
        totalVolAvailableGallonsPerFdg: parseFloat(getValues('totalVolAvailableGallonsPerFdg')),
        labSolidsOrRefractometerSelection: parseFloat(
          getValues('labSolidsOrRefractometerSelection'),
        ),
        targetSolidsFinalSolution: parseFloat(getValues('targetSolidsFinalSolution')),
        targetVolPerFdgQtPerCalf: parseFloat(getValues('targetVolPerFdgQtPerCalf')),
        lbOfSecondPowderPerHdPerDay: parseFloat(getValues('lbOfSecondPowderPerHdPerDay')),
        pasteurizedMilkCP: parseFloat(getValues('pasteurizedMilkCP')),
        pasteurizedMilkFat: parseFloat(getValues('pasteurizedMilkFat')),
        powderOneCP: parseFloat(getValues('powderOneCP')),
        powderOneFat: parseFloat(getValues('powderOneFat')),
        powderTwoCP: parseFloat(getValues('powderTwoCP')),
        powderTwoFat: parseFloat(getValues('powderTwoFat')),
        labSolidsOrRefractometer: getValues('labSolidsOrRefractometer') as string,
        powderFeedingCount: parseInt(getValues('powderFeedingCount'), 10),
        powderType: (getValues('powderType') as unknown) as number,
      };
    }

    if (selectedFarmId) {
      /* Mutation to add new calculation */
      addCalculation({
        variables: {
          object: {
            name: getValues('referenceName') as string,
            inputs: {
              ...userInputVariables,
              noOfCalves,
              feedingsPerDay,
            },
            farm_id: selectedFarmId,
            calculator: type,
          },
        },
        refetchQueries: [
          { query: calculationsOfFarmQuery, variables: { farm_id: selectedFarmId } },
        ],
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Calculation added successfully');

          saveCalculatorInputs(
            JSON.stringify({ ...userInputVariables, farmId: selectedFarmId }),
            type,
          ).catch((err) => logger(err));

          setShowLoaderOnSave(false);
          setShowModal(false);
          if (setCalculationData) {
            setCalculationData(undefined);
          }
          if (setSelectedFarmId) {
            setSelectedFarmId(undefined);
          }
        })
        .catch((err: ApolloError) => {
          logger(err);
          setShowLoaderOnSave(false);
        });
    }
  };

  return (
    <Modal
      destroyOnClose
      visible={showModal}
      closable={false}
      style={{ padding: 0 }}
      okText="Save"
      okButtonProps={{ className: 'buttonColorRed' }}
      onCancel={() => {
        setShowModal(false);
        setValue('referenceName', undefined);
      }}
      confirmLoading={showLoaderOnSave}
      onOk={handleSaveBtnClick}
      width={450}
    >
      <FormItem label="Reference Name" displayMode="column" isRequired errorText={errorText}>
        <InputComponent
          placeholder="Please enter a name for this calculation"
          name="referenceName"
          rhfControllerProps={{ control }}
        />
      </FormItem>
    </Modal>
  );
};

export default ReferenceNameModalComponent;
