import axios from 'axios';
import dayjs from 'dayjs';
import localforage from 'localforage';
import { LOCALFORAGE_KEY } from '../globals';
import { CaddieModuleType } from '../types';

export type AuthenticationRedirectQueryParamsType = {
  code: string;
};

// The user data type of auth access token
export type AuthUserDataType = {
  // type definition for  first name of user
  firstName: string;
  // type definition for last name of user
  lastName: string;
  // type definition for role's of user
  role: string | string[];
  // type definition for ag2ag of user
  ag2ag: string;
  // type definition for email of user
  email: string;
  // type definition for client id
  clientId: string;
  // type definition for user, whether user is admin or not
  isAdmin: boolean;
  // type definition for module names for which user has access
  hasAccessToModules: Array<CaddieModuleType>;
};

// The payload data type of access token
type AccessTokenPayloadType = {
  scope: string[];
  client_id: string;
  iss: string;
  fname: string;
  lname: string;
  role: string | string[];
  ag2ag: string;
  mail: string;
  account?: string;
  userid?: string;
  exp: number;
};

// Type for the data used for auth
export type AuthDataType = {
  accessToken: string;
  accessTokenExpirationDate: string;
  refreshToken: string;
  user: AuthUserDataType;
};

export type AuthDataWithoutUserType = Omit<AuthDataType, 'user'>;

// The regular expression to search code sent by auth server in redirect URI
const CODE_RE = /[?&]code=[^&]+/;
// The regular expression to search error sent by auth server in redirect URI
const ERROR_RE = /[?&]error=[^&]+/;
// The token endpoint of oauth server
const TOKEN_ENDPOINT = `${process.env.REACT_APP_PINGFEDERATE_DOMAIN || ''}/as/token.oauth2`;
// The authorize endpoint of oauth server
const AUTHORIZATION_ENDPOINT = `${
  process.env.REACT_APP_PINGFEDERATE_DOMAIN || ''
}/as/authorization.oauth2`;
// Session storage
const STORAGE_KEY_PREFIX = 'pingfederate';
export const SESSION_STORAGE_KEYS = {
  codeVerifier: `${STORAGE_KEY_PREFIX}_codeVerifier`,
  clientId: `${STORAGE_KEY_PREFIX}_clientId`,
};

// https://stackoverflow.com/questions/30106476/
const decodeB64 = (input: string): string =>
  decodeURIComponent(
    atob(input)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
// Extract payload from JWT token
const urlDecodeB64 = (input: string): string =>
  decodeB64(input.replace(/_/g, '/').replace(/-/g, '+'));

// function to create a random string which will be used as code verifier
const createRandomString = () => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
  let random = '';
  const randomValues = Array.from(window.crypto.getRandomValues(new Uint8Array(43)));
  randomValues.forEach((v) => {
    random += charset[v % charset.length];
  });
  return random;
};

// Used in sha256 hashing function
const getCryptoSubtle = () => {
  const { crypto } = window;
  // safari 10.x uses webkitSubtle
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  return crypto.subtle || (crypto as any).webkitSubtle;
};

// Create SHA256 hash
const sha256 = (s: string) =>
  getCryptoSubtle().digest({ name: 'SHA-256' }, new TextEncoder().encode(s));

// Used for base64 encoding
const urlEncodeB64 = (input: string) => {
  const b64Chars: { [index: string]: string } = { '+': '-', '/': '_', '=': '' };
  return input.replace(/[+/=]/g, (m: string) => b64Chars[m]);
};

// base64 encoding of the code verifier
const bufferToBase64UrlEncoded = (input: number[] | Uint8Array): string => {
  const ie11SafeInput = new Uint8Array(input);
  return urlEncodeB64(window.btoa(String.fromCharCode(...Array.from(ie11SafeInput))));
};

// Check if the URI has auth server params
export const hasAuthParams = (searchParams = window.location.search): boolean =>
  CODE_RE.test(searchParams) || ERROR_RE.test(searchParams);

// function to parse query
export const parseQueryResult = (queryStringArg: string): AuthenticationRedirectQueryParamsType => {
  let queryString = queryStringArg;
  if (queryString.indexOf('#') > -1) {
    queryString = queryString.substr(0, queryString.indexOf('#'));
  }
  const queryParams = queryString.split('&');
  let code = '';
  queryParams.forEach((qp) => {
    const [key, val] = qp.split('=');
    if (key === 'code') {
      code = decodeURIComponent(val);
    }
  });
  return {
    code,
  };
};

/**
 * Defines a type that handles storage to/from a storage location
 */
interface ClientStorageOptions {
  daysUntilExpire: number;
}

export type ClientStorage = {
  get(key: string): string | undefined;
  save(key: string, value: string, options?: ClientStorageOptions): void;
  remove(key: string): void;
};

/**
 * A storage protocol for marshalling data to/from session storage
 */
export const SessionStorage = {
  get(key: string) {
    if (typeof sessionStorage === 'undefined') {
      return undefined;
    }

    const value = sessionStorage.getItem(key);

    if (typeof value === 'undefined' || value === null) {
      return undefined;
    }

    return value;
  },

  save(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  },

  remove(key: string) {
    sessionStorage.removeItem(key);
  },
} as ClientStorage;

export type TokenEndpointResponse = {
  access_token: string;
  refresh_token: string;
  id_token?: string;
  expires_in: number;
};

const getAuthDataWithoutUserFromTokenEndpointResponse = (responseData: TokenEndpointResponse) => ({
  accessToken: responseData.access_token,
  refreshToken: responseData.refresh_token,
  accessTokenExpirationDate: dayjs().add(responseData.expires_in, 'seconds').toISOString(),
});

// Fetch access token from the auth code using Authorization Code flow with PKCE
const getAccessToken = async (authorizationCode: string): Promise<AuthDataWithoutUserType> => {
  // Get the code verifier (for PKCE) from session storage
  const codeVerifier = SessionStorage.get(SESSION_STORAGE_KEYS.codeVerifier);

  // Get client id (internal or external depending on the user who is trying to login) from session storage
  const clientId = SessionStorage.get(SESSION_STORAGE_KEYS.clientId);

  if (!codeVerifier) {
    throw new Error('Code verifier not present');
  }

  if (!clientId) {
    throw new Error('Client id not present');
  }

  if (!process.env.REACT_APP_PINGFEDERATE_REDIRECT_URI) {
    throw new Error('PINGFEDERATE_REDIRECT_URI not present');
  }

  // Call the token endpoint
  const formData = new URLSearchParams();

  formData.append('client_id', clientId);
  formData.append('grant_type', 'authorization_code');
  formData.append('redirect_uri', process.env.REACT_APP_PINGFEDERATE_REDIRECT_URI);
  formData.append('code_verifier', codeVerifier);
  formData.append('code', authorizationCode);

  const response = await axios.post<TokenEndpointResponse>(TOKEN_ENDPOINT, formData);

  // Code verifier is one time use only.
  // So delete the stored code verifier after usage
  SessionStorage.remove(SESSION_STORAGE_KEYS.codeVerifier);

  return getAuthDataWithoutUserFromTokenEndpointResponse(response.data);
};

// Call pingfederate token endpoint to get new tokens using refresh token
export const pingfederateRefreshAccessToken = async (
  refreshToken: string,
): Promise<AuthDataWithoutUserType> => {
  if (!process.env.REACT_APP_PINGFEDERATE_EXTERNAL_CLIENT_ID) {
    throw new Error('PINGFEDERATE_EXTERNAL_CLIENT_ID not present');
  }

  if (!process.env.REACT_APP_PINGFEDERATE_REDIRECT_URI) {
    throw new Error('PINGFEDERATE_REDIRECT_URI not present');
  }

  // stores auth data from local storage
  const authData = (await localforage.getItem(LOCALFORAGE_KEY)) as AuthUserDataType;

  // stores client id from local storage. If not present, value of external client id env variable will be used
  const clientId =
    authData && authData.clientId
      ? authData.clientId
      : process.env.REACT_APP_PINGFEDERATE_EXTERNAL_CLIENT_ID;

  // Call the token endpoint
  const formData = new URLSearchParams();

  formData.append('client_id', clientId);
  formData.append('grant_type', 'refresh_token');
  formData.append('redirect_uri', process.env.REACT_APP_PINGFEDERATE_REDIRECT_URI);
  formData.append('refresh_token', refreshToken);

  const response = await axios.post<TokenEndpointResponse>(TOKEN_ENDPOINT, formData);

  return getAuthDataWithoutUserFromTokenEndpointResponse(response.data);
};

// Decode the access token and return user data from payload
export const decodeAccessToken = (token: string): AuthUserDataType => {
  const parts = token.split('.');

  const [header, payload, signature] = parts;

  if (parts.length !== 3 || !header || !payload || !signature) {
    throw new Error('ID token could not be decoded');
  }

  const payloadJSON = JSON.parse(urlDecodeB64(payload)) as AccessTokenPayloadType;

  // const to check string 'Admin' present in role or not
  const isAdminPresent =
    (typeof payloadJSON.role === 'string' && payloadJSON.role.toLowerCase().includes('admin')) ||
    (Array.isArray(payloadJSON.role) &&
      payloadJSON.role.some((item) => item.toLowerCase().includes('admin')));

  // const to store array which is used to store module if user has access to specific module
  const hasAccessToModulesArray: CaddieModuleType[] = [];

  // if user role is string and it contains 'calfmilkration' or it is array and array contains 'calfmilkration' then add 'calf-milk-replacer' to the array
  if (
    (typeof payloadJSON.role === 'string' &&
      payloadJSON.role.toLowerCase().includes('calfmilkration')) ||
    (Array.isArray(payloadJSON.role) &&
      payloadJSON.role.length > 0 &&
      payloadJSON.role.some((item) => item.toLowerCase().includes('calfmilkration')))
  ) {
    // adding 'calf-milk-replacer' to the array
    hasAccessToModulesArray.push('calf-milk-ration');
  }

  // if user role is string and it contains 'colostrumfeedingapp' or it is array and array contains 'colostrumfeedingapp' then add 'colostrum-replacer' to the array
  if (
    (typeof payloadJSON.role === 'string' &&
      payloadJSON.role.toLowerCase().includes('colostrumfeedingapp')) ||
    (Array.isArray(payloadJSON.role) &&
      payloadJSON.role.length > 0 &&
      payloadJSON.role.some((item) => item.toLowerCase().includes('colostrumfeedingapp')))
  ) {
    // adding 'colostrum-replacer' to the array
    hasAccessToModulesArray.push('colostrum-management');
  }

  return {
    ag2ag: payloadJSON.ag2ag,
    email: payloadJSON.mail,
    firstName: payloadJSON.fname,
    lastName: payloadJSON.lname,
    role: payloadJSON.role,
    clientId: payloadJSON.client_id,
    isAdmin: isAdminPresent,
    hasAccessToModules: hasAccessToModulesArray,
  };
};

// Get auth code from redirect URI and get tokens from the auth code
export const handleRedirectCallback = async (): Promise<AuthDataWithoutUserType> => {
  const url = window.location.href;

  // Get the authorization code from redirect URI called by auth server
  const queryStringFragments = url.split('?').slice(1);

  if (queryStringFragments.length === 0) {
    throw new Error('There are no query params available for parsing.');
  }

  // extracting the auth code parsed from query string
  const { code } = parseQueryResult(queryStringFragments.join(''));

  if (!code) {
    throw new Error('No code returned by auth server.');
  }

  // Get token from auth code
  const authDataWithoutUser = await getAccessToken(code);

  return authDataWithoutUser;
};

// Create the PKCE authorization URL for SSO
export const buildAuthorizeUrl = async (): Promise<string> => {
  const codeVerifier = createRandomString();

  const codeChallengeBuffer = await sha256(codeVerifier);

  const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer as Uint8Array);

  // Save the code verifier to session storage for use in redirect callback
  SessionStorage.save(SESSION_STORAGE_KEYS.codeVerifier, codeVerifier);

  // Get client id (internal or external depending on the user who is trying to login) from session storage
  const clientId = SessionStorage.get(SESSION_STORAGE_KEYS.clientId);

  return `${AUTHORIZATION_ENDPOINT}?client_id=${
    clientId || ''
  }&response_type=code&scope=openid&redirect_uri=${
    process.env.REACT_APP_PINGFEDERATE_REDIRECT_URI || ''
  }&code_challenge_method=S256&code_challenge=${codeChallenge}`;
};

export default null;
