import {
  ByCalfColostrumReplacerOutputType,
  ByCalfColostrumReplacerInputsType,
} from '@ecp32/calculations';
import {
  CalculatorCalculatedFieldType,
  ColostrumReplacerCalculatorTypePropertyType,
  MeasurementsSystemSubType,
} from '../../utils/types';

// type to define type of feeding values
export type FeedingType = 'firstFeeding' | 'secondFeeding';

// type for ByCalf calculator calculation data which is used to pass state for by calf calculation history screen modal
export type CalculationDataForByCalfCalculatorType = {
  // type definition for reference name
  referenceName: string;
  // type definition for calculation input data
  selectedCalculationsInputData: ByCalfColostrumReplacerInputsType;
  // type definition for calculation output data
  selectedCalculationsOutputData: Partial<ByCalfColostrumReplacerOutputType>;
};
// type for ByCalf calculator form
export type ByCalfFormType = ByCalfColostrumReplacerInputsType & {
  // type for field which store selected calf id
  calfId: string;
  // type for field which store type of feeing value
  typeOfFeeding: FeedingType;
};

// type use to store keys of inputs
export type InputKeysType = keyof ByCalfColostrumReplacerInputsType;

// type use to store outputs keys
type OutputKeysType = keyof ByCalfColostrumReplacerOutputType;

// type to define pooled calculator all fields object type
export type ByCalfColostrumReplacerDataType = {
  // type for key which is use to store fields key
  key: InputKeysType | OutputKeysType | 'calfId' | 'typeOfFeeding';
  // type for property which determine type of field this can be input, calculated, fixed
  type: ColostrumReplacerCalculatorTypePropertyType;
  /* Calculator calculated field type */
  calculatedType?: CalculatorCalculatedFieldType;
  /* latest description of each calculator field */
  description: {
    /* Label of the field */
    label: string | ((type: string) => string);
    /* latest unit of the field */
    unit?: string;
    // unit system to be called for unit
    unitKey?: MeasurementsSystemSubType;
  };
  // for fields with field input type this option to be set to true
  isSelectOption?: boolean;
  // for fields with field radio type this option to be set to true
  isRadioButton?: boolean;
};

// const use to store pooled calculator fields object
export const byCalfColostrumReplacerAllFields: ByCalfColostrumReplacerDataType[] = [
  {
    key: 'typeOfFeeding',
    type: 'input',
    description: {
      label: 'Type of feeding',
    },
    isRadioButton: true,
  },
  {
    key: 'calfId',
    type: 'input',
    description: {
      label: (type: string) => `${type === 'secondFeeding' ? 'Select' : 'Enter'} Calf ID`,
    },
    isSelectOption: true,
  },
  {
    key: 'unit',
    type: 'fixed',
    description: {
      label: 'Unit',
    },
  },
  {
    key: 'desiredMaxAmtForFirstFeeding',
    type: 'fixed',
    description: {
      label: (unit: string) => `Desired Max ${unit} first feeding`,
      unitKey: 'defaultVolumeUnit',
    },
  },
  {
    key: 'desiredMaxAmtForSecondFeeding',
    type: 'fixed',
    description: {
      label: (unit: string) => `Desired Max ${unit} second feeding`,
      unitKey: 'defaultVolumeUnit',
    },
  },
  {
    key: 'igGInColostrumReplacer',
    type: 'fixed',
    description: {
      label: '% IgG in Colostrum Replacer',
    },
  },

  {
    key: 'birthBodyWt',
    type: 'input',
    description: {
      label: (unit: string) => `Birth body weight in ${unit}`,
      unitKey: 'defaultCalfWtUnit',
    },
  },
  {
    key: 'brixReading',
    type: 'input',
    description: {
      label: (type: string) =>
        `${type === 'secondFeeding' ? 'Second' : 'First'} feeding Brix reading (%)`,
    },
  },
  {
    key: 'brixUsedInPrevCalculation',
    type: 'input',
    description: {
      label: (type: string) =>
        `${
          type === 'secondFeeding'
            ? 'First feeding Brix reading (%)'
            : 'Second Feeding Brix Reading (%)'
        } `,
    },
  },

  {
    key: 'minAmtNeededInFirstFeeding',
    type: 'calculated',
    description: {
      label: (unit: string) => `Minimum ${unit} needed in first feeding`,
      unitKey: 'defaultVolumeUnit',
    },
  },
  {
    key: 'crPowderNeededInFirstFeeding',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit} of Colostrum Replacer powder needed for first feeding`,
      unitKey: 'defaultMassUnit',
    },
  },
  {
    key: 'amtNeededInSecondFeeding',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit} needed in second feeding`,
      unitKey: 'defaultVolumeUnit',
    },
  },
  {
    key: 'amtOfCrUsedInSecondFeeding',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit} of Colostrum Replacer powder needed in second feeding`,
      unitKey: 'defaultMassUnit',
    },
  },
];

// type used in colostrum replacer app to identify calf which are selected for calculation
export type CalfSelectedForCalculationType = {
  // temporary id is use to identify form  until we get calfId
  temporaryId: string;
  // store id of calf which is selected in by-calf form
  calfId: string;
};
