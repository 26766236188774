import React, { useState } from 'react';
import { Button, message, Select, Spin, Table } from 'antd';
import { loader } from 'graphql.macro';
import dayjs from 'dayjs';
import { EyeOutlined, FilterFilled, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { useQuery, useLazyQuery } from '@apollo/client';
import WarningText from '../../components/WarningText';
import FormItem from '../../components/FormItem';
import ColumnsFilterComponent from '../../components/ColumnsFilterComponent';
import CalculatorHistoryViewModalComponent from '../../components/CalculatorHistoryViewModalComponent';
import {
  FarmOptionsQuery,
  FarmOptionsQueryVariables,
  CalculationsOfFarmQuery,
  CalculationsOfFarmQueryVariables,
  Enum_Calculator_Enum,
} from '../../graphql/graphql-types';
import { FarmCalculationsDataType } from '../../utils/types';
import { logger } from '../../utils/helpers';

import EditCalculatorModalComponent from '../../components/EditCalculatorModalComponent';
import CalculationDltBtnWithPopconfirm from '../../components/CalculationDltBtnWithPopconfirm';

const { Option } = Select;
const { Column } = Table;

const farmOptionsQuery = loader('../../graphql/queries/farmOptionsQuery.graphql');
const calculationsOfFarmQuery = loader('../../graphql/queries/calculationsOfFarmQuery.graphql');

/* Object to store label for each calculator type */
const calculatorTypesWithLabels: Record<
  Exclude<Enum_Calculator_Enum, Enum_Calculator_Enum.ColRepPooled>,
  string
> = {
  [Enum_Calculator_Enum.CmrSetSolids]: 'CMR Set Solids',
  [Enum_Calculator_Enum.PmrbNoWater]: 'PMRB No Water',
  [Enum_Calculator_Enum.PmWater]: 'PM & Water',
};

/* React functional component */
const CalculatorsHistoryScreen: React.FC = () => {
  /* State to store selected calculation ID that will be deleted use to show loading indicator on delete btn of that
  particular row */
  const [selectedCalculationIdForDeletion, setSelectedCalculationIdForDeletion] = useState<
    string | undefined
  >(undefined);

  /* State to store selected calculation data for view btn  */
  const [selectedCalculationsData, setSelectedCalculationsData] = useState<
    FarmCalculationsDataType | undefined
  >(undefined);

  /* State to store selected calculation data for view btn  */
  const [selectedCalculatorToEditData, setSelectedCalculatorToEditData] = useState<
    FarmCalculationsDataType | undefined
  >(undefined);

  /* Query to fetch farm options data */
  const { loading, data, error } = useQuery<FarmOptionsQuery, FarmOptionsQueryVariables>(
    farmOptionsQuery,
  );

  /* Query to fetch calculation of the selected farm */
  const [
    calculationsOfFarm,
    {
      called,
      loading: calculationsOfFarmLoading,
      error: calculationsOfFarmErr,
      data: calculationsOfFarmData,
    },
  ] = useLazyQuery<CalculationsOfFarmQuery, CalculationsOfFarmQueryVariables>(
    calculationsOfFarmQuery,
  );

  /* Showing loading indicator while fetching farm options */
  if (loading) {
    return (
      <div className="loadingIndicator">
        <Spin size="large" />
      </div>
    );
  }

  /* If any error occurs while fetching farm options data */
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  /* Variable to store farm options data */
  const farmOptionsData = data?.farm;

  /* Variable to store farm calculations data */
  const farmCalculationsData = calculationsOfFarmData?.calculation;

  /* Function to render calculations of the farm table */
  const renderCalculationsOfFarmTable = () => {
    /* If any error occurred while fetching calculations data */
    if (calculationsOfFarmErr) {
      return <div className="errorMessage">{calculationsOfFarmErr.message}</div>;
    }

    return (
      <div style={{ padding: 40 }}>
        <Table<FarmCalculationsDataType>
          dataSource={farmCalculationsData as FarmCalculationsDataType[]}
          bordered
          size="middle"
          loading={calculationsOfFarmLoading}
          rowKey="id"
        >
          <Column<FarmCalculationsDataType>
            key="name"
            title="Name"
            align="center"
            dataIndex="name"
            filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <ColumnsFilterComponent
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex="name"
                type="search"
              />
            )}
            filterIcon={(filtered) => (
              <SearchOutlined style={{ color: filtered ? 'var(--color-lightBlue)' : undefined }} />
            )}
            onFilter={(value, record) =>
              record.name
                ? record.name.toLowerCase().includes(value.toString().toLowerCase())
                : false
            }
            render={(text: string) => text || '-'}
          />

          <Column<FarmCalculationsDataType>
            key="calculator"
            title="Calculator"
            align="center"
            dataIndex="calculator"
            filters={[
              {
                text: calculatorTypesWithLabels.cmr_set_solids,
                value: Enum_Calculator_Enum.CmrSetSolids,
              },
              {
                text: calculatorTypesWithLabels.pmrb_no_water,
                value: Enum_Calculator_Enum.PmrbNoWater,
              },
              {
                text: calculatorTypesWithLabels.pm_water,
                value: Enum_Calculator_Enum.PmWater,
              },
            ]}
            filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
              <ColumnsFilterComponent
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex="name"
                type="options"
                filters={filters}
              />
            )}
            onFilter={(value, record) => record.calculator.indexOf(value as string) === 0}
            filterIcon={(filtered) => (
              <FilterFilled style={{ color: filtered ? 'var(--color-lightBlue)' : undefined }} />
            )}
            render={(text: Exclude<Enum_Calculator_Enum, Enum_Calculator_Enum.ColRepPooled>) =>
              calculatorTypesWithLabels[text]
            }
          />

          <Column
            key="created_at "
            title="Date"
            align="center"
            dataIndex="created_at"
            render={(text: string) => dayjs(text).format('DD MMMM, YYYY') || '-'}
          />

          <Table.Column<FarmCalculationsDataType>
            key="actions"
            title="Actions"
            align="center"
            dataIndex="actions"
            width={600}
            render={(text, record) => (
              <>
                <Button
                  icon={<EditOutlined />}
                  className="buttonColorRed"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setSelectedCalculatorToEditData(record);
                  }}
                >
                  Edit
                </Button>
                <Button
                  icon={<EyeOutlined />}
                  className="buttonColorRed"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setSelectedCalculationsData(record);
                  }}
                >
                  View
                </Button>

                <CalculationDltBtnWithPopconfirm
                  calculationId={record.id}
                  calculationIdToDltAndShowLoading={selectedCalculationIdForDeletion}
                  logger={logger}
                  message={message}
                  record={record}
                  setCalculationIdToDltAndShowLoading={setSelectedCalculationIdForDeletion}
                />
              </>
            )}
          />
        </Table>
      </div>
    );
  };

  return (
    <>
      {selectedCalculationsData ? (
        <CalculatorHistoryViewModalComponent
          calculationData={selectedCalculationsData}
          updateCalculationData={setSelectedCalculationsData}
        />
      ) : null}

      {selectedCalculatorToEditData ? (
        <EditCalculatorModalComponent
          calculationData={selectedCalculatorToEditData}
          setCalculationData={setSelectedCalculatorToEditData}
        />
      ) : null}

      {Array.isArray(farmOptionsData) && farmOptionsData.length > 0 ? (
        <>
          <FormItem label="Select a farm:">
            <Select
              showSearch
              style={{ width: 350 }}
              placeholder="Select a farm"
              optionFilterProp="children"
              filterOption={(input, option) => {
                if (option) {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
              onChange={(val: string) => {
                calculationsOfFarm({
                  variables: {
                    farm_id: val,
                  },
                });
              }}
            >
              {farmOptionsData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormItem>

          {called && calculationsOfFarmLoading ? (
            <div className="loadingIndicator" style={{ marginTop: 40 }}>
              <Spin size="large" />
            </div>
          ) : null}

          {farmCalculationsData ? renderCalculationsOfFarmTable() : null}
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <WarningText customStyles={{ width: 570, margin: 'auto' }}>
            <div>
              Please add a farm using <b>'Add new farm'</b> tab to begin using the app.
            </div>
          </WarningText>
        </div>
      )}
    </>
  );
};

export default CalculatorsHistoryScreen;
