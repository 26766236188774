import { Button, Col, Modal, Row, message } from 'antd';
import React, { useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import SectionHeaderComponent from '../SectionHeaderComponent';
import SettingsFixedFieldsComponent from './SettingsFixedFieldsComponent';
import getByCalfFieldsBasedOnProperty from '../../utils/colostrum-replacer/getByCalfFieldsBasedOnProperty';
import renderLabelBasedOnUnit from '../../utils/colostrum-replacer/renderLabelBasedOnUnit';
import CalculatedOutputFieldsComponent from './CalculatedOutputFieldsComponent';
import styles from '../../forms/calculator/CalculatorForms.module.scss';
import {
  CalculationDataForByCalfCalculatorType,
  byCalfColostrumReplacerAllFields,
} from '../../calculator/colostrum-replacer-calculator/ByCalf';
import measurementSystemOptions from '../../utils/colostrum-replacer/measurementSystemOptions';
import {
  Enum_Measurement_System_Enum,
  GenerateColostrumReplacerReportMutation,
  GenerateColostrumReplacerReportMutationVariables,
} from '../../graphql/graphql-types';
import renderLabelBasedOnTypeOfFeeding from '../../utils/colostrum-replacer/renderLabelBasedOnTypeOfFeeding';
import { logger } from '../../utils/helpers';
import downloadFileFromUrl from '../../utils/colostrum-replacer/downloadFileFromUrl';
import getColostrumReplacerAllFieldsWithLabels from '../../utils/colostrum-replacer/getColostrumReplacerAllFieldsWithLabels';
import ColostrumReplacerReportNameModal from '../../screens/colostrum-replacer/ColostrumReplacerReportNameModal';
import ResearchProviderInfoBar from './ResearchProviderInfoBar';

const generateColostrumReplacerReportMutation = loader(
  '../../graphql/mutations/generateColostrumReplacerReportMutation.graphql',
);

// type definition for by calf calculator history view modal component
type ByCalfHistoryViewModalComponentType = {
  // type definition for calculation data for by calf calculator
  calculationDataForByCalfCalculator: CalculationDataForByCalfCalculatorType;
  // type definition to set state for calculation data for by calf calculator
  setCalculationDataForByCalfCalculator: React.Dispatch<
    React.SetStateAction<CalculationDataForByCalfCalculatorType | null>
  >;
  // type definition for selected farm name
  selectedFarmName: string;
};

// calling getByCalfFieldsBasedOnProperty to get input fields, fixed fields, section wise fields for by-calf calculator
const {
  byCalfInputFields,
  byCalfFixedFields,
  byCalfOutputFields,
} = getByCalfFieldsBasedOnProperty();

const ByCalfHistoryViewModalComponent = ({
  calculationDataForByCalfCalculator,
  setCalculationDataForByCalfCalculator,
  selectedFarmName,
}: ByCalfHistoryViewModalComponentType): JSX.Element => {
  // destructing calculation data for by calf calculator state
  const {
    referenceName,
    selectedCalculationsInputData,
    selectedCalculationsOutputData,
  } = calculationDataForByCalfCalculator;

  // mutation to generate colustrum replacer by calf calculator report
  const [generateColostrumReport] = useMutation<
    GenerateColostrumReplacerReportMutation,
    GenerateColostrumReplacerReportMutationVariables
  >(generateColostrumReplacerReportMutation);

  // const use to store unit field label and value object
  const unitFieldObject = measurementSystemOptions.find(
    (item) => item.value === selectedCalculationsInputData.unit,
  );

  // state to show enter report name modal when user clicks on Generate report for selected calves button
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState<boolean>(false);

  // state to show loading indicator when user clicks on done button after entering report name for multiple calf report generation
  const [isDoneBtnLoading, setIsDoneBtnLoading] = useState<boolean>(false);

  // const to store keys which we have to filter out during display of input fields in modal
  const inputFieldFilterKeys = ['typeOfFeeding', 'calfId'];

  // const to store keys which we have to filter out during display of output fields in modal when type of feeding is first feeding
  const outputFieldFilterKeys = ['amtNeededInSecondFeeding', 'amtOfCrUsedInSecondFeeding'];

  // if first feeding then insert key in input key field array
  if (selectedCalculationsInputData.brixUsedInPrevCalculation === 0) {
    inputFieldFilterKeys.push('brixUsedInPrevCalculation');
  }

  // const to filter by calf input fields data
  const byCalfFilteredInputFields = byCalfInputFields.filter(
    (item) => !inputFieldFilterKeys.includes(item.key),
  );

  // const to filter by calf output fields data
  const byCalfFilteredOutputFields =
    selectedCalculationsInputData.brixUsedInPrevCalculation === 0
      ? byCalfOutputFields.filter((item) => !outputFieldFilterKeys.includes(item.key))
      : byCalfOutputFields;

  // const to store type of feeding
  const typeOfFeeding =
    selectedCalculationsInputData.brixUsedInPrevCalculation === 0
      ? 'firstFeeding'
      : 'secondFeeding';

  return (
    <>
      {isGenerateReportModalOpen ? (
        <ColostrumReplacerReportNameModal
          onOk={async (e, formData, reset) => {
            setIsDoneBtnLoading(true);

            try {
              if (unitFieldObject) {
                if (unitFieldObject) {
                  // const to store all input and output label fields
                  const allFieldsWithLabel = getColostrumReplacerAllFieldsWithLabels({
                    allFieldsLabels: byCalfColostrumReplacerAllFields,
                  });

                  // const to store generate report mutation variable data which is used to passed for generate report mutation
                  const generateReportMutationVariable = {
                    calculator: 'by_calf',
                    farm_name: selectedFarmName,
                    report_name: formData.name,
                    calculator_fields_labels: {
                      [Enum_Measurement_System_Enum.UsCustomary]:
                        allFieldsWithLabel.colostrumReplacerAllFieldsWithLabelsForUsCustomary,
                      [Enum_Measurement_System_Enum.Metric]:
                        allFieldsWithLabel.colostrumReplacerAllFieldsWithLabelsForMetric,
                    },
                    calculation_data: [
                      {
                        inputs_fields: {
                          ...selectedCalculationsInputData,
                          igGInColostrumReplacer:
                            selectedCalculationsInputData.igGInColostrumReplacer,
                          typeOfFeeding,
                          calfId: referenceName,
                        },
                        calculated_fields: selectedCalculationsOutputData,
                      },
                    ],
                  };

                  // mutation call to generate by calf report for multiple selected calf id
                  const generateReport = await generateColostrumReport({
                    variables: { data: JSON.stringify(generateReportMutationVariable) },
                  });

                  if (
                    !generateReport ||
                    !generateReport.data ||
                    !generateReport.data.generateColostrumReplacerReport ||
                    !generateReport.data.generateColostrumReplacerReport.fileName ||
                    !generateReport.data.generateColostrumReplacerReport.report_url
                  ) {
                    throw new Error('Report URL not found');
                  }

                  await downloadFileFromUrl({
                    url: generateReport.data.generateColostrumReplacerReport.report_url,
                    fileName: generateReport.data.generateColostrumReplacerReport.fileName,
                  });

                  setIsDoneBtnLoading(false);
                  setIsGenerateReportModalOpen(false);

                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.success('Report downloaded successfully');
                }
              }
            } catch (err) {
              const errObj = err as ApolloError;
              logger(errObj);
              setIsDoneBtnLoading(false);
              setIsGenerateReportModalOpen(false);
              reset();
            }
          }}
          onCancel={() => {
            setIsGenerateReportModalOpen(false);
          }}
          isDoneBtnLoading={isDoneBtnLoading}
          isGenerateReportModalOpen={isGenerateReportModalOpen}
        />
      ) : null}

      <Modal
        visible={!!selectedCalculationsInputData}
        footer={null}
        width={1200}
        onCancel={() => {
          setCalculationDataForByCalfCalculator(null);
        }}
      >
        <Row style={{ columnGap: 100 }}>
          <Col span={10}>
            <SectionHeaderComponent title="Inputs" />
            <Row gutter={50} style={{ marginTop: 10, marginBottom: 10 }}>
              <Col span={13}>Calf ID</Col>
              <Col>{referenceName}</Col>
            </Row>

            {/* render fixed fields */}
            <SettingsFixedFieldsComponent
              fieldData={byCalfFixedFields.filter((item) => item.key !== 'igGInColostrumReplacer')}
              settingsFieldValue={{
                unit: selectedCalculationsInputData.unit,
                desiredMaxAmtForFirstFeeding:
                  selectedCalculationsInputData.desiredMaxAmtForFirstFeeding,
                desiredMaxAmtForSecondFeeding:
                  selectedCalculationsInputData.desiredMaxAmtForSecondFeeding,
              }}
              customStyle={{ marginTop: 10, marginBottom: 20 }}
            />

            {/* render input fields */}
            {byCalfFilteredInputFields.map((ele) => (
              <Row gutter={50} style={{ marginTop: 20, marginBottom: 20 }} key={ele.key}>
                <Col span={13}>
                  {ele.key === 'brixReading' || ele.key === 'brixUsedInPrevCalculation'
                    ? renderLabelBasedOnTypeOfFeeding(ele.description.label, typeOfFeeding)
                    : renderLabelBasedOnUnit(
                        ele.description.label,
                        selectedCalculationsInputData.unit,
                        ele.description.unitKey,
                      )}
                </Col>
                <Col span={8}>
                  {(selectedCalculationsInputData &&
                    selectedCalculationsInputData[
                      ele.key as keyof typeof selectedCalculationsInputData
                    ]) ||
                    '-'}
                </Col>
              </Row>
            ))}
          </Col>

          <Col span={10}>
            <SectionHeaderComponent title="By Calf Calculator Result" />
            {/* render per calf fields */}
            <CalculatedOutputFieldsComponent
              fieldData={byCalfFilteredOutputFields}
              outputValues={selectedCalculationsOutputData}
              selectedUnitValue={selectedCalculationsInputData.unit}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'grid', margin: 'auto' }}>
            <Button
              className={`${styles.saveBtn} buttonColorRed`}
              onClick={() => {
                setIsGenerateReportModalOpen(true);
              }}
              style={{ marginTop: 10 }}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
        <ResearchProviderInfoBar />
      </Modal>
    </>
  );
};

export default ByCalfHistoryViewModalComponent;
