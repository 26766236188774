import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import localforage from 'localforage';
import AuthContext from './AuthContext';
import { logger } from '../helpers';
import {
  decodeAccessToken,
  handleRedirectCallback,
  hasAuthParams,
  AuthDataType,
  AuthDataWithoutUserType,
  pingfederateRefreshAccessToken,
  buildAuthorizeUrl,
  SessionStorage,
  SESSION_STORAGE_KEYS,
  AuthUserDataType,
} from '../pingfederate/pingfederate';
import { LOCALFORAGE_KEY } from '../globals';

// Props of the component
type Props = {
  children: ReactNode;
};

const AuthProvider = (props: Props): JSX.Element => {
  const { children } = props;

  // Is the user logged in?
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  // Loading state for this component
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // The access token of logged in user
  const [accessToken, setAccessToken] = useState<string>('');

  // The expiry timestamp of access token
  const [accessTokenExpirationDate, setAccessTokenExpirationDate] = useState<string>('');

  // Refresh token of logged in user
  const [refreshToken, setRefreshToken] = useState<string>('');

  // state to store user details
  const [user, setUser] = useState<AuthUserDataType | null>(null);

  // Stop loading after 1 second delay
  const delayedStopLoading = useCallback(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  // Update the states of auth data
  const updateAuthStates = useCallback(
    (
      newAccessToken: string,
      newAccessTokenExpirationDate: string,
      newRefreshToken,
      userData: AuthUserDataType,
    ) => {
      setRefreshToken(newRefreshToken);
      setAccessToken(newAccessToken);
      setAccessTokenExpirationDate(newAccessTokenExpirationDate);
      setUser(userData);
    },
    [],
  );

  // Whenever token is fetched we call this function. This function:
  //  1. Extracts user data from access token's payload
  //  2. Stores auth data to local storage
  //  3. Updates the auth states of this component
  const postTokenOperations = useCallback(
    async (opts: AuthDataWithoutUserType): Promise<AuthDataType> => {
      // Decode access token to get user's data
      const userData = decodeAccessToken(opts.accessToken);

      // Store the auth data to local storage
      const authData = {
        accessToken: opts.accessToken,
        accessTokenExpirationDate: opts.accessTokenExpirationDate,
        refreshToken: opts.refreshToken,
        user: userData,
      };

      try {
        await localforage.setItem(LOCALFORAGE_KEY, authData);
      } catch (error) {
        // TODO: Decide what to do in this case
      }

      // Update states
      updateAuthStates(
        opts.accessToken,
        opts.accessTokenExpirationDate,
        opts.refreshToken,
        userData,
      );

      return authData;
    },
    [updateAuthStates],
  );

  // Get new access token from refresh token
  const refreshAccessToken = useCallback(
    async (
      refresh_token: string,
    ): Promise<{ status: 'success' | 'error' | 'logout'; authData?: AuthDataType }> => {
      try {
        // Run the refresh token grant by calling the token endpoint
        const refreshResults = await pingfederateRefreshAccessToken(refresh_token);

        if (!refreshResults.refreshToken) {
          return {
            status: 'logout',
          };
        }

        const authData = await postTokenOperations({
          accessToken: refreshResults.accessToken,
          accessTokenExpirationDate: refreshResults.accessTokenExpirationDate,
          refreshToken: refreshResults.refreshToken,
        });

        return {
          status: 'success',
          authData,
        };
      } catch (error) {
        logger(error as Error);

        const pingfederateError = error as AxiosError<{ error: string }>;

        if (
          pingfederateError.response?.data.error &&
          pingfederateError.response?.data.error === 'invalid_grant'
        ) {
          return {
            status: 'logout',
          };
        }
        return {
          status: 'error',
        };
      }
    },
    [postTokenOperations],
  );

  // Send user's to IdP's login page to SSO login
  const login = useCallback(async () => {
    const url = await buildAuthorizeUrl();

    window.location.assign(url);
  }, []);

  // Auth logout function
  const logout = useCallback(async () => {
    setIsLoading(true);

    // Clear localstorage
    await localforage.removeItem(LOCALFORAGE_KEY);

    // Removing client id from session storage when logout button is clicked
    SessionStorage.remove(SESSION_STORAGE_KEYS.clientId);

    // Clear states
    setAccessToken('');

    setAccessTokenExpirationDate('');

    setRefreshToken('');

    setUser(null);

    /* Here we are setting isAuthenticated to false. Hence the user will be directed to the initial login screen with
       internal and external login buttons */
    setIsAuthenticated(false);

    delayedStopLoading();
  }, [delayedStopLoading]);

  // Function to call when we want to make the user login again with SSO
  const reLogin = useCallback(() => {
    /* Here we are setting isAuthenticated to false. Hence the user will be directed to the initial login screen with
       internal and external login buttons */
    setIsAuthenticated(false);
    setIsLoading(false);
  }, []);

  // ComponentDidMount
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async (): Promise<void> => {
      try {
        // Auth server has redirected to the app with auth code
        // Check if redirected url contains the code param
        if (hasAuthParams()) {
          // Handle authorization callback
          const authDataWithoutUser = await handleRedirectCallback();

          await postTokenOperations(authDataWithoutUser);
        } else {
          // Check if auth data is already preset in local storage
          const value = await localforage.getItem<AuthDataType>(LOCALFORAGE_KEY);

          // No auth data in storage; User will have to login
          if (value === null) {
            /* Here we are setting isAuthenticated to false. Hence the user will be directed to the initial login screen with
               internal and external login buttons */
            setIsAuthenticated(false);
            delayedStopLoading();

            return;
          }

          // User data present in local storage
          const authData = value;

          // Update states
          updateAuthStates(
            authData.accessToken,
            authData.accessTokenExpirationDate,
            authData.refreshToken,
            authData.user,
          );

          // Get new access and refresh tokens from auth server
          const refreshStatus = await refreshAccessToken(authData.refreshToken);

          if (refreshStatus.status === 'logout') {
            reLogin();
            return;
          }
        }

        setIsAuthenticated(true);

        delayedStopLoading();
      } catch (error) {
        reLogin();
      }
    })();
  }, [
    refreshAccessToken,
    updateAuthStates,
    reLogin,
    delayedStopLoading,
    postTokenOperations,
    login,
  ]);

  // Get the access token for attaching as Bearer token in API calls
  const getAccessToken = useCallback(async (): Promise<string> => {
    // First we will check the expiry time of stored access token
    const tokenExpiry = dayjs(accessTokenExpirationDate);

    // Token is expired
    if (tokenExpiry.isBefore(dayjs())) {
      try {
        // Get new access and refresh tokens from auth server
        const refreshStatus = await refreshAccessToken(refreshToken);

        if (refreshStatus.status === 'logout') {
          reLogin();
          return '';
        }

        return refreshStatus.authData?.accessToken || '';
      } catch (error) {
        // We will log out the user if we are not able to fetch new access token
        reLogin();
      }
    }
    return accessToken;
  }, [accessToken, accessTokenExpirationDate, refreshAccessToken, reLogin, refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        getAccessToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
