import { ApolloError, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { Button, Col, Row, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Enum_User_Role_Enum } from '@ecp32/calculations/src/graphql';
import {
  FarmOptionsQuery,
  FarmOptionsQueryVariables,
  UpdateFarmIsPoolingMutation,
  UpdateFarmIsPoolingMutationVariables,
} from '../../graphql/graphql-types';
import FormItem from '../../components/FormItem';
import WarningText from '../../components/WarningText';
import Select from '../../components/Select';
import RadioGroup from '../../components/RadioGroup';
import { logger } from '../../utils/helpers';

const farmOptionsQuery = loader('../../graphql/queries/farmOptionsQuery.graphql');
const updateFarmIsPoolingMutation = loader(
  '../../graphql/mutations/updateFarmIsPoolingMutation.graphql',
);

// type for farm selection form
type FarmFormType = {
  // selected farm id type
  farmId: string | null;
  // form field type which decide for pooled type calculator
  isPooledCalculator: boolean | null;
};

// yup object schema to define validations
const schema = yup.object().shape({
  isPooledCalculator: yup.boolean().required('Please select pooling option').nullable(),
});

const ColostrumReplacerCalculator = (): JSX.Element => {
  // navigate variable contain useNavigate hooks
  const navigate = useNavigate();

  // useLocation hook to get state from setting screen
  const location = useLocation();

  // const to store state
  const stateValue = location.state as { farmId: string };

  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState<boolean>(false);

  /* useForm declaration */
  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FarmFormType>({
    defaultValues: {
      farmId: null,
      isPooledCalculator: null,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* Query to fetch farm options data */
  const { loading, data, error } = useQuery<FarmOptionsQuery, FarmOptionsQueryVariables>(
    farmOptionsQuery,
    {
      fetchPolicy: 'network-only',
      context: {
        /** Explicitly passing x-hasura role to handle farm so that user can perform calculations on farms which are created by them only */
        headers: {
          'x-hasura-role': Enum_User_Role_Enum.User,
        },
      },
    },
  );

  /** Mutation to update farm by checking whether its using pooled calculator (pooling colostrum) for calculation or not */
  const [updateFarmIsPooling] = useMutation<
    UpdateFarmIsPoolingMutation,
    UpdateFarmIsPoolingMutationVariables
  >(updateFarmIsPoolingMutation);

  // const to store selected farm id
  const selectedFarmId = watch('farmId');

  /** const to store pooling colostrum or not */
  const isPoolingColostrum = watch('isPooledCalculator');

  // const to store all farms data
  const farmOptionsData = data && data.farm;

  // const to store selected farm's complete data
  const selectedFarmData =
    farmOptionsData && farmOptionsData.find((farm) => farm.id === selectedFarmId);

  // useEffect to show selected farm when user sets setting for particular farm
  useEffect(() => {
    reset({
      farmId: stateValue ? stateValue.farmId : selectedFarmId,
      isPooledCalculator: selectedFarmData && selectedFarmData.col_rep_is_pooling,
    });
  }, [navigate, reset, selectedFarmData, selectedFarmId, stateValue]);

  /* Showing loading indicator while fetching farm options */
  if (loading) {
    return (
      <div className="loadingIndicator">
        <Spin size="large" />
      </div>
    );
  }

  /* If any error occurs while fetching farm options data */
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  // boolean use to decide whether to show select screen or calculator selection screen based on settings fields value
  const isFarmSettingsSet = !!(
    selectedFarmData &&
    selectedFarmData.colostrum_max_first_fdg &&
    selectedFarmData.colostrum_max_second_fdg &&
    selectedFarmData.col_rep_measurement_system_id
  );

  // function to render button, warning based on settings
  const renderFormBtnAndSettingWarning = () => {
    if (selectedFarmId) {
      if (isFarmSettingsSet) {
        return (
          <form
            onSubmit={handleSubmit(() => {
              setIsSaveBtnLoading(true);
              updateFarmIsPooling({
                variables: {
                  id: selectedFarmId,
                  col_rep_is_pooling: !!isPoolingColostrum,
                },
              })
                .then(() => {
                  navigate(
                    isPoolingColostrum ? `${selectedFarmId}/pooled` : `${selectedFarmId}/by-calf`,
                  );
                  setIsSaveBtnLoading(false);
                })
                .catch((err: ApolloError) => {
                  logger(err);
                  setIsSaveBtnLoading(false);
                });
            })}
          >
            <FormItem
              label="Are you pooling colostrum?"
              errorText={
                errors && errors.isPooledCalculator ? errors.isPooledCalculator.message : undefined
              }
              isRequired
              labelColSpan={4}
              inputColSpan={4}
            >
              <>
                <RadioGroup
                  name="isPooledCalculator"
                  rhfControllerProps={{ control }}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  radioProps={{ style: { fontSize: 14, marginRight: 30, marginBottom: 5 } }}
                />
              </>
            </FormItem>
            <Row>
              <Col offset={4} style={{ paddingLeft: 30 }}>
                <Button
                  loading={isSaveBtnLoading}
                  htmlType="submit"
                  className="buttonColorRed"
                  style={{ marginTop: 20, display: 'flex' }}
                >
                  Start
                </Button>
              </Col>
            </Row>
          </form>
        );
      }

      return (
        <Row>
          <Col offset={4} style={{ paddingLeft: 30 }}>
            <>
              <WarningText
                customStyles={{ marginBottom: 20, marginTop: 25, maxWidth: 'fit-content' }}
              >
                No settings has been set yet, Please set them and start calculation.
              </WarningText>
              <Button
                className="buttonColorRed"
                onClick={() => {
                  navigate(`/colostrum-management/settings/${selectedFarmId}`);
                }}
              >
                Go to Settings
              </Button>
            </>
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <>
      {Array.isArray(farmOptionsData) && farmOptionsData.length > 0 ? (
        <div style={{ marginLeft: 20 }}>
          <FormItem label="Select a farm:" labelColSpan={4} inputColSpan={4}>
            <Select
              rhfControllerProps={{ control }}
              name="farmId"
              placeholder="Select farm"
              selectProps={{
                showSearch: true,
                optionFilterProp: 'label',
              }}
              customStyles={{ width: 300 }}
              options={farmOptionsData.map((ele) => ({ label: ele.name, value: ele.id }))}
            />
          </FormItem>

          {/* // todo : update formItem component so that we can call button with separate formItem tag */}
          {renderFormBtnAndSettingWarning()}
        </div>
      ) : (
        <div style={{ marginTop: 25, maxWidth: 'fit-content' }}>
          <WarningText>Please add a farm to start using the app.</WarningText>
        </div>
      )}
    </>
  );
};

export default ColostrumReplacerCalculator;
