import React from 'react';
import WarningText from '../../components/WarningText';

const renderBrixReadingValidationWarning = (
  brixReading: number,
  brixUsedInPrevCalculation: number,
) => {
  if (
    brixReading < 12 ||
    brixReading > 38 ||
    (brixUsedInPrevCalculation > 1 && brixUsedInPrevCalculation < 12) ||
    brixUsedInPrevCalculation > 38
  ) {
    return (
      <WarningText customStyles={{ marginTop: 20, width: 400 }}>Out of normal range.</WarningText>
    );
  }
  return null;
};

export default renderBrixReadingValidationWarning;
