import React from 'react';
import { Button } from 'antd';
import CaddieLogo from '../../assests/images/caddieLogo.png';
import LoginScreen from '../LoginScreen';
import { logger } from '../../utils/helpers';
import useAuth from '../../utils/auth/useAuth';

const RestrictedAccessScreen = (): JSX.Element => {
  // destructuring from useAuth hook
  const { logout } = useAuth();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        height: '300px',
        textAlign: 'center',
      }}
    >
      <img src={CaddieLogo} alt="Caddie Logo" width={300} />
      <h1>
        Sorry, you don't have access to this app. Please contact your administrator for further
        assistance
      </h1>
      <Button
        className="buttonColorRed"
        style={{ width: 70 }}
        onClick={() => {
          logout()
            .then(() => {
              <LoginScreen />;
            })
            .catch((err: Error) => {
              logger(err);
            });
        }}
      >
        OK
      </Button>
    </div>
  );
};

export default RestrictedAccessScreen;
