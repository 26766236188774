import { createContext } from 'react';
import { AuthUserDataType } from '../pingfederate/pingfederate';

export type AuthContextType = {
  // Is the user logged in?
  isAuthenticated: boolean;
  // Loading state of auth context
  isLoading: boolean;
  // // The access token of logged in user
  // accessToken?: string;
  // // The expiry timestamp of access token
  // accessTokenExpirationDate?: string;
  // // Refresh token of logged in user
  // refreshToken?: string;
  // Function to get the active access token
  getAccessToken: () => Promise<string>;
  // Start's the SSO login process
  login: () => Promise<void>;
  // Auth/SSO logout
  logout: () => Promise<void>;
  // user data
  user: AuthUserDataType | null;
};

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: true,
  getAccessToken: stub,
  login: stub,
  logout: stub,
  user: null,
});

export default AuthContext;
