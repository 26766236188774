import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Table, message } from 'antd';
import dayjs from 'dayjs';
import Column from 'antd/lib/table/Column';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { PooledColostrumReplacerInputsType, pooledColostrumReplacer } from '@ecp32/calculations';
import {
  AllPooledCalculationsQuery,
  AllPooledCalculationsQueryVariables,
} from '../../graphql/graphql-types';
import ColumnsFilterComponent from '../../components/ColumnsFilterComponent';
import { logger } from '../../utils/helpers';
import CalculationDltBtnWithPopconfirm from '../../components/CalculationDltBtnWithPopconfirm';
import { CalculationDataForPooledCalculatorType } from '../../calculator/colostrum-replacer-calculator/Pooled';
import PooledHistoryViewModalComponent from '../../components/colostrum-replacer/PooledHistoryViewModalComponent';

const allPooledCalculationsQuery = loader(
  '../../graphql/queries/allPooledCalculationsQuery.graphql',
);

// prop type definition for pooled calculator history screen
type PooledCalculatorHistoryPropType = {
  // prop type for farmId
  farmId: string;
};

// type for pooled calculator history screen table
type PooledCalculatorHistoryTableType = AllPooledCalculationsQuery['calculation'][0];

const PooledCalculatorHistoryScreen = ({
  farmId,
}: PooledCalculatorHistoryPropType): JSX.Element => {
  // state to set selected calculation id for showing loading indicator after clicking on delete btn for that particular row
  const [calculationIdToDltAndShowLoading, setCalculationIdToDltAndShowLoading] = useState<
    string | undefined
  >(undefined);

  // state to store pooled calculation input data, output data and reference name for particular calf id when user clicks on view button
  const [
    calculationDataForPooledCalculator,
    setCalculationDataForPooledCalculator,
  ] = useState<CalculationDataForPooledCalculatorType | null>(null);

  // query to fetch pooled calculations data
  const { loading, data, error } = useQuery<
    AllPooledCalculationsQuery,
    AllPooledCalculationsQueryVariables
  >(allPooledCalculationsQuery, { variables: { farmId }, fetchPolicy: 'network-only' });

  // const to store pooled calculations data from query
  const pooledCalculationsData = data && data.calculation;

  // const to store farm name from query
  const farmName = data && data.farm_by_pk && data.farm_by_pk.name;

  // If any error occurs while fetching farm options data
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  return (
    <>
      {calculationDataForPooledCalculator && farmName ? (
        <PooledHistoryViewModalComponent
          calculationDataForPooledCalculator={calculationDataForPooledCalculator}
          setCalculationDataForPooledCalculator={setCalculationDataForPooledCalculator}
          selectedFarmName={farmName}
        />
      ) : null}

      <Table<PooledCalculatorHistoryTableType>
        dataSource={pooledCalculationsData}
        loading={loading}
        bordered
        size="small"
        rowKey="id"
        style={{ width: '70%' }}
      >
        <Column<PooledCalculatorHistoryTableType>
          key="name"
          title="Reference Name"
          align="center"
          dataIndex="name"
          width={500}
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsFilterComponent
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="name"
              type="search"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? 'var(--color-lightBlue)' : undefined }} />
          )}
          onFilter={(value, record) =>
            record.name ? record.name.toLowerCase().includes(value.toString().toLowerCase()) : false
          }
          render={(text: string) => text || '-'}
        />

        <Column
          key="created_at"
          title="Date"
          align="center"
          dataIndex="created_at"
          render={(text: string) => dayjs(text).format('DD MMMM, YYYY') || '-'}
        />

        <Column<PooledCalculatorHistoryTableType>
          key="actions"
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(text, record) => (
            <>
              <Button
                size="small"
                icon={<EyeOutlined />}
                className="buttonColorRed"
                style={{ marginRight: 10 }}
                onClick={() => {
                  // calling pooledColostrumReplacer calculator function
                  const outputFields = pooledColostrumReplacer(record.inputs);

                  setCalculationDataForPooledCalculator({
                    referenceName: record.name as string,
                    calculationInputData: record.inputs as PooledColostrumReplacerInputsType,
                    calculationOutputData: outputFields,
                  });
                }}
              >
                View
              </Button>

              <CalculationDltBtnWithPopconfirm
                calculationId={record.id}
                calculationIdToDltAndShowLoading={calculationIdToDltAndShowLoading}
                logger={logger}
                message={message}
                record={record}
                setCalculationIdToDltAndShowLoading={setCalculationIdToDltAndShowLoading}
              />
            </>
          )}
        />
      </Table>
    </>
  );
};

export default PooledCalculatorHistoryScreen;
