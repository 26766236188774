import React from 'react';
import { Button, message } from 'antd';
import { loader } from 'graphql.macro';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ApolloError, Reference, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import zipCodeData from '../data/usZips.json';
import { hasKey, logger } from '../utils/helpers';
import {
  AddFarmMutation,
  AddFarmMutationVariables,
  Farm,
  UpdateFarmByPkMutation,
  UpdateFarmByPkMutationVariables,
} from '../graphql/graphql-types';
import { FarmDataType } from '../utils/types';
import FormItem from '../components/FormItem';
import InputComponent from '../components/InputComponent';

const addFarmMutation = loader('../graphql/mutations/addFarmMutation.graphql');
const updateFarmByPkMutation = loader('../graphql/mutations/updateFarmByPkMutation.graphql');

// This is the type of farm data which is going to be add
type FarmFormFieldsTypes = Pick<
  Farm,
  | 'address_line_1'
  | 'address_line_2'
  | 'city'
  | 'manager_email'
  | 'manager_name'
  | 'manager_phone'
  | 'name'
  | 'state'
  | 'zipcode'
>;

// This is the types of props coming from the parent component
type FarmFormPropTypes = {
  // This is the mode of form whether form is add form or edit form
  mode: 'add' | 'edit';
  // This is the css property how to show the form in edit form drawer or in add form
  inputsFlexDirection: 'row' | 'column';
  // This is the css property for fields padding how to apply in edit form drawer or in add form
  paddingBottomInName: number;
  // This is function for controlling the open and close of the drawer
  setVisibleDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
  // This is the data for editing the farm
  farmDetailsToEdit?: FarmDataType;
};

// This is the main functional component
const FarmForm: React.FC<FarmFormPropTypes> = (props) => {
  // Destructing props
  const {
    mode,
    inputsFlexDirection,
    paddingBottomInName,
    setVisibleDrawer,
    farmDetailsToEdit,
  } = props;

  // loader for add button
  const [submitLoader, setSubmitLoader] = React.useState<boolean>(false);
  // This navigate holds the useNavigation()
  const navigate = useNavigate();

  // schema for validation
  const schema = yup.object().shape({
    name: yup.string().required('Please enter name of farm and try again'),
    zipcode: yup
      .string()
      .required('Please enter ZIP code of the farm and try again')
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Please enter valid zip code and try again'),
    manager_email: yup.string().email('Please enter valid email id and try again').nullable(),
    manager_phone: yup
      .string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        'Please enter valid phone/cell and try again',
      )
      .nullable(),
  });

  // this variable is used to store the values of useForm
  const methods = useForm({
    defaultValues: {
      address_line_1: mode === 'edit' && farmDetailsToEdit ? farmDetailsToEdit.address_line_1 : '',
      address_line_2: mode === 'edit' && farmDetailsToEdit ? farmDetailsToEdit.address_line_2 : '',
      city: mode === 'edit' && farmDetailsToEdit ? farmDetailsToEdit.city : '',
      manager_email: mode === 'edit' && farmDetailsToEdit ? farmDetailsToEdit.manager_email : '',
      manager_name: mode === 'edit' && farmDetailsToEdit ? farmDetailsToEdit.manager_name : '',
      manager_phone: mode === 'edit' && farmDetailsToEdit ? farmDetailsToEdit.manager_phone : null,
      name: mode === 'edit' && farmDetailsToEdit ? farmDetailsToEdit.name : '',
      state: mode === 'edit' && farmDetailsToEdit ? farmDetailsToEdit.state : '',
      zipcode: mode === 'edit' && farmDetailsToEdit ? farmDetailsToEdit.zipcode : '',
    },
    /* This function allows you to use any external validation library,
      Here we are using yup  */
    resolver: yupResolver(schema),
  });

  // destructuring control from methods
  const { control } = methods;

  // This mutation is used for adding the new farm in the graphql
  const [addFarm] = useMutation<AddFarmMutation, AddFarmMutationVariables>(addFarmMutation);

  // This is the mutation function for updating the values of farm details in the graphql
  const [updateFarmByPk] = useMutation<UpdateFarmByPkMutation, UpdateFarmByPkMutationVariables>(
    updateFarmByPkMutation,
  );

  // This function is invoke when user click on the add or save button
  const onSubmit = (data: FarmFormFieldsTypes) => {
    setSubmitLoader(true);
    const valuesToSend = {
      address_line_1: data.address_line_1 ? data.address_line_1 : null,
      address_line_2: data.address_line_2 ? data.address_line_2 : null,
      city: data.city ? data.city : null,
      manager_email: data.manager_email ? data.manager_email : null,
      manager_name: data.manager_name ? data.manager_name : null,
      manager_phone: data.manager_phone ? data.manager_phone : null,
      name: data.name,
      state: data.state ? data.state : null,
      zipcode: data.zipcode,
    };
    if (mode === 'add') {
      addFarm({
        variables: valuesToSend,
        // updating the cache of farm list
        update: (cache, result) => {
          // This is the new data of farm which is going to be added in the cache
          const dataToAdd = result.data?.insert_farm_one;
          // This is the modify function for the cache
          cache.modify({
            // This is the input of the modify function by which we get access to the cache data here we access farm field
            fields: {
              // existingFarmsRef by this we get all the data of farm present the cache
              farm(existingFarmsRef: Array<Reference>) {
                return [...existingFarmsRef, dataToAdd];
              },
            },
          });
        },
      })
        .then(() => {
          navigate('/farms');
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Farm is successfully added.');
          setSubmitLoader(false);
        })
        .catch((errorInAddFarm: ApolloError) => {
          logger(errorInAddFarm);
          setSubmitLoader(false);
        });
    }

    if (mode === 'edit' && farmDetailsToEdit && setVisibleDrawer) {
      updateFarmByPk({
        variables: {
          id: farmDetailsToEdit.id,
          ...valuesToSend,
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Farm is successfully updated.');
          setVisibleDrawer(false);
          setSubmitLoader(false);
        })
        .catch((errorInUpdateFarm: ApolloError) => {
          logger(errorInUpdateFarm);
          setSubmitLoader(false);
        });
    }
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <div
        style={{
          display: 'flex',
          flexDirection: inputsFlexDirection,
          paddingTop: paddingBottomInName,
        }}
      >
        <div>
          <FormItem
            label="Farm Name:"
            isRequired
            labelColSpan={6}
            inputColSpan={18}
            errorText={methods.formState.errors.name ? methods.formState.errors.name.message : ''}
          >
            <InputComponent
              placeholder="Please enter name of farm and try again"
              name="name"
              rhfControllerProps={{ control }}
            />
          </FormItem>
          <FormItem label="Address line-1:" labelColSpan={6} inputColSpan={18}>
            <InputComponent
              placeholder="Please enter address of the farm"
              name="address_line_1"
              rhfControllerProps={{ control }}
            />
          </FormItem>

          <FormItem label="Address line-2:" labelColSpan={6} inputColSpan={18}>
            <InputComponent
              placeholder="Please enter address of the farm"
              name="address_line_2"
              rhfControllerProps={{ control }}
            />
          </FormItem>

          <FormItem
            labelColSpan={6}
            inputColSpan={18}
            label="ZIP code:"
            errorText={
              methods.formState.errors.zipcode ? methods.formState.errors.zipcode.message : ''
            }
            isRequired
          >
            <InputComponent
              placeholder="Please enter ZIP code of farm"
              name="zipcode"
              rhfControllerProps={{ control }}
              onChange={(rhfOnChange, value) => {
                rhfOnChange(value);
                if (value.length === 5 && hasKey(zipCodeData, value)) {
                  const dataForEnteredZip = zipCodeData[value];
                  if (dataForEnteredZip) {
                    methods.setValue('city', dataForEnteredZip.city);
                    methods.setValue('state', dataForEnteredZip.state);
                  }
                }
              }}
            />
          </FormItem>

          <FormItem label="City:" labelColSpan={6} inputColSpan={18}>
            <InputComponent
              placeholder="Pre-filled based on ZIP code"
              name="city"
              rhfControllerProps={{ control }}
            />
          </FormItem>

          <FormItem label="State:" labelColSpan={6} inputColSpan={18}>
            <InputComponent
              placeholder="Pre-filled based on ZIP code"
              name="state"
              rhfControllerProps={{ control }}
            />
          </FormItem>
        </div>
        <div>
          <FormItem label="Manager Name:" labelColSpan={6} inputColSpan={18}>
            <InputComponent
              placeholder="Please enter name of manager"
              name="manager_name"
              rhfControllerProps={{ control }}
            />
          </FormItem>

          <FormItem
            label="Manager Email:"
            errorText={
              methods.formState.errors.manager_email
                ? methods.formState.errors.manager_email.message
                : ''
            }
            labelColSpan={6}
            inputColSpan={18}
          >
            <InputComponent
              placeholder="Please enter email ID of manager"
              name="manager_email"
              rhfControllerProps={{ control }}
            />
          </FormItem>

          <FormItem
            label="Manager Phone:"
            errorText={
              methods.formState.errors.manager_phone
                ? methods.formState.errors.manager_phone.message
                : ''
            }
            labelColSpan={6}
            inputColSpan={18}
          >
            <InputComponent
              placeholder="Please enter phone/cell of manager"
              name="manager_phone"
              rhfControllerProps={{ control }}
            />
          </FormItem>
        </div>
      </div>
      <div
        style={
          mode === 'add'
            ? { paddingTop: 50, paddingLeft: 550 }
            : { paddingTop: 50, display: 'flex', justifyContent: 'center' }
        }
      >
        <Button
          loading={submitLoader}
          htmlType="submit"
          className="buttonColorRed"
          style={{ width: 100, margin: 10, marginLeft: mode === 'edit' ? 67 : 0 }}
        >
          {mode === 'add' ? 'Add Farm' : 'Save'}
        </Button>
        <Button
          onClick={() => {
            if (mode === 'edit' && setVisibleDrawer) {
              setVisibleDrawer(false);
              methods.setValue('name', '');
              methods.setValue('address_line_1', '');
              methods.setValue('address_line_2', '');
              methods.setValue('manager_email', '');
              methods.setValue('city', '');
              methods.setValue('manager_name', '');
              methods.setValue('manager_phone', '');
              methods.setValue('state', '');
              methods.setValue('zipcode', '');
            } else {
              methods.reset();
              navigate('/farms');
            }
          }}
          type="default"
          style={{ width: 100, margin: 10 }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default FarmForm;
