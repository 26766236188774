import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Drawer, message, Popconfirm, Table } from 'antd';
import FarmForm from '../../forms/FarmForm';
import FarmDetailComponent from '../../components/FarmDetailComponent';
import Heading from '../../components/Heading';
import {
  AllFarmsQuery,
  AllFarmsQueryVariables,
  DeleteFarmByPkMutation,
  DeleteFarmByPkMutationVariables,
} from '../../graphql/graphql-types';
import { FarmDataType } from '../../utils/types';
import { logger } from '../../utils/helpers';
import WarningText from '../../components/WarningText';

const allFarmsQuery = loader('../../graphql/queries/allFarmsQuery.graphql');
const deleteFarmByPkMutation = loader('../../graphql/mutations/deleteFarmByPkMutation.graphql');

// This is the main functional component
const AllFarmsScreen: React.FC = () => {
  // This state holds the value to show edit drawer or not
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  // This is the state in which we store whether to show farm detail drawer or not
  const [showDetailDrawer, setShowDetailDrawer] = useState<boolean>(false);
  // This is the detail of farm which is going to be edited
  const [farmDetailsToEdit, setFarmDetailsToEdit] = useState<FarmDataType | null>(null);
  // It has the data for farm detail drawer
  const [farmDataToShow, setFarmDataToShow] = useState<FarmDataType | null>(null);

  /* Here we are fetching farm data from query and when data is loading the below loading is true and
  when some error occur during fetching then it comes on error variable */
  const { loading, data, error } = useQuery<AllFarmsQuery, AllFarmsQueryVariables>(allFarmsQuery);

  // in this variable we store farms data which is coming from query
  const farms = data?.farm;

  // This is the mutation for deleting the farm
  const [deleteFarmByPk] = useMutation<DeleteFarmByPkMutation, DeleteFarmByPkMutationVariables>(
    deleteFarmByPkMutation,
  );

  // handleDelete is invoked when user hits the yes button from the pop confirm of delete button
  const handleDelete = (record: FarmDataType) => {
    // this is the mutation in which we pass the id of the farm which is going to be delete
    deleteFarmByPk({
      variables: {
        id: record.id,
      },
      // update function is used here to see the updated list immediately after deleting the farm
      update: (cache, { data: deletedData }) => {
        // This is the id of farm whose delete button is clicked
        const idToDelete: string | undefined = deletedData?.update_farm_by_pk?.id;
        // This is the modify function in which fields is one input where we get all the data of our cache and according to our need we apply field name and get ref of field
        cache.modify({
          fields: {
            /* inside fields we get all our fields of cache here we need to update farm field,
             existingFarmRefs is ref of all farm present in our cache */
            farm(existingFarmRefs: Array<Reference>, { readField }) {
              if (idToDelete) {
                return existingFarmRefs.filter(
                  (farmRef) =>
                    // inside readField first parameter is key and the second parameter is object by this we get id of object
                    idToDelete !== readField('id', farmRef),
                );
              }
              return existingFarmRefs;
            },
          },
        });
      },
    })
      .then(() => {
        const name = record?.name;
        if (name) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success(`${name} is successfully deleted`);
        }
      })
      .catch((errorInDeleteFarm: ApolloError) => {
        logger(errorInDeleteFarm);
      });
  };

  // This error will display in place of table when error occur during fetching farm data
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  return (
    <>
      <Drawer
        title={<div style={{ fontSize: 22 }}>Farm Profile</div>}
        placement="right"
        closable
        onClose={() => {
          setShowDetailDrawer(false);
        }}
        visible={showDetailDrawer}
        width={450}
        className="drawerContainer"
      >
        {farmDataToShow ? <FarmDetailComponent farmDataToShow={farmDataToShow} /> : null}
      </Drawer>
      <Drawer
        title={<div style={{ fontSize: 22 }}>Edit farm profile</div>}
        placement="right"
        closable
        onClose={() => {
          setVisibleDrawer(false);
        }}
        visible={visibleDrawer}
        width={640}
        className="drawerContainer"
      >
        {visibleDrawer && farmDetailsToEdit ? (
          <FarmForm
            mode="edit"
            inputsFlexDirection="column"
            paddingBottomInName={0}
            setVisibleDrawer={setVisibleDrawer}
            farmDetailsToEdit={farmDetailsToEdit}
          />
        ) : null}
      </Drawer>
      <div style={{ padding: 40, paddingTop: 10 }}>
        <Heading headingName="Farms Summary" />
        {Array.isArray(farms) && farms.length > 0 ? (
          <Table<FarmDataType>
            dataSource={farms}
            bordered
            pagination={false}
            size="middle"
            loading={loading}
          >
            <Table.Column<FarmDataType>
              key="name"
              title="Farm Name"
              align="center"
              dataIndex="name"
              render={(text, record) => (
                <Link
                  style={{ textDecoration: 'underline' }}
                  to=""
                  role="link"
                  tabIndex={0}
                  onKeyPress={() => {}}
                  onClick={() => {
                    setShowDetailDrawer(true);
                    setFarmDataToShow(record);
                  }}
                >
                  {text}
                </Link>
              )}
            />
            <Table.Column<FarmDataType>
              title="Manager Name"
              dataIndex="manager_name"
              key="manager_name"
              align="center"
              render={(text, record): string => {
                if (record.manager_name) {
                  return record.manager_name;
                }
                return '-';
              }}
            />
            <Table.Column<FarmDataType>
              title="Manager Email"
              dataIndex="manager_email"
              key="manager_email"
              align="center"
              render={(text, record): string => {
                if (record.manager_email) {
                  return record.manager_email;
                }
                return '-';
              }}
            />
            <Table.Column<FarmDataType>
              title="Manager Phone"
              dataIndex="manager_phone"
              key="manager_phone"
              align="center"
              render={(text, record): string => {
                if (record.manager_phone) {
                  return record.manager_phone;
                }
                return '-';
              }}
            />
            <Table.Column<FarmDataType>
              key="option"
              title="Options"
              align="center"
              dataIndex="option"
              render={(text, record) => (
                <div>
                  <Button
                    icon={<EditOutlined />}
                    className="buttonColorRed"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setVisibleDrawer(true);
                      setFarmDetailsToEdit(record);
                    }}
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title="Delete Farm. Are you sure?"
                    onConfirm={(): void => {
                      handleDelete(record);
                    }}
                    icon={<WarningFilled style={{ color: 'red' }} />}
                    cancelText="No"
                    okText="Yes"
                  >
                    <Button icon={<DeleteOutlined />}>Delete</Button>
                  </Popconfirm>
                </div>
              )}
            />
          </Table>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <WarningText customStyles={{ width: 570, margin: 'auto' }}>
              <div>
                Please add a farm using <b>'Add new farm'</b> tab to begin using the app.
              </div>
            </WarningText>
          </div>
        )}
      </div>
    </>
  );
};

export default AllFarmsScreen;
