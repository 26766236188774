import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Row, message } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { loader } from 'graphql.macro';
import { ApolloError, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { byCalfColostrumReplacerOutput } from '@ecp32/calculations';
import FormItem from '../components/FormItem';
import SectionHeaderComponent from '../components/SectionHeaderComponent';
import SettingsFixedFieldsComponent from '../components/colostrum-replacer/SettingsFixedFieldsComponent';
import getByCalfFieldsBasedOnProperty from '../utils/colostrum-replacer/getByCalfFieldsBasedOnProperty';
import renderLabelBasedOnUnit from '../utils/colostrum-replacer/renderLabelBasedOnUnit';
import InputNumber from '../components/InputNumberComponent';
import {
  ByCalfFormType,
  CalfSelectedForCalculationType,
  FeedingType,
  InputKeysType,
} from '../calculator/colostrum-replacer-calculator/ByCalf';
import CalculatedOutputFieldsComponent from '../components/colostrum-replacer/CalculatedOutputFieldsComponent';
import RadioGroup from '../components/RadioGroup';
import {
  Enum_Measurement_System_Enum,
  CalfListByFarmIdQuery,
  Calf,
  InsertOrUpdateCalfMutation,
  InsertOrUpdateCalfMutationVariables,
  CheckIsCalfAlreadyExistQuery,
  CheckIsCalfAlreadyExistQueryVariables,
  DeleteCalfByPkMutation,
  DeleteCalfByPkMutationVariables,
  CalfByPkQueryVariables,
  CalfByPkQuery,
} from '../graphql/graphql-types';
import Select from '../components/Select';
import InputComponent from '../components/InputComponent';
import renderBrixReadingValidationWarning from '../utils/colostrum-replacer/renderBrixReadingValidationWarning';
import { logger } from '../utils/helpers';
import styles from './calculator/CalculatorForms.module.scss';
import renderLabelBasedOnTypeOfFeeding from '../utils/colostrum-replacer/renderLabelBasedOnTypeOfFeeding';

const deleteCalfByPkMutation = loader('../graphql/mutations/deleteCalfByPkMutation.graphql');
const calfListByFarmId = loader('../graphql/queries/calfListByFarmIdQuery.graphql');
const checkIsCalfAlreadyExist = loader('../graphql/queries/checkIsCalfAlreadyExistQuery.graphql');
const insertOrUpdateCalfMutation = loader(
  '../graphql/mutations/insertOrUpdateCalfMutation.graphql',
);
const calfByPk = loader('../graphql/queries/calfByPkQuery.graphql');

// prop type definition for component
type ColostrumReplacerByCalfCalculatorFormPropsType = {
  // prop use to set list of calf selected for calculation
  setCalfSelectedForCalculation: React.Dispatch<
    React.SetStateAction<CalfSelectedForCalculationType[]>
  >;
  // prop use to store list of calf selected for calculation
  calfSelectedForCalculation: CalfSelectedForCalculationType[];
  // prop use to store temporary id of the particular calf
  temporaryCalfId: string;
  // prop use to store calf's data and farm data
  farmAndCalfData: CalfListByFarmIdQuery;
};

// yup object schema to define validations
const schema = yup.object().shape({
  birthBodyWt: yup
    .number()
    .required('Please enter birth body weight')
    .transform((value: number) => (Number.isNaN(value) ? undefined : value))
    .test('is-in-range', 'Out of normal range', (value, context) => {
      const { unit } = context.parent as { unit: Enum_Measurement_System_Enum };
      if (value) {
        return unit === Enum_Measurement_System_Enum.UsCustomary
          ? value >= 50 && value <= 130
          : value >= 22 && value <= 60;
      }
      return false;
    })
    .nullable(),
  calfId: yup.string().when('typeOfFeeding', {
    is: (value: string) => value === 'secondFeeding',
    then: yup.string().required('Please select calf ID'),
    otherwise: yup.string().required('Please enter calf ID'),
  }),
  brixReading: yup.number().when('typeOfFeeding', {
    is: (value: string) => value === 'secondFeeding',
    then: yup
      .number()
      .min(1, 'Brix reading should be more than or equal to 1')
      .max(40, 'Out of normal range')
      .required('Please enter second feeding brix reading')
      .nullable(),
    otherwise: yup
      .number()
      .min(1, 'Brix reading should be more than or equal to 1')
      .max(40, 'Out of normal range')
      .required('Please enter first feeding brix reading')
      .nullable(),
  }),
  brixUsedInPrevCalculation: yup.number().when('typeOfFeeding', {
    is: (value: string) => value === 'secondFeeding',
    then: yup
      .number()
      .min(
        1,
        'First feeding brix reading used in previous calculation should be more than or equal to 1',
      )
      .max(40, 'Out of normal range')
      .required('Please enter first feeding brix reading')
      .nullable(),
  }),
});

// calling getByCalfFieldsBasedOnProperty to get input fields, fixed fields, section wise fields for by-calf calculator
const {
  byCalfInputFields,
  byCalfFixedFields,
  byCalfOutputFields,
} = getByCalfFieldsBasedOnProperty();

// react functional component
const ColostrumReplacerByCalfCalculatorForm = ({
  setCalfSelectedForCalculation,
  calfSelectedForCalculation,
  temporaryCalfId,
  farmAndCalfData,
}: ColostrumReplacerByCalfCalculatorFormPropsType): JSX.Element => {
  // extracting farm id param from useParam
  const { farmId } = useParams();

  // extracting client from useApollo client
  const client = useApolloClient();

  // state to show loading indicator when user clicks on remove calf button
  // const [isRemoveCalfBtnLoading, setIsRemoveCalfBtnLoading] = useState<boolean>(false);

  // state to show loading indicator when user clicks on save calf button
  const [isSaveCalfBtnLoading, setIsSaveCalfBtnLoading] = useState<boolean>(false);

  const [isResetBtnLoading, setIsResetBtnLoading] = useState<boolean>(false);

  // extracting farm data and calf data from query
  const { farm_by_pk: farmData, calf: calfData, getIgGInColostrumReplacer } = farmAndCalfData;

  // const to store calf data for which first feeding is done and second feeding is pending
  const firstFeedingDoneCalf =
    calfData && calfData.filter((item) => item.colostrum_second_fdg_brix_reading === 0);

  // const used to store the default settings field values for the form
  const farmSettingsData = {
    unit:
      farmData && farmData.col_rep_measurement_system_id
        ? farmData.col_rep_measurement_system_id
        : Enum_Measurement_System_Enum.UsCustomary,
    desiredMaxAmtForFirstFeeding:
      farmData && farmData.colostrum_max_first_fdg
        ? (farmData.colostrum_max_first_fdg as number)
        : 0,
    desiredMaxAmtForSecondFeeding:
      farmData && farmData.colostrum_max_second_fdg
        ? (farmData.colostrum_max_second_fdg as number)
        : 0,
  };

  // const to store igGInColostrumReplacer value
  const igGInColostrumReplacerVal = getIgGInColostrumReplacer
    ? getIgGInColostrumReplacer.igGInColostrumReplacer
    : 21;

  // const to store default values for form
  const formDefaultValues = {
    calfId: undefined,
    igGInColostrumReplacer: igGInColostrumReplacerVal,
    typeOfFeeding: 'firstFeeding' as FeedingType,
    birthBodyWt: undefined,
    brixReading: 20,
    brixUsedInPrevCalculation: 0,
    ...farmSettingsData,
  };

  // useForm declaration
  const {
    control,
    watch,
    reset,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<ByCalfFormType>({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  // const to store current value of all form fields
  const { calfId, typeOfFeeding, ...allFieldsValue } = watch();

  // This mutation is used for deleting the calf from the graphql
  // const [deleteCalfByPk] = useMutation<DeleteCalfByPkMutation, DeleteCalfByPkMutationVariables>(
  //   deleteCalfByPkMutation,
  // );

  // This mutation is used for insert or update the calf details from the graphql
  const [insertOrUpdateCalf] = useMutation<
    InsertOrUpdateCalfMutation,
    InsertOrUpdateCalfMutationVariables
  >(insertOrUpdateCalfMutation);

  // function to update selected calf value and reset its calf id
  const updateAndResetCalfId = () => {
    // const to reset calf id from calfSelectedForCalculation
    const updatedValueOfCalfSelectedForCalculation = calfSelectedForCalculation.map((item) => {
      if (item.temporaryId === temporaryCalfId) {
        return { temporaryId: item.temporaryId, calfId: '' };
      }
      return item;
    });

    setCalfSelectedForCalculation(updatedValueOfCalfSelectedForCalculation);
  };

  // function that will return calculated fields for the by-calf colostrum calculator every time when input fields value changes
  const byCalfCalculatorFunc = useCallback(() => {
    // logic to call by-calf calculator function when there are no form errors
    if (Object.keys(errors).length === 0) {
      try {
        return byCalfColostrumReplacerOutput({
          ...allFieldsValue,
        });
      } catch (error) {
        console.log('error', error);
      }
    }
    return undefined;
  }, [errors, allFieldsValue]);

  // function to set onchange of calf id value
  const handleCalfIdOnChangeFunc = (
    value: string,
    farmAndCalfDataValue?: Pick<
      Calf,
      | 'id'
      | 'colostrum_first_fdg_brix_reading'
      | 'colostrum_second_fdg_brix_reading'
      | 'birth_body_wt'
      | 'col_rep_measurement_system_id'
      | 'colostrum_max_first_fdg'
      | 'colostrum_max_second_fdg'
    >,
  ) => {
    /**
     *  settings below fields as settings field value will be on calf level so if there is any settings fields value respective to individual calf
     *  then that value will be considered on priority else farm settings value will be considered
     */
    reset({
      calfId: value,
      desiredMaxAmtForFirstFeeding: farmAndCalfDataValue
        ? (farmAndCalfDataValue.colostrum_max_first_fdg as number)
        : farmSettingsData.desiredMaxAmtForFirstFeeding,
      desiredMaxAmtForSecondFeeding: farmAndCalfDataValue
        ? (farmAndCalfDataValue.colostrum_max_second_fdg as number)
        : farmSettingsData.desiredMaxAmtForSecondFeeding,
      unit: farmAndCalfDataValue
        ? farmAndCalfDataValue.col_rep_measurement_system_id
        : farmSettingsData.unit,
      typeOfFeeding:
        farmAndCalfDataValue && farmAndCalfDataValue.colostrum_first_fdg_brix_reading
          ? 'secondFeeding'
          : 'firstFeeding',
      igGInColostrumReplacer: igGInColostrumReplacerVal,
      brixUsedInPrevCalculation:
        farmAndCalfDataValue && farmAndCalfDataValue.colostrum_first_fdg_brix_reading
          ? (farmAndCalfDataValue.colostrum_first_fdg_brix_reading as number)
          : 0,
      birthBodyWt:
        farmAndCalfDataValue && farmAndCalfDataValue.birth_body_wt
          ? (farmAndCalfDataValue.birth_body_wt as number)
          : undefined,
      brixReading:
        farmAndCalfDataValue && farmAndCalfDataValue.colostrum_first_fdg_brix_reading
          ? (farmAndCalfDataValue.colostrum_first_fdg_brix_reading as number)
          : 20,
    });

    // const to store updated calfSelectedForCalculation const by setting calf id of respective calf
    const updatedValueOfCalfSelectedForCalculation = calfSelectedForCalculation.map((item) => {
      if (item.temporaryId === temporaryCalfId) {
        return { temporaryId: item.temporaryId, calfId: value };
      }
      return item;
    });
    setCalfSelectedForCalculation(updatedValueOfCalfSelectedForCalculation);
  };

  // const to store calculated output by calling pooled calculator function
  const byCalfCalculationOutput = byCalfCalculatorFunc();

  // store calf id's from existing calf data
  const calfIdData = firstFeedingDoneCalf.map((item) => item.id);

  // const to store calf id if it is present
  const alreadyExistCalfData = calfData.find((item) => item.id === calfId);

  return (
    <Row gutter={50} style={{ paddingLeft: 20 }}>
      <Col span={11}>
        <form>
          <SectionHeaderComponent title="Inputs" />

          {/* render fixed fields */}
          <SettingsFixedFieldsComponent
            fieldData={byCalfFixedFields.filter((item) => item.key !== 'igGInColostrumReplacer')}
            settingsFieldValue={{
              unit: allFieldsValue.unit,
              desiredMaxAmtForFirstFeeding: allFieldsValue.desiredMaxAmtForFirstFeeding || 0,
              desiredMaxAmtForSecondFeeding: allFieldsValue.desiredMaxAmtForSecondFeeding || 0,
            }}
          />

          {/* render fields of form */}
          {byCalfInputFields.map((ele) => {
            if (ele.isSelectOption) {
              return (
                <FormItem
                  key={ele.key}
                  label={
                    ele.key === 'calfId'
                      ? renderLabelBasedOnTypeOfFeeding(ele.description.label, typeOfFeeding)
                      : renderLabelBasedOnUnit(
                          ele.description.label,
                          allFieldsValue.unit,
                          ele.description.unitKey,
                        )
                  }
                  isRequired
                  displayMode="column"
                  customStyle={{ marginBottom: 10 }}
                  errorText={errors ? errors[ele.key as InputKeysType]?.message : undefined}
                >
                  {typeOfFeeding === 'firstFeeding' ? (
                    <InputComponent
                      name="calfId"
                      placeholder="Enter Calf ID"
                      rhfControllerProps={{ control }}
                      onChange={(rhfOnChange, calfIdVal) => {
                        handleCalfIdOnChangeFunc(calfIdVal);
                        rhfOnChange(calfIdVal);
                      }}
                      onBlur={async (e) => {
                        // if user enters any value then call query and set error if calf id already exists
                        if (e.target.value) {
                          // fetching check is calf already exists query
                          const queryData = await client.query<
                            CheckIsCalfAlreadyExistQuery,
                            CheckIsCalfAlreadyExistQueryVariables
                          >({
                            query: checkIsCalfAlreadyExist,
                            variables: { id: e.target.value, farmId },
                            fetchPolicy: 'network-only',
                          });

                          // if entered calf id is already present in DB then set error
                          if (
                            queryData &&
                            queryData.data &&
                            queryData.data.calf &&
                            queryData.data.calf[0] &&
                            queryData.data.calf[0].id === e.target.value
                          ) {
                            setError('calfId', {
                              message:
                                'Calf ID already exists, either change the type of feeding or enter the correct Calf ID',
                            });
                          }
                        }
                      }}
                    />
                  ) : (
                    // Calling select component with dropdown render prop to enable user to add new calf id
                    <Select
                      rhfControllerProps={{ control }}
                      name="calfId"
                      placeholder="Select Calf ID"
                      onChange={(value: string) => {
                        // const used to store the data of selected calf
                        const selectedCalfData =
                          calfData && calfData.find((calf) => calf.id === value);

                        handleCalfIdOnChangeFunc(value, selectedCalfData);
                      }}
                      customStyles={{ width: 400 }}
                      options={calfIdData.map((item) => ({
                        label: item,
                        value: item,
                        // disabling calf options which are already selected in calculation
                        disabled: calfSelectedForCalculation
                          .map((calf) => calf.calfId)
                          .includes(item),
                      }))}
                    />
                  )}
                </FormItem>
              );
            }

            // render radio type input fields
            if (ele.isRadioButton) {
              return (
                <FormItem
                  key={ele.key}
                  label={ele.description.label as string}
                  displayMode="column"
                  isRequired
                  customStyle={{ marginBottom: 10 }}
                  errorText={errors ? errors[ele.key as InputKeysType]?.message : undefined}
                >
                  <RadioGroup
                    name={ele.key}
                    rhfControllerProps={{ control }}
                    radioProps={{
                      style: { marginTop: 3 },
                    }}
                    options={[
                      {
                        label: 'First Feeding',
                        value: 'firstFeeding',
                      },
                      { label: 'Second Feeding', value: 'secondFeeding' },
                    ]}
                    onChange={(e) => {
                      if (e.target.value === 'firstFeeding') {
                        // reset values to form default values
                        reset(formDefaultValues);

                        // function call to update and reset calf id from calfSelectedForCalculation
                        updateAndResetCalfId();
                      } else {
                        // reset values to default values when user selects second feeding option
                        reset({
                          ...formDefaultValues,
                          typeOfFeeding: 'secondFeeding',
                          brixUsedInPrevCalculation: 20,
                        });

                        // function call to update and reset calf id from calfSelectedForCalculation
                        updateAndResetCalfId();
                      }
                    }}
                  />
                </FormItem>
              );
            }

            // when type of feeding is first feeding hide brix used in previous calculation field
            if (ele.key === 'brixUsedInPrevCalculation' && typeOfFeeding === 'firstFeeding') {
              return null;
            }

            // const to store condition which is used to disable and style to input number component
            const inputNumberCondition =
              (typeOfFeeding === 'secondFeeding' && ele.key === 'birthBodyWt') ||
              ele.key === 'brixUsedInPrevCalculation';

            // render input type form fields
            return (
              <FormItem
                key={ele.key}
                label={
                  ele.key === 'brixReading' || ele.key === 'brixUsedInPrevCalculation'
                    ? renderLabelBasedOnTypeOfFeeding(ele.description.label, typeOfFeeding)
                    : renderLabelBasedOnUnit(
                        ele.description.label,
                        allFieldsValue.unit,
                        ele.description.unitKey,
                      )
                }
                isRequired={typeOfFeeding === 'firstFeeding' || ele.key === 'brixReading'}
                displayMode="column"
                errorText={errors ? errors[ele.key as InputKeysType]?.message : undefined}
              >
                <InputNumber
                  rhfControllerProps={{ control }}
                  name={ele.key}
                  placeholder="Please enter value"
                  inputNumberProps={{
                    // TODO : Disabled is not working while submitting data hence below implemented is a temporary solution
                    readOnly: inputNumberCondition && !!calfId,
                    style:
                      inputNumberCondition && calfId
                        ? {
                            backgroundColor: '#EFEFEF',
                            color: '#00000040',
                          }
                        : {},
                  }}
                />
              </FormItem>
            );
          })}

          {/* function call to display warning message */}
          {renderBrixReadingValidationWarning(
            allFieldsValue.brixReading,
            allFieldsValue.brixUsedInPrevCalculation,
          )}

          <Button
            loading={isSaveCalfBtnLoading}
            className={`${styles.saveBtn} buttonColorRed`}
            style={{ marginTop: 10, marginRight: 30 }}
            disabled={!!errors.calfId}
            onClick={handleSubmit(() => {
              setIsSaveCalfBtnLoading(true);

              // const to store mutation variable object which is then passed to mutation variable
              const mutationVariableObject = {
                id: calfId,
                farm_id: farmId,
                col_rep_measurement_system_id: allFieldsValue.unit,
                colostrum_max_first_fdg: allFieldsValue.desiredMaxAmtForFirstFeeding,
                colostrum_max_second_fdg: allFieldsValue.desiredMaxAmtForSecondFeeding,
                colostrum_second_fdg_brix_reading:
                  typeOfFeeding === 'secondFeeding' ? allFieldsValue.brixReading : 0,
                birth_body_wt: allFieldsValue.birthBodyWt,
                colostrum_first_fdg_brix_reading:
                  typeOfFeeding === 'secondFeeding'
                    ? allFieldsValue.brixUsedInPrevCalculation
                    : allFieldsValue.brixReading,
              };

              // const to store mutation variable which is then passed to upsert mutation, if calf already exist then we are passing system id also
              const mutationVariable = alreadyExistCalfData
                ? { ...mutationVariableObject, system_id: alreadyExistCalfData.system_id }
                : mutationVariableObject;

              // calling upsert mutation which is used to add new calf details if calf id is not already present and to update calf details if calf id is already present
              insertOrUpdateCalf({
                variables: {
                  object: mutationVariable,
                },
                refetchQueries: [{ query: calfListByFarmId, variables: { farmId } }],
              })
                .then(() => {
                  setIsSaveCalfBtnLoading(false);

                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.success(
                    `${
                      alreadyExistCalfData
                        ? 'Calf has been updated successfully.'
                        : 'Calf has been added successfully.'
                    }`,
                  );
                })
                .catch((err: ApolloError) => {
                  setIsSaveCalfBtnLoading(false);
                  logger(err);
                });
            })}
          >
            Save
          </Button>

          <Button
            loading={isResetBtnLoading}
            style={{ marginTop: 10, marginRight: 30 }}
            onClick={async () => {
              setIsResetBtnLoading(true);
              if (alreadyExistCalfData) {
                /**
                 * fetching calf calculation input field data to reset fields to last entered values
                 */
                const calfCalculationData = await client.query<
                  CalfByPkQuery,
                  CalfByPkQueryVariables
                >({
                  query: calfByPk,
                  variables: { system_id: alreadyExistCalfData.system_id },
                  fetchPolicy: 'network-only',
                });

                /**
                 * const to store input fields value for particular calf
                 */
                const calfInputFields =
                  calfCalculationData &&
                  calfCalculationData.data &&
                  calfCalculationData.data.calf_by_pk;

                if (calfInputFields) {
                  setValue('birthBodyWt', calfInputFields.birth_body_wt);
                  setValue(
                    'brixReading',
                    typeOfFeeding === 'firstFeeding'
                      ? calfInputFields.colostrum_first_fdg_brix_reading
                      : calfInputFields.colostrum_second_fdg_brix_reading,
                  );

                  /**
                   * when user directly selects second feeding and selects any calf,
                   * without saving if user changes any value and clicks directly on reset then render previous selected value
                   */
                  if (
                    typeOfFeeding === 'secondFeeding' &&
                    calfInputFields.colostrum_second_fdg_brix_reading === 0
                  ) {
                    setValue('brixReading', calfInputFields.colostrum_first_fdg_brix_reading);
                  }
                }

                clearErrors();
                setIsResetBtnLoading(false);
              } else {
                // reset values to form default values
                reset(formDefaultValues);

                // function call to update and reset calf id from calfSelectedForCalculation
                updateAndResetCalfId();
                setIsResetBtnLoading(false);
              }
            }}
          >
            Reset
          </Button>
          {/*
          <Button
            loading={isRemoveCalfBtnLoading}
            onClick={() => {
              if (alreadyExistCalfData) {
                setIsRemoveCalfBtnLoading(true);
                deleteCalfByPk({
                  variables: {
                    system_id: alreadyExistCalfData.system_id,
                  },
                  refetchQueries: [{ query: calfListByFarmId, variables: { farmId } }],
                })
                  .then(() => {
                    setIsRemoveCalfBtnLoading(false);

                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.success('Calf is successfully deleted.');
                    // removing calf calculation of calf whose remove calf button is clicked.
                    setCalfSelectedForCalculation(
                      calfSelectedForCalculation.filter((item) =>
                        item.calfId ? item.calfId !== calfId : item.temporaryId !== temporaryCalfId,
                      ),
                    );
                    reset();
                  })
                  .catch((err: ApolloError) => {
                    setIsRemoveCalfBtnLoading(false);
                    logger(err);
                  });
              } else {
                // removing calf calculation of calf whose remove calf button is clicked.
                setCalfSelectedForCalculation(
                  calfSelectedForCalculation.filter((item) =>
                    item.calfId ? item.calfId !== calfId : item.temporaryId !== temporaryCalfId,
                  ),
                );
                reset();
              }
            }}
          >
            Remove Calf
          </Button> */}
        </form>
      </Col>
      <Col span={12}>
        <SectionHeaderComponent title="Outputs" />
        {/* render per calf fields */}
        <CalculatedOutputFieldsComponent
          fieldData={
            typeOfFeeding === 'firstFeeding'
              ? byCalfOutputFields.filter(
                  (item) =>
                    item.key === 'crPowderNeededInFirstFeeding' ||
                    item.key === 'minAmtNeededInFirstFeeding',
                )
              : byCalfOutputFields
          }
          outputValues={byCalfCalculationOutput}
          selectedUnitValue={allFieldsValue.unit}
        />
      </Col>
    </Row>
  );
};

export default ColostrumReplacerByCalfCalculatorForm;
