import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';
import { Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { FarmOptionsQuery, FarmOptionsQueryVariables } from '../../graphql/graphql-types';
import FormItem from '../../components/FormItem';
import Select from '../../components/Select';
import PooledCalculatorHistoryScreen from './PooledCalculatorHistoryScreen';
import ByCalfCalculatorHistoryScreen from './ByCalfCalculatorHistoryScreen';

const farmOptionsQuery = loader('../../graphql/queries/farmOptionsQuery.graphql');

const ColostrumReplacerHistory = () => {
  // useForm declaration
  const { control, watch } = useForm<{
    // type definition for selected farm id
    selectedFarmId: string;
  }>({
    defaultValues: { selectedFarmId: undefined },
    mode: 'onChange',
  });

  // fetching farm data from query
  const { data, loading, error: queryError } = useQuery<
    FarmOptionsQuery,
    FarmOptionsQueryVariables
  >(farmOptionsQuery, {
    fetchPolicy: 'no-cache',
  });

  // Showing loading indicator while fetching farm options
  if (loading) {
    return (
      <div className="loadingIndicator">
        <Spin size="large" />
      </div>
    );
  }

  // If any error occurs while fetching farm options data
  if (queryError) {
    return <div className="errorMessage">{queryError.message}</div>;
  }

  // const to store selected farm id
  const farmId = watch('selectedFarmId');

  return (
    <>
      <div style={{ marginLeft: 20 }}>
        <FormItem label="Select a farm:" labelColSpan={4} inputColSpan={4}>
          <Select
            rhfControllerProps={{ control }}
            name="selectedFarmId"
            placeholder="Select farm"
            customStyles={{ width: 300 }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'label',
            }}
            options={
              data && data.farm
                ? data.farm.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                : []
            }
          />
        </FormItem>
      </div>

      {farmId ? (
        <div className="tabContainer">
          <Tabs defaultIndex={0} forceRenderTabPanel style={{ marginTop: 20 }}>
            <TabList style={{ padding: '30px 35px 0px 35px', fontSize: '16px' }}>
              <Tab style={{ paddingLeft: 30, paddingRight: 30 }}>Pooled</Tab>
              <Tab style={{ paddingLeft: 30, paddingRight: 30 }}>By Calf</Tab>
            </TabList>
            <TabPanel style={{ paddingTop: 10, marginLeft: 35 }}>
              <PooledCalculatorHistoryScreen farmId={farmId} />
            </TabPanel>
            <TabPanel style={{ paddingTop: 10, marginLeft: 35 }}>
              <ByCalfCalculatorHistoryScreen farmId={farmId} />
            </TabPanel>
          </Tabs>
        </div>
      ) : null}
    </>
  );
};

export default ColostrumReplacerHistory;
