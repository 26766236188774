import {
  CalculatorDataCommonType,
  LabSolidsOrRefractometerType,
  ReferenceNameType,
  UnitType,
} from '../utils/types';

/* Type for PMRB no water input keys */
export type PmrbNoWaterInputsKeyType =
  | 'noOfCalves'
  | 'targetPasteurizedMilkBalancerLbsPerCalfDaily'
  | 'labSolidsOrRefractometerSelection'
  | 'feedingsPerDay'
  | 'targetVolPerFdgQtPerCalf'
  | 'pasteurizedMilkBalancerCP'
  | 'pasteurizedMilkBalancerFat'
  | 'pasteurizedMilkCP'
  | 'pasteurizedMilkFat'
  | 'labSolidsOrRefractometer'
  | 'unit';

/* Type for PMRB no water output keys */
export type PmrbNoWatersOutputsKeyType =
  | 'totalPowderFedPerCalfDaily'
  | 'actualSolutionVolFedPerCalfDaily'
  | 'totalPasteurizedMilkNeededPerFdg'
  | 'totalFinalVolNeededPerFdg'
  | 'totalPowderNeededPerFdg'
  | 'finalSolids'
  | 'pasteurizedMilkBalancerCPAndFatRatio'
  | 'pasteurizedMilkCPAndFatRatio'
  | 'totalSolidsFedFromPasteurizedMilkPerCalfDaily'
  | 'pasteurizedMilkBalancerDryMatterPerCalfPerDay'
  | 'totalSolidsFedPerDaily'
  | 'percentCPDryBasis'
  | 'percentFatDryBasis'
  | 'percentCPToPercentFatRatio'
  | 'dmBasisCP'
  | 'dmBasisFat'
  | 'dmBasisCpToFatRatio'
  | 'wtPmbPerGallon'
  | 'brixFactor'
  | 'wtOfPMPerGallon'
  | 'wtOfSolidsPerGallonOfPasteurizedMilk'
  | 'wtOfSolidsNeededPerGallonOfPasteurizedMilk'
  | 'conversionWeight'
  | 'solidsOfFinalSolution'
  | 'conversionFactor'
  | 'totalSolutionVolPerFdg'
  | 'wtNeededPerFdg'
  | 'gallonsOfMilkNeededPerFdg'
  | 'wtOfTotal'
  | 'actualSolutionVolPerCalfPerFdg'
  | 'pasteurizedMilkFedPerCalfPerFdg'
  | 'totalPowderFedPerCalfPerFdg'
  | 'pasteurizedMilkFedPerCalfDaily';

/* Type of PMRB no water data fields type */
export interface PmrbNOWaterDataFieldsType extends CalculatorDataCommonType {
  key: PmrbNoWaterInputsKeyType | PmrbNoWatersOutputsKeyType;
}

/* Type for PMRB no water all input fields */
export interface PmrbNOWaterInputFieldsType extends CalculatorDataCommonType {
  key: Exclude<PmrbNoWaterInputsKeyType, 'noOfCalves' | 'feedingsPerDay'>;
}
export interface PmrbNoWaterFormFieldsWithoutReferenceNameType {
  /* target pasteurized milk balancer lbs per calf daily */
  targetPasteurizedMilkBalancerLbsPerCalfDaily: number | undefined | null;
  /* labs solids or refractometer selection value */
  labSolidsOrRefractometerSelection: number | undefined | null;
  /* target volume per feeding qt per calf */
  targetVolPerFdgQtPerCalf: number | undefined | null;
  /* Pasteurized milk balancer CP */
  pasteurizedMilkBalancerCP: number | undefined | null;
  /* Pasteurized milk balancer Fat */
  pasteurizedMilkBalancerFat: number | undefined | null;
  /* Pasteurized milk CP */
  pasteurizedMilkCP: number | undefined | null;
  /* Pasteurized milk Fat */
  pasteurizedMilkFat: number | undefined | null;
  /* lab solid or refractometer selected option */
  labSolidsOrRefractometer: LabSolidsOrRefractometerType;
  /* Selected unit */
  unit: UnitType;
}
/* Type of PMRB no water form fields type */
export interface PmrbNoWaterFormFieldsType
  extends ReferenceNameType,
    PmrbNoWaterFormFieldsWithoutReferenceNameType {}

/* Function to calculate brix factor based on selected key */
const calculateBrixFactor = (value: number, key: LabSolidsOrRefractometerType): number => {
  if (key === 'labSolids') {
    return value;
  }
  return value ** 2 * -0.05318 + value * 2.72 - 9.72043;
};

/* Function calculates total<unit>PasteurizedMilkNeededPerFdg and total<unit>FinalVolumeNeededPerFdg and its labels
    based on unit selection by user which can be 'gallons' or 'pounds' */
const calculateTotalNeededBasedOnUnits = (
  unit: 'gallons' | 'pounds',
  noOfCalvesVal: number,
  targetVolPerFdgQtPerCalfVal: number,
  conversionFactorVal: number,
  totalPowderNeededPerFdgVal: number,
  conversionWeightVal: number,
): {
  totalPasteurizedMilkNeededPerFdg: number;
  totalFinalVolumeNeededPerFdg: number;
  totalSolutionVolPerFdg: number;
} => {
  /* Variable to store total volume */
  const totalVolume = (noOfCalvesVal * targetVolPerFdgQtPerCalfVal) / 4;
  /* If selected unit is 'gallons' then following calculations will be returned with corresponding label and unit of the field */
  if (unit === 'gallons') {
    return {
      totalPasteurizedMilkNeededPerFdg: totalVolume * conversionFactorVal,
      totalFinalVolumeNeededPerFdg: totalVolume,
      totalSolutionVolPerFdg: totalVolume,
    };
  }
  /* Variable to store final volume of past. milk based on 'totalVolume' calculated previously as selected unit is 'pounds' */
  const finalVolume = totalVolume * conversionWeightVal - totalPowderNeededPerFdgVal;
  /* If selected unit is 'pounds' then following calculations will be returned with corresponding label and unit of the field */
  return {
    totalPasteurizedMilkNeededPerFdg: finalVolume,
    totalFinalVolumeNeededPerFdg: finalVolume + totalPowderNeededPerFdgVal,
    totalSolutionVolPerFdg: totalVolume * conversionWeightVal,
  };
};

/* PMRB no water static data array */
export const pmrbNoWaterValues: PmrbNOWaterDataFieldsType[] = [
  {
    key: 'noOfCalves',
    type: 'input',
    description: {
      label: 'Number of Calves',
    },
  },
  {
    key: 'feedingsPerDay',
    type: 'input',
    description: {
      label: 'Feedings',
      unit: 'per day',
    },
  },
  {
    key: 'targetVolPerFdgQtPerCalf',
    type: 'input',
    description: {
      label: 'Target volume',
      unit: 'per feeding Qt per calf',
    },
  },
  // forthis field we were doing all our calculation based on per calf per feeding previously...
  // but in new updates we are taking input per calf(ie on Daily basis)
  {
    key: 'targetPasteurizedMilkBalancerLbsPerCalfDaily',
    type: 'input',
    description: {
      label: 'Target Pasteurized Milk Balancer',
      unit: 'Lbs per calf DAILY',
    },
  },
  {
    key: 'pasteurizedMilkBalancerCP',
    type: 'input',
    description: {
      label: 'Pasteurized Milk Balancer %CP',
    },
  },
  {
    key: 'pasteurizedMilkBalancerFat',
    type: 'input',
    description: {
      label: 'Pasteurized Milk Balancer %Fat',
    },
  },
  {
    key: 'labSolidsOrRefractometer',
    type: 'input',
    description: {
      label: 'Select',
    },
    isSelectOption: [
      { label: 'Lab Solids (%)', value: 'labSolids' },
      {
        label: 'Refractometer Brix Reading',
        value: 'refractometerBrix',
      },
    ],
  },
  {
    key: 'labSolidsOrRefractometerSelection',
    type: 'input',
    description: {
      label: (type: string) =>
        type === 'refractometerBrix' ? 'Refractometer Brix Reading' : 'Lab Solids (%)',
    },
  },
  {
    key: 'pasteurizedMilkCP',
    type: 'input',
    description: {
      label: 'Pasteurized Milk % CP (as is)',
    },
  },
  {
    key: 'pasteurizedMilkFat',
    type: 'input',
    description: {
      label: 'Pasteurized Milk % Fat (as is)',
    },
  },
  {
    key: 'unit',
    type: 'input',
    description: {
      label: 'Select Final Ration Output Units',
    },
    isSelectOption: [
      {
        label: 'Gallons',
        value: 'gallons',
      },
      {
        label: 'Pounds',
        value: 'pounds',
      },
    ],
  },
  {
    key: 'totalPasteurizedMilkNeededPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Pasteurized Milk Needed',
      unit: (unit: UnitType): string => `per feeding (${unit})`,
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'totalPowderNeededPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Powder Needed',
      unit: 'per feeding (lbs)',
    },
    calculatedType: 'primary',
    decimalPlaces: 2,
  },
  {
    key: 'totalSolutionVolPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Solution Volume',
      unit: (unit: UnitType): string => `per feeding (${unit})`,
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'solidsOfFinalSolution',
    type: 'calculated',
    description: {
      label: '% Solids of Final Solution',
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'actualSolutionVolPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: 'Actual Solution Volume',
      unit: 'per calf per feeding (qts)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 1,
  },
  {
    key: 'pasteurizedMilkFedPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: 'Pasteurized Milk Fed',
      unit: 'per calf per feeding (qts)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 1,
  },
  {
    key: 'totalPowderFedPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Powder Fed',
      unit: 'per calf per feeding (lbs)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'percentCPDryBasis',
    type: 'calculated',
    description: {
      label: '% CP (dry basis)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 1,
  },
  {
    key: 'percentFatDryBasis',
    type: 'calculated',
    description: {
      label: '% Fat (dry basis)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 1,
  },
  {
    key: 'percentCPToPercentFatRatio',
    type: 'calculated',
    description: {
      label: '% CP & % Fat Ratio',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'actualSolutionVolFedPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Actual Solution Volume Fed',
      unit: 'per calf daily (qt)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 1,
  },
  {
    key: 'pasteurizedMilkFedPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Pasteurized Milk Fed',
      unit: 'per calf daily (qts)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 1,
  },
  {
    key: 'totalPowderFedPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Total Powder Fed',
      unit: 'per calf daily (lb)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalSolidsFedFromPasteurizedMilkPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Total Solids Fed from Pasteurized Milk',
      unit: 'per calf daily (lbs)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalSolidsFedPerDaily',
    type: 'calculated',
    description: {
      label: 'Total Solids Fed',
      unit: 'per calf daily (lbs)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalFinalVolNeededPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Final Solution Needed',
      unit: (unit: UnitType): string => `per feeding (${unit})`,
    },
    calculatedType: 'hidden',
    decimalPlaces: 1,
  },
  {
    key: 'finalSolids',
    type: 'calculated',
    description: {
      label: 'Final Solids %',
    },
    calculatedType: 'hidden',
    decimalPlaces: 1,
  },
  {
    key: 'pasteurizedMilkBalancerCPAndFatRatio',
    type: 'calculated',
    description: {
      label: 'Pasteurized Milk Balancer %CP & %Fat Ratio',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'pasteurizedMilkCPAndFatRatio',
    type: 'calculated',
    description: {
      label: 'Pasteurized Milk as is %CP & %Fat Ratio',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'pasteurizedMilkBalancerDryMatterPerCalfPerDay',
    type: 'calculated',
    description: {
      label: 'Pasteurized Milk Balancer® Dry Matter',
      unit: 'per Calf per day',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'dmBasisCP',
    type: 'calculated',
    description: {
      label: 'Pasteurized milk DM Basis % CP',
    },
    calculatedType: 'hidden',
    decimalPlaces: 1,
  },
  {
    key: 'dmBasisFat',
    type: 'calculated',
    description: {
      label: 'Pasteurized milk DM Basis % Fat',
    },
    calculatedType: 'hidden',
    decimalPlaces: 1,
  },
  {
    key: 'dmBasisCpToFatRatio',
    type: 'calculated',
    description: {
      label: 'Pasteurized milk DM Basis %CP & % Fat Ratio',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'wtPmbPerGallon',
    type: 'calculated',
    description: {
      label: 'Weight PMB - NOT added to a gallon',
      unit: 'in gallons',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
  {
    key: 'brixFactor',
    type: 'calculated',
    description: {
      label: 'BRIX factor',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
  {
    key: 'wtOfPMPerGallon',
    type: 'calculated',
    description: {
      label: 'Weight of PM',
      unit: 'per gallon (Lb/gallon)',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
  {
    key: 'wtOfSolidsPerGallonOfPasteurizedMilk',
    type: 'calculated',
    description: {
      label: 'Weight of solids of Pasteurized Milk',
      unit: 'per gallon',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
  {
    key: 'wtOfSolidsNeededPerGallonOfPasteurizedMilk',
    type: 'calculated',
    description: {
      label: 'Weight of solids needed of Pasteurized Milk ',
      unit: 'per gallon',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
  {
    key: 'conversionWeight',
    type: 'calculated',
    description: {
      label: 'Conversion Weight',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },

  {
    key: 'conversionFactor',
    type: 'calculated',
    description: {
      label: 'Conversion Factor',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
  {
    key: 'wtOfTotal',
    type: 'calculated',
    description: {
      label: 'Wt of total',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },

  {
    key: 'wtNeededPerFdg',
    type: 'calculated',
    description: {
      label: 'Weight needed',
      unit: 'per feeding',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'gallonsOfMilkNeededPerFdg',
    type: 'calculated',
    description: {
      label: 'Gallons of milk needed',
      unit: 'per feeding',
    },
    calculatedType: 'hidden',
    decimalPlaces: 1,
  },
];

/* Function to calculate fields based on input fields for PMRB no water fields */
export const calculatedPMRBNoWaterFields = (
  input: Record<PmrbNoWaterInputsKeyType, number | undefined>,
): Record<PmrbNoWatersOutputsKeyType, number | undefined> => {
  /* Object to store results after calculating fields based on provided logic */
  const results: Record<PmrbNoWatersOutputsKeyType, number | undefined> = {
    totalPowderFedPerCalfDaily: undefined,
    actualSolutionVolFedPerCalfDaily: undefined,
    totalPasteurizedMilkNeededPerFdg: undefined,
    totalFinalVolNeededPerFdg: undefined,
    totalPowderNeededPerFdg: undefined,
    finalSolids: undefined,
    pasteurizedMilkBalancerCPAndFatRatio: undefined,
    pasteurizedMilkCPAndFatRatio: undefined,
    totalSolidsFedFromPasteurizedMilkPerCalfDaily: undefined,
    pasteurizedMilkBalancerDryMatterPerCalfPerDay: undefined,
    totalSolidsFedPerDaily: undefined,
    percentCPDryBasis: undefined,
    percentFatDryBasis: undefined,
    percentCPToPercentFatRatio: undefined,
    dmBasisCP: undefined,
    dmBasisFat: undefined,
    wtPmbPerGallon: undefined,
    brixFactor: undefined,
    wtOfPMPerGallon: undefined,
    wtOfSolidsPerGallonOfPasteurizedMilk: undefined,
    wtOfSolidsNeededPerGallonOfPasteurizedMilk: undefined,
    conversionWeight: undefined,
    solidsOfFinalSolution: undefined,
    conversionFactor: undefined,
    totalSolutionVolPerFdg: undefined,
    gallonsOfMilkNeededPerFdg: undefined,
    wtNeededPerFdg: undefined,
    wtOfTotal: undefined,
    dmBasisCpToFatRatio: undefined,
    actualSolutionVolPerCalfPerFdg: undefined,
    pasteurizedMilkFedPerCalfPerFdg: undefined,
    totalPowderFedPerCalfPerFdg: undefined,
    pasteurizedMilkFedPerCalfDaily: undefined,
  };

  if (
    input.noOfCalves &&
    input.targetPasteurizedMilkBalancerLbsPerCalfDaily &&
    input.labSolidsOrRefractometerSelection &&
    input.feedingsPerDay &&
    input.targetVolPerFdgQtPerCalf &&
    input.pasteurizedMilkBalancerCP &&
    input.pasteurizedMilkBalancerFat &&
    input.pasteurizedMilkCP &&
    input.pasteurizedMilkFat
  ) {
    const selectedUnit = (input.unit as unknown) as UnitType;
    results.totalPowderFedPerCalfDaily = input.targetPasteurizedMilkBalancerLbsPerCalfDaily;

    results.actualSolutionVolFedPerCalfDaily =
      input.targetVolPerFdgQtPerCalf * input.feedingsPerDay;

    const totalGalFinalVolNeededPerFdgVal = (input.noOfCalves * input.targetVolPerFdgQtPerCalf) / 4;

    // as our calculation was based on per feeding we get value of targetPasteurizedMilkBalancerLbsPerCalfDaily on daily basis
    // hence we are dividing targetPasteurizedMilkBalancerLbsPerCalfDaily with feedingPerDay to actually get targetPasteurizedMilkBalancerLbsPerCalfDaily value per feeding.
    results.totalPowderNeededPerFdg =
      input.noOfCalves *
      (input.targetPasteurizedMilkBalancerLbsPerCalfDaily / input.feedingsPerDay);

    results.pasteurizedMilkBalancerCPAndFatRatio =
      input.pasteurizedMilkBalancerCP / input.pasteurizedMilkBalancerFat;

    results.pasteurizedMilkCPAndFatRatio = input.pasteurizedMilkCP / input.pasteurizedMilkFat;

    results.pasteurizedMilkBalancerDryMatterPerCalfPerDay =
      results.totalPowderFedPerCalfDaily * 0.97;

    results.wtPmbPerGallon =
      (results.pasteurizedMilkBalancerDryMatterPerCalfPerDay /
        results.actualSolutionVolFedPerCalfDaily) *
      4;

    results.brixFactor = calculateBrixFactor(
      input.labSolidsOrRefractometerSelection,
      (input.labSolidsOrRefractometer as unknown) as LabSolidsOrRefractometerType,
    );

    results.wtOfPMPerGallon = 4 * (2.125 + 0.008 * (results.brixFactor - 12.5));

    results.wtOfSolidsPerGallonOfPasteurizedMilk =
      (results.brixFactor * results.wtOfPMPerGallon) / 100;

    results.wtOfSolidsNeededPerGallonOfPasteurizedMilk =
      results.wtPmbPerGallon + results.wtOfSolidsPerGallonOfPasteurizedMilk;

    results.conversionWeight = results.wtOfPMPerGallon + results.wtPmbPerGallon * 0.77;

    results.solidsOfFinalSolution =
      (results.wtPmbPerGallon + results.wtOfSolidsPerGallonOfPasteurizedMilk) /
      (1 * results.conversionWeight);

    results.conversionFactor = results.wtOfPMPerGallon / results.conversionWeight;

    const totalGalPasteurizedMilkNeededPerFdgVal =
      totalGalFinalVolNeededPerFdgVal * results.conversionFactor;

    results.totalSolidsFedFromPasteurizedMilkPerCalfDaily =
      (totalGalPasteurizedMilkNeededPerFdgVal *
        input.feedingsPerDay *
        results.wtOfPMPerGallon *
        results.brixFactor) /
      (100 * input.noOfCalves);

    results.totalSolidsFedPerDaily =
      results.totalSolidsFedFromPasteurizedMilkPerCalfDaily +
      results.pasteurizedMilkBalancerDryMatterPerCalfPerDay;

    results.dmBasisCP = (input.pasteurizedMilkCP * 100) / results.brixFactor;

    results.dmBasisFat = (input.pasteurizedMilkFat * 100) / results.brixFactor;

    results.dmBasisCpToFatRatio = results.dmBasisCP / results.dmBasisFat;

    const {
      totalPasteurizedMilkNeededPerFdg,
      totalFinalVolumeNeededPerFdg,
      totalSolutionVolPerFdg,
    } = calculateTotalNeededBasedOnUnits(
      (input.unit as unknown) as UnitType,
      input.noOfCalves,
      input.targetVolPerFdgQtPerCalf,
      results.conversionFactor,
      results.totalPowderNeededPerFdg,
      results.conversionWeight,
    );

    results.totalSolutionVolPerFdg = totalSolutionVolPerFdg;

    results.gallonsOfMilkNeededPerFdg = results.totalSolutionVolPerFdg * results.conversionFactor;

    results.wtNeededPerFdg =
      results.totalSolutionVolPerFdg * results.conversionWeight - results.totalPowderNeededPerFdg;

    results.wtOfTotal = results.wtNeededPerFdg + results.totalPowderNeededPerFdg;

    results.totalPasteurizedMilkNeededPerFdg = totalPasteurizedMilkNeededPerFdg;
    results.totalFinalVolNeededPerFdg =
      selectedUnit === 'gallons' ? totalFinalVolumeNeededPerFdg : results.wtOfTotal;

    results.finalSolids =
      (100 *
        (results.totalPowderNeededPerFdg * 0.97 +
          (results.brixFactor / 100) * results.wtNeededPerFdg)) /
      results.wtOfTotal;

    results.percentCPDryBasis =
      (results.totalSolidsFedFromPasteurizedMilkPerCalfDaily * results.dmBasisCP +
        input.pasteurizedMilkBalancerCP * results.pasteurizedMilkBalancerDryMatterPerCalfPerDay) /
      (results.totalSolidsFedFromPasteurizedMilkPerCalfDaily +
        results.pasteurizedMilkBalancerDryMatterPerCalfPerDay);

    results.percentFatDryBasis =
      (results.totalSolidsFedFromPasteurizedMilkPerCalfDaily * results.dmBasisFat +
        input.pasteurizedMilkBalancerFat * results.pasteurizedMilkBalancerDryMatterPerCalfPerDay) /
      (results.totalSolidsFedFromPasteurizedMilkPerCalfDaily +
        results.pasteurizedMilkBalancerDryMatterPerCalfPerDay);

    results.percentCPToPercentFatRatio = results.percentCPDryBasis / results.percentFatDryBasis;

    results.actualSolutionVolPerCalfPerFdg =
      (results.totalSolutionVolPerFdg / input.noOfCalves) * 4;

    results.pasteurizedMilkFedPerCalfPerFdg =
      (results.totalPasteurizedMilkNeededPerFdg / input.noOfCalves) * 4;

    results.totalPowderFedPerCalfPerFdg = results.totalPowderNeededPerFdg / input.noOfCalves;

    results.pasteurizedMilkFedPerCalfDaily =
      results.pasteurizedMilkFedPerCalfPerFdg * input.feedingsPerDay;
  }
  return results;
};

export default null;
