import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Divider } from 'antd';

// This Props are coming from parent component
type HeadingProps = {
  // This is the name of the heading which we want in the above of any page or component
  headingName: string | JSX.Element;
  // whether to show a horizontal line below title
  renderHrLine?: boolean;
  // setting heading color
  headingColor?: string;
  // This is for showing close icon
  showCrossIcon?: boolean;
  // This props is used for click of close icon
  handleCrossClick?: () => void;
};

// This is the main react component
const Heading: React.FC<HeadingProps> = (props) => {
  const {
    headingName,
    renderHrLine = true,
    headingColor = '#000',
    showCrossIcon = false,
    handleCrossClick = () => {},
  } = props;
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: 22, color: headingColor }}>{headingName}</div>
        <div>{showCrossIcon ? <CloseOutlined onClick={handleCrossClick} /> : null}</div>
      </div>
      {renderHrLine ? <Divider style={{ marginTop: 0, backgroundColor: 'grey' }} /> : null}
    </div>
  );
};

export default Heading;
