import {
  PooledColostrumReplacerDataType,
  pooledColostrumReplacerAllFields,
} from '../../calculator/colostrum-replacer-calculator/Pooled';

// return type of function
type FilteredDataType = {
  // variable to store inputs fields for pooled calculator
  pooledInputFields: PooledColostrumReplacerDataType[];
  // variable to store fields which will be shown under "Per calf" section
  pooledPerCalfOutputFields: PooledColostrumReplacerDataType[];
  // variable to store fields which will be shown under "perNCalves" section
  pooledPerNCalvesOutputFields: PooledColostrumReplacerDataType[];
  // variable to store fields which will be shown as static value this are setting specific fields
  pooledFixedFields: PooledColostrumReplacerDataType[];
};

// function will return  array of pooled fields based on some property of data.
const getPooledFieldsBasedOnProperty = (): FilteredDataType => {
  // const to store filtered data
  const filteredData: FilteredDataType = {
    pooledInputFields: [],
    pooledPerCalfOutputFields: [],
    pooledPerNCalvesOutputFields: [],
    pooledFixedFields: [],
  };

  // Iterating over pooled calculator all fields to create multiple array based on type and section key
  pooledColostrumReplacerAllFields.forEach((ele) => {
    if (ele.type === 'input') {
      filteredData.pooledInputFields.push(ele);
    }
    if (ele.type === 'fixed') {
      filteredData.pooledFixedFields.push(ele);
    }
    if (ele.sectionKey === 'perCalf') {
      filteredData.pooledPerCalfOutputFields.push(ele);
    }
    if (ele.sectionKey === 'perNCalves') {
      filteredData.pooledPerNCalvesOutputFields.push(ele);
    }
  });
  return filteredData;
};

export default getPooledFieldsBasedOnProperty;
