import React from 'react';
import './LoadingScreen.css';
import logo from '../assests/images/caddieLogo.png';

/** const to store spinner color of loader */
const spinnerColor = '#c5232a';

// This is a loading screen
const LoadingPage: React.FC = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 150,
      alignItems: 'center',
    }}
  >
    {/* logo */}
    <img width={250} src={logo} alt="caddie_logo" />

    {/* loader */}
    <div className="spinner">
      <div
        className="bounce1"
        style={{
          backgroundColor: spinnerColor,
        }}
      />
      <div
        className="bounce2"
        style={{
          backgroundColor: spinnerColor,
        }}
      />
      <div
        className="bounce3"
        style={{
          backgroundColor: spinnerColor,
        }}
      />
    </div>
  </div>
);

export default LoadingPage;
