import {
  CalculatorDataCommonType,
  LabSolidsOrRefractometerType,
  ReferenceNameType,
} from '../utils/types';

/* Type for PM & water input keys */
export type PmAndWaterInputsKeyType =
  | 'noOfCalves'
  | 'totalVolAvailableGallonsPerFdg'
  | 'labSolidsOrRefractometerSelection'
  | 'targetSolidsFinalSolution'
  | 'targetVolPerFdgQtPerCalf'
  | 'feedingsPerDay'
  | 'lbOfSecondPowderPerHdPerDay'
  | 'pasteurizedMilkCP'
  | 'pasteurizedMilkFat'
  | 'powderOneCP'
  | 'powderOneFat'
  | 'powderTwoCP'
  | 'powderTwoFat'
  | 'labSolidsOrRefractometer'
  | 'powderFeedingCount'
  | 'powderType';

/* Type for PM & water output keys */
export type PmAndWatersOutputsKeyType =
  | 'powderOneCPAndFatRatio'
  | 'powderTwoCPAndFatRatio'
  | 'totalSolutionVolFedPerCalfDaily'
  | 'totalFinalSolutionVolPerFdg'
  | 'totalSolidsFedPerCalfDaily'
  | 'totalSolidsFedFromPasteurizedMilkPerCalfPerFdg'
  | 'totalSolidsFedFromPasteurizedMilkPerCalfDaily'
  | 'totalSolidsFedFromPowderPerCalfDaily'
  | 'total'
  | 'percentCPDryBasis'
  | 'percentFatDryBasis'
  | 'percentCPToPercentFatRatio'
  | 'dmCP'
  | 'dmFat'
  | 'dmCpToFatRatio'
  | 'totalPowderFedPerCalfPerFdg'
  | 'totalPowderFedPerCalfDaily'
  | 'amtGallonsOfPasteurizedMilk'
  | 'milkFedPerCalfPerFdg'
  | 'pasteurizedMilkBalancerFedPerCalfPerFdg'
  | 'totalMilkNeededPerFdg'
  | 'totalBalancerNeededPerFdg'
  | 'totalPowderFedPerFdg'
  | 'waterNeededPerFdg'
  | 'powderNeeded'
  | 'waterNeeded'
  | 'totalSolidsPerHdPerDay'
  | 'solidsPerFdgFromPM'
  | 'lbsByGallonsOfPM'
  | 'lbsByGallonsOfFinalSolution'
  | 'lbsPowderDmNeededPerFdg'
  | 'solidsDecimalAfterConversion'
  | 'pasteurizedMilkFedPerFdg'
  | 'actualSolutionVolPerFdg'
  | 'pasteurizedMilkFedPerCalfPerFdg'
  | 'pasteurizedMilkFedPerCalfDaily';

/* Type for Pm and water all data fields */
export interface PmAndWaterDataFieldsType extends CalculatorDataCommonType {
  key: PmAndWaterInputsKeyType | PmAndWatersOutputsKeyType;
}

/* Type for PM & water all input fields */
export interface PmAndWaterInputFieldsType extends CalculatorDataCommonType {
  key: Exclude<PmAndWaterInputsKeyType, 'noOfCalves' | 'feedingsPerDay'>;
}

/* type for selection of powder count  */
export type PowderFeedingCountSelectionType = 1 | 2;

// type for powder type
export type PowderType = 'milkReplacer' | 'milkBalancer';

export interface PmAndWaterFormFieldsWithoutReferenceNameType {
  /* total volume available gallons per feeding */
  totalVolAvailableGallonsPerFdg: number | undefined | null;
  /* labs solids or refractometer selection value */
  labSolidsOrRefractometerSelection: number | undefined | null;
  /* Total solids of final solution */
  targetSolidsFinalSolution: number | undefined | null;
  /* target volume per feeding qt per calf */
  targetVolPerFdgQtPerCalf: number | undefined | null;
  /* Pounds of second powder pe head daily */
  lbOfSecondPowderPerHdPerDay: number | undefined | null;
  /* Pasteurized milk CP */
  pasteurizedMilkCP: number | undefined | null;
  /* Pasteurized milk Fat */
  pasteurizedMilkFat: number | undefined | null;
  /* Powder 1 CP */
  powderOneCP: number | undefined | null;
  /* Powder 1 Fat */
  powderOneFat: number | undefined | null;
  /* Powder 2 CP */
  powderTwoCP: number | undefined | null;
  /* Powder 2 Fat */
  powderTwoFat: number | undefined | null;
  /* lab solid or refractometer selected option */
  labSolidsOrRefractometer: LabSolidsOrRefractometerType;
  /* select option for count of powder to feed */
  powderFeedingCount: PowderFeedingCountSelectionType;
  /* select option for which type of powder to feed (when user decide to feed only one powder) */
  powderType: PowderType;
}
/* Type of pm and water form fields type */
export interface PmAndWaterFormFieldsType
  extends ReferenceNameType,
    PmAndWaterFormFieldsWithoutReferenceNameType {}

/* Function to calculate solid decimals after conversion based on selected key 'labSolids' or 'refractometerBrix' */
const calculateSolidDecimalAfterConversion = (
  key: LabSolidsOrRefractometerType,
  labSolidsOrRefractometerSelectionVal: number,
): number => {
  if (key === 'labSolids') {
    return labSolidsOrRefractometerSelectionVal / 100;
  }
  return (
    (labSolidsOrRefractometerSelectionVal ** 2 * -0.05318 +
      labSolidsOrRefractometerSelectionVal * 2.72 -
      9.7204) /
    100
  );
};

/* Pm & water static data array */
export const pmAndWaterValues: PmAndWaterDataFieldsType[] = [
  {
    key: 'noOfCalves',
    type: 'input',
    description: {
      label: 'Number of Calves',
    },
  },
  {
    key: 'feedingsPerDay',
    type: 'input',
    description: {
      label: 'Feedings',
      unit: 'per day',
    },
  },
  {
    key: 'targetVolPerFdgQtPerCalf',
    type: 'input',
    description: {
      label: 'Target volume',
      unit: 'per feeding Qt per calf',
    },
  },
  {
    key: 'targetSolidsFinalSolution',
    type: 'input',
    description: {
      label: 'Target % Solids of Final Solution',
    },
  },
  {
    key: 'totalVolAvailableGallonsPerFdg',
    type: 'input',
    description: {
      label: 'Total Volume Available Gallons',
      unit: 'per feeding',
    },
  },
  {
    key: 'labSolidsOrRefractometer',
    type: 'input',
    description: {
      label: 'Select',
    },
    isSelectOption: [
      { label: 'Lab Solids (%)', value: 'labSolids' },
      {
        label: 'Refractometer Brix Reading',
        value: 'refractometerBrix',
      },
    ],
  },
  {
    key: 'labSolidsOrRefractometerSelection',
    type: 'input',
    description: {
      label: (type: string) =>
        type === 'refractometerBrix' ? 'Refractometer Brix Reading' : 'Lab Solids (%)',
    },
  },
  {
    key: 'pasteurizedMilkCP',
    type: 'input',
    description: {
      label: 'Pasteurized Milk % CP (as is)',
    },
  },
  {
    key: 'pasteurizedMilkFat',
    type: 'input',
    description: {
      label: 'Pasteurized Milk % Fat (as is)',
    },
  },
  {
    key: 'powderFeedingCount',
    type: 'input',
    description: {
      label: 'Are you feeding 1 or 2 powders?',
    },
    isSelectOption: [
      {
        label: '2',
        value: 2,
      },
      {
        label: '1',
        value: 1,
      },
    ],
  },
  {
    key: 'powderType',
    type: 'input',
    description: {
      label: 'Powder Type',
    },
    isSelectOption: [
      {
        label: 'Milk Replacer',
        value: 'milkReplacer',
      },
      {
        label: 'Pasteurized Milk Balancer',
        value: 'milkBalancer',
      },
    ],
  },
  //  when user is feeding only one powder, powderOneCP will be either milk replacer % CP or balancer % CP based on powderType selection,
  // When user is feeding both powder then this will be milk replacer %cp
  {
    key: 'powderOneCP',
    type: 'input',
    description: {
      label: (type: string) => `${type} % CP`,
    },
  },
  //  when user is feeding only one powder, powderOneCP will be either milk replacer % Fat or balancer % Fat based on powderType selection,
  // When user is feeding both powder then this will be milk replacer % Fat
  {
    key: 'powderOneFat',
    type: 'input',
    description: {
      label: (type: string) => `${type} % Fat`,
    },
  },
  // this field will only be consider when user feeding two powder. for one powder this field will be hidden
  {
    key: 'lbOfSecondPowderPerHdPerDay',
    type: 'input',
    description: {
      label: 'Lb. of 2nd Powder',
      unit: 'per head per day',
    },
  },
  // this field will only be consider when user feeding two powder as is named as Balancer % CP . for one powder this field will be hidden
  {
    key: 'powderTwoCP',
    type: 'input',
    description: {
      label: 'Powder 2 %CP',
    },
  },
  // this field will only be consider when user feeding two powder as is named as Balancer % Fat . for one powder this field will be hidden
  {
    key: 'powderTwoFat',
    type: 'input',
    description: {
      label: 'Powder 2 %Fat',
    },
  },
  {
    key: 'totalPowderFedPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Powder Fed',
      unit: 'per feeding (lb)',
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  // this field is linked with powder one.
  {
    key: 'totalMilkNeededPerFdg',
    type: 'calculated',
    description: {
      label: (powderLabel: string): string => `Total Milk ${powderLabel} Needed`,
      unit: 'per feeding (lbs)',
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'totalBalancerNeededPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Balancer Needed',
      unit: 'per feeding (lbs)',
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'pasteurizedMilkFedPerFdg',
    type: 'calculated',
    description: {
      label: 'Pasteurized Milk Fed',
      unit: 'per feeding (gallons)',
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'waterNeededPerFdg',
    type: 'calculated',
    description: {
      label: 'Water Needed',
      unit: 'per feeding (gallon)',
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'totalFinalSolutionVolPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Final Solution Volume',
      unit: 'per feeding (gallon)',
    },
    calculatedType: 'primary',
    decimalPlaces: 0,
  },
  {
    key: 'actualSolutionVolPerFdg',
    type: 'calculated',
    description: {
      label: 'Actual Solution Volume',
      unit: 'per calf per feeding (qts)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'pasteurizedMilkFedPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: 'Pasteurized Milk Fed',
      unit: 'per calf per feeding (qts)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalPowderFedPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Powder Fed',
      unit: 'per calf per feeding (lb)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  // this field linked to powder one and its powderLabel is based on powder type selection
  {
    key: 'milkFedPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: (powderLabel: string): string => `Milk ${powderLabel} Fed`,
      unit: 'per calf per feeding (lb)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'pasteurizedMilkBalancerFedPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: 'Pasteurized Milk Balancer Fed',
      unit: 'per calf per feeding (lb)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalSolidsFedFromPasteurizedMilkPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: 'Total Solids Fed from Pasteurized Milk',
      unit: 'per calf per feeding (lbs)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'percentCPDryBasis',
    type: 'calculated',
    description: {
      label: '% CP (dry basis)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 1,
  },
  {
    key: 'percentFatDryBasis',
    type: 'calculated',
    description: {
      label: '% Fat (dry basis)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 1,
  },
  {
    key: 'percentCPToPercentFatRatio',
    type: 'calculated',
    description: {
      label: '% CP to % Fat Ratio',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalSolutionVolFedPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Total Solution Volume Fed',
      unit: 'per calf daily (qt)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'pasteurizedMilkFedPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Pasteurized Milk Fed',
      unit: 'per calf daily (qts)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalPowderFedPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Total Powder Fed',
      unit: 'per calf daily (lb)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalSolidsFedPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Total Solids Fed',
      unit: 'per calf daily (lbs)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalSolidsFedFromPowderPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Total Solids Fed from Powder',
      unit: 'per calf daily (lb)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'totalSolidsFedFromPasteurizedMilkPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Total Solids Fed from Pasteurized Milk',
      unit: 'per calf daily (lbs)',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'amtGallonsOfPasteurizedMilk',
    type: 'calculated',
    description: {
      label: 'Amount of Past. Milk',
      unit: 'in gallons',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'total',
    type: 'calculated',
    description: {
      label: 'Total Solids Fed',
      unit: 'per calf daily (lbs)',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'dmCP',
    type: 'calculated',
    description: {
      label: 'Pasteurized milk DM % CP',
    },
    calculatedType: 'hidden',
    decimalPlaces: 1,
  },
  {
    key: 'dmFat',
    type: 'calculated',
    description: {
      label: 'Pasteurized milk DM % Fat',
    },
    calculatedType: 'hidden',
    decimalPlaces: 1,
  },
  {
    key: 'dmCpToFatRatio',
    type: 'calculated',
    description: {
      label: 'Pasteurized milk DM %CP & % Fat Ratio',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'powderNeeded',
    type: 'calculated',
    description: {
      label: 'Powder needed',
    },
    calculatedType: 'hidden',
    decimalPlaces: 1,
  },
  {
    key: 'waterNeeded',
    description: {
      label: 'Water needed',
    },
    type: 'calculated',
    calculatedType: 'hidden',
    decimalPlaces: 0,
  },
  {
    key: 'totalSolidsPerHdPerDay',
    type: 'calculated',
    description: {
      label: 'Total Solids/hd daily',
      unit: 'per head per day',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'solidsPerFdgFromPM',
    type: 'calculated',
    description: {
      label: 'Solids from PM',
      unit: 'per feeding',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
  {
    key: 'lbsByGallonsOfPM',
    type: 'calculated',
    description: {
      label: 'Lb/gallon of PM',
    },
    calculatedType: 'hidden',
    decimalPlaces: 4,
  },
  {
    key: 'lbsByGallonsOfFinalSolution',
    type: 'calculated',
    description: {
      label: 'Lb/gallon of final solution',
    },
    calculatedType: 'hidden',
    decimalPlaces: 4,
  },
  {
    key: 'lbsPowderDmNeededPerFdg',
    type: 'calculated',
    description: {
      label: 'Lb powder DM needed/fdg',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
  {
    key: 'solidsDecimalAfterConversion',
    type: 'calculated',
    description: {
      label: 'Solids decimal after conversion if brix',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
  {
    key: 'powderOneCPAndFatRatio',
    type: 'calculated',
    description: {
      label: 'Powder 1 %CP & %Fat Ratio',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'powderTwoCPAndFatRatio',
    type: 'calculated',
    description: {
      label: 'Powder 2 %CP & %Fat Ratio',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
];

/* Function to calculate fields based on input fields for PM & water fields */
export const calculatedPMAndWaterFields = (
  input: Record<PmAndWaterInputsKeyType, number | undefined>,
): Record<PmAndWatersOutputsKeyType, number | undefined> => {
  /* Object to store results after calculating fields based on provided logic */
  const results: Record<PmAndWatersOutputsKeyType, number | undefined> = {
    powderOneCPAndFatRatio: undefined,
    powderTwoCPAndFatRatio: undefined,
    totalSolutionVolFedPerCalfDaily: undefined,
    totalFinalSolutionVolPerFdg: undefined,
    totalSolidsFedPerCalfDaily: undefined,
    totalSolidsFedFromPasteurizedMilkPerCalfPerFdg: undefined,
    totalSolidsFedFromPasteurizedMilkPerCalfDaily: undefined,
    totalSolidsFedFromPowderPerCalfDaily: undefined,
    total: undefined,
    percentCPDryBasis: undefined,
    percentFatDryBasis: undefined,
    percentCPToPercentFatRatio: undefined,
    dmCP: undefined,
    dmFat: undefined,
    dmCpToFatRatio: undefined,
    totalPowderFedPerCalfPerFdg: undefined,
    totalPowderFedPerCalfDaily: undefined,
    amtGallonsOfPasteurizedMilk: undefined,
    milkFedPerCalfPerFdg: undefined,
    totalMilkNeededPerFdg: undefined,
    totalBalancerNeededPerFdg: undefined,
    totalPowderFedPerFdg: undefined,
    waterNeededPerFdg: undefined,
    powderNeeded: undefined,
    waterNeeded: undefined,
    totalSolidsPerHdPerDay: undefined,
    solidsPerFdgFromPM: undefined,
    lbsByGallonsOfPM: undefined,
    lbsByGallonsOfFinalSolution: undefined,
    lbsPowderDmNeededPerFdg: undefined,
    solidsDecimalAfterConversion: undefined,
    pasteurizedMilkBalancerFedPerCalfPerFdg: undefined,
    pasteurizedMilkFedPerFdg: undefined,
    actualSolutionVolPerFdg: undefined,
    pasteurizedMilkFedPerCalfPerFdg: undefined,
    pasteurizedMilkFedPerCalfDaily: undefined,
  };

  if (
    input.noOfCalves &&
    input.totalVolAvailableGallonsPerFdg &&
    input.labSolidsOrRefractometerSelection &&
    input.targetSolidsFinalSolution &&
    input.targetVolPerFdgQtPerCalf &&
    input.feedingsPerDay &&
    input.pasteurizedMilkCP &&
    input.pasteurizedMilkFat
  ) {
    /* Variable to store converted percent value of 'targetSolidsFinalSolution' input field */
    const targetSolidsFinalSolutionVal = input.targetSolidsFinalSolution / 100;
    const lbOfSecondPowderPerHdPerDayVal = input.lbOfSecondPowderPerHdPerDay as number;

    if (input.powderOneCP && input.powderOneFat) {
      results.powderOneCPAndFatRatio = input.powderOneCP / input.powderOneFat;
    }
    if (input.powderTwoCP && input.powderTwoFat) {
      results.powderTwoCPAndFatRatio = input.powderTwoCP / input.powderTwoFat;
    }

    // in new update we want to show totalSolutionVolFedPerCalfDaily in quarts (only this field).
    // all other output fields calculation based on this fields will be done in gallons only hence we define totalSolutionVolFedPerCalfDailyInGallons this const
    results.totalSolutionVolFedPerCalfDaily = input.targetVolPerFdgQtPerCalf * input.feedingsPerDay;

    // this const is nothing but the conversion of totalSolutionVolFedPerCalfDaily( which is now in quarts) into  gallon.

    const totalSolutionVolFedPerCalfDailyInGallons = results.totalSolutionVolFedPerCalfDaily / 4;

    results.totalFinalSolutionVolPerFdg =
      (totalSolutionVolFedPerCalfDailyInGallons / input.feedingsPerDay) * input.noOfCalves;

    results.lbsByGallonsOfFinalSolution =
      4 * (2.125 + 0.008 * (targetSolidsFinalSolutionVal * 100 - 12.5));

    results.totalSolidsPerHdPerDay =
      results.lbsByGallonsOfFinalSolution *
      totalSolutionVolFedPerCalfDailyInGallons *
      targetSolidsFinalSolutionVal;

    results.totalSolidsFedPerCalfDaily = results.totalSolidsPerHdPerDay;

    results.amtGallonsOfPasteurizedMilk =
      (input.totalVolAvailableGallonsPerFdg / input.noOfCalves) * lbOfSecondPowderPerHdPerDayVal;

    results.solidsDecimalAfterConversion = calculateSolidDecimalAfterConversion(
      (input.labSolidsOrRefractometer as unknown) as LabSolidsOrRefractometerType,
      input.labSolidsOrRefractometerSelection,
    );

    results.lbsByGallonsOfPM =
      4 * (2.125 + 0.008 * (results.solidsDecimalAfterConversion * 100 - 12.5));

    results.solidsPerFdgFromPM =
      (input.totalVolAvailableGallonsPerFdg / input.noOfCalves) *
      results.lbsByGallonsOfPM *
      results.solidsDecimalAfterConversion;

    results.lbsPowderDmNeededPerFdg =
      results.totalSolidsPerHdPerDay / input.feedingsPerDay - results.solidsPerFdgFromPM;

    const calculatedFieldForPowerNeededVal =
      results.totalSolidsPerHdPerDay - input.feedingsPerDay * results.solidsPerFdgFromPM;

    results.powderNeeded =
      (calculatedFieldForPowerNeededVal * input.noOfCalves) / 0.97 / input.feedingsPerDay;

    results.waterNeeded =
      (results.totalFinalSolutionVolPerFdg * results.lbsByGallonsOfFinalSolution -
        (results.powderNeeded + input.totalVolAvailableGallonsPerFdg * results.lbsByGallonsOfPM)) /
      8.252;

    results.totalSolidsFedFromPasteurizedMilkPerCalfPerFdg = results.solidsPerFdgFromPM;

    results.totalSolidsFedFromPasteurizedMilkPerCalfDaily =
      results.totalSolidsFedFromPasteurizedMilkPerCalfPerFdg * input.feedingsPerDay;

    results.totalPowderFedPerCalfPerFdg = results.lbsPowderDmNeededPerFdg / 0.97;

    results.totalPowderFedPerCalfDaily = results.totalPowderFedPerCalfPerFdg * input.feedingsPerDay;

    results.totalSolidsFedFromPowderPerCalfDaily = results.totalPowderFedPerCalfDaily * 0.97;

    results.total =
      results.totalSolidsFedFromPasteurizedMilkPerCalfDaily +
      results.totalSolidsFedFromPowderPerCalfDaily;

    results.milkFedPerCalfPerFdg =
      (results.totalPowderFedPerCalfDaily - lbOfSecondPowderPerHdPerDayVal) / input.feedingsPerDay;

    results.pasteurizedMilkBalancerFedPerCalfPerFdg =
      lbOfSecondPowderPerHdPerDayVal / input.feedingsPerDay;

    results.totalMilkNeededPerFdg = results.milkFedPerCalfPerFdg * input.noOfCalves;

    results.totalBalancerNeededPerFdg =
      (lbOfSecondPowderPerHdPerDayVal * input.noOfCalves) / input.feedingsPerDay;

    results.dmCP = input.pasteurizedMilkCP / results.solidsDecimalAfterConversion;

    results.dmFat = input.pasteurizedMilkFat / results.solidsDecimalAfterConversion;

    results.dmCpToFatRatio = results.dmCP / results.dmFat;

    /* Converting to percentage from actual value */
    results.percentCPDryBasis =
      ((results.totalSolidsFedFromPasteurizedMilkPerCalfDaily * (results.dmCP / 100) +
        0.97 *
          (((input.powderOneCP ? input.powderOneCP : 0) / 100) *
            (results.milkFedPerCalfPerFdg * input.feedingsPerDay) +
            ((input.powderTwoCP ? input.powderTwoCP : 0) / 100) * lbOfSecondPowderPerHdPerDayVal)) /
        results.total) *
      100;

    results.percentFatDryBasis =
      ((results.totalSolidsFedFromPasteurizedMilkPerCalfDaily * (results.dmFat / 100) +
        0.97 *
          (((input.powderOneFat ? input.powderOneFat : 0) / 100) *
            (results.milkFedPerCalfPerFdg * input.feedingsPerDay) +
            ((input.powderTwoFat ? input.powderTwoFat : 0) / 100) *
              lbOfSecondPowderPerHdPerDayVal)) /
        results.total) *
      100;

    results.percentCPToPercentFatRatio = results.percentCPDryBasis / results.percentFatDryBasis;

    results.totalPowderFedPerFdg = results.powderNeeded;

    results.waterNeededPerFdg = results.waterNeeded;
    results.pasteurizedMilkFedPerFdg = input.totalVolAvailableGallonsPerFdg;

    results.actualSolutionVolPerFdg = (results.totalFinalSolutionVolPerFdg / input.noOfCalves) * 4;

    results.pasteurizedMilkFedPerCalfPerFdg =
      (results.pasteurizedMilkFedPerFdg / input.noOfCalves) * 4;

    results.pasteurizedMilkFedPerCalfDaily =
      results.pasteurizedMilkFedPerCalfPerFdg * input.feedingsPerDay;
  }

  return results;
};

export default null;
