import { measurementsSystemWithDefaultUnits, unitLabels } from '@ecp32/calculations';
import { Enum_Measurement_System_Enum } from '../../graphql/graphql-types';
import { MeasurementsSystemSubType } from '../types';

// function to render label based on selected unit
const renderLabelBasedOnUnit = (
  label: string | ((type: string) => string),
  unit: Enum_Measurement_System_Enum,
  unitKey?: MeasurementsSystemSubType,
): string =>
  `${
    typeof label === 'function'
      ? label(
          unitLabels[
            measurementsSystemWithDefaultUnits[unit][
              unitKey || 'defaultVolumeUnit'
            ] as keyof typeof unitLabels
          ],
        )
      : label
  }`;

export default renderLabelBasedOnUnit;
