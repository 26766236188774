import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Row } from 'antd';
import {
  PmrbNoWaterFormFieldsType,
  PmrbNOWaterInputFieldsType,
  pmrbNoWaterValues,
  calculatedPMRBNoWaterFields,
  PmrbNOWaterDataFieldsType,
  PmrbNoWaterFormFieldsWithoutReferenceNameType,
} from '../../calculator/PMRBNoWater';
import {
  noOfCalvesFeedingsPerDayFormValidationSchema,
  numberGreaterThanZeroSchema,
} from '../../utils/globals';
import {
  CalculatorFormsPropsType,
  LocalForageFarmIdType,
  NoOfCalvesFeedingsPerDayFormType,
} from '../../utils/types';
import {
  filterArrayBasedOnType,
  logger,
  readCalculatorInputs,
  removeCalculatorInputs,
  saveCalculatorInputs,
} from '../../utils/helpers';
import SectionHeaderComponent from '../../components/SectionHeaderComponent';
import FormItem from '../../components/FormItem';
import InputNumberComponent from '../../components/InputNumberComponent';
import CalculatedFieldsListViewComponent from './CalculatedFieldsListViewComponent';
import RadioGroup from '../../components/RadioGroup';
import ReferenceNameModalComponent from './ReferenceNameModalComponent';
import { Enum_Calculator_Enum } from '../../graphql/graphql-types';
import styles from './CalculatorForms.module.scss';
import NoOfCalvesFeedingsPerDayForm from '../NoOfCalvesFeedingPerDayForm';
import ReportNameModalComponent, { ReportGenerationDataType } from './ReportNameModalComponent';

/* PMRB no water default values */
const pmrbNoWaterFormDefaultValues: PmrbNoWaterFormFieldsType = {
  targetPasteurizedMilkBalancerLbsPerCalfDaily: null,
  labSolidsOrRefractometerSelection: null,
  targetVolPerFdgQtPerCalf: null,
  pasteurizedMilkBalancerCP: null,
  pasteurizedMilkBalancerFat: null,
  pasteurizedMilkCP: null,
  pasteurizedMilkFat: null,
  labSolidsOrRefractometer: 'labSolids',
  unit: 'gallons',
  referenceName: '',
};

/* PMRB no water Validation schema */
const validationSchema = yup.object().shape({
  targetPasteurizedMilkBalancerLbsPerCalfDaily: numberGreaterThanZeroSchema,
  labSolidsOrRefractometerSelection: numberGreaterThanZeroSchema,
  targetVolPerFdgQtPerCalf: numberGreaterThanZeroSchema,
  pasteurizedMilkBalancerCP: numberGreaterThanZeroSchema,
  pasteurizedMilkBalancerFat: numberGreaterThanZeroSchema,
  pasteurizedMilkCP: numberGreaterThanZeroSchema,
  pasteurizedMilkFat: numberGreaterThanZeroSchema,
});

/* React functional component */
const PmrbNoWaterForm: React.FC<CalculatorFormsPropsType> = ({
  selectedFarmId,
  setSelectedFarmId,
  noOfCalves,
  feedingsPerDay,
  initialInputs,
  setCalculationData,
  farmName,
}) => {
  /* State used to decide whether to show submit modal(modal with reference name field) or not */
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);

  /* State to decide whether to show data with unit per calf per feeding or not */
  const [showPerCalfPerFdgData, setShowPerCalfPerFdgData] = useState<boolean>(false);

  /* State to decide whether to show data with unit per calf daily or not */
  const [showPerCalfDailyData, setShowPerCalfDailyData] = useState<boolean>(false);

  /* State used to store data that will be used for report generation */
  const [
    dataForReportGeneration,
    setDataForReportGeneration,
  ] = useState<ReportGenerationDataType | null>(null);

  // variable to store form initial values when user want to update existing calculator from history screen
  let pmrbNoWaterFormInitialValues: Record<string, string | number | undefined> = {};

  // variable to store form initial value of noOFCalves and  feedingsPerDay when user want to update existing calculator from history screen
  let noOfCalvesFeedingsPerDayInitialValue = {};

  // logic to get initialValues when form is called from history screen
  if ((noOfCalves === undefined || feedingsPerDay === undefined) && initialInputs) {
    Object.keys(initialInputs).forEach((input) => {
      if (input !== 'noOfCalves' && input !== 'feedingsPerDay') {
        pmrbNoWaterFormInitialValues = {
          ...pmrbNoWaterFormInitialValues,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          [input]: initialInputs[input],
        };
      } else {
        noOfCalvesFeedingsPerDayInitialValue = {
          ...noOfCalvesFeedingsPerDayInitialValue,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          [input]: initialInputs[input],
        } as NoOfCalvesFeedingsPerDayFormType;
      }
    });
  } else {
    noOfCalvesFeedingsPerDayInitialValue = {
      noOfCalves: undefined,
      feedingsPerDay: 2,
    };
  }

  /* Variable to store updated cmr form initial values if needed */
  let updatedKeysPmrbNoWaterFormInitialValues = pmrbNoWaterFormInitialValues;

  /**
   * This code block is written because some keys in web and mobile are spelled differently but
   * associated with the same field which is making issues while assigning default values to the form in edit mode
   * Hence here key is renamed to the correct one
   */
  if (Object.keys(pmrbNoWaterFormInitialValues).length > 0) {
    /* Destructuring PMRB no water initial values */
    const { totalSolidsFed, totalSolidsFedPerDaily, ...rest } = pmrbNoWaterFormInitialValues;

    /* Updating initial values */
    updatedKeysPmrbNoWaterFormInitialValues = {
      ...rest,
      totalSolidsFedPerDaily: totalSolidsFedPerDaily || totalSolidsFed,
    };
  }

  /* useForm declaration for  form containing only noOfCalves and feedingsPerDay fields */
  const {
    control: noOfCalvesFeedingsPerDayFormControl,
    formState: { errors: noOfCalvesFeedingsPerDayFormErrors },
    watch: noOfCalvesFeedingsPerDayFormWatch,
    handleSubmit: noOfCalvesFeedingsPerDayFormHandleSubmit,
  } = useForm<NoOfCalvesFeedingsPerDayFormType>({
    defaultValues:
      noOfCalves && feedingsPerDay
        ? {
            noOfCalves: undefined,
            feedingsPerDay: 2,
          }
        : noOfCalvesFeedingsPerDayInitialValue,
    resolver: yupResolver(noOfCalvesFeedingsPerDayFormValidationSchema),
  });

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    reset,
    setValue,
    setError,
  } = useForm<PmrbNoWaterFormFieldsType>({
    defaultValues:
      noOfCalves && feedingsPerDay
        ? pmrbNoWaterFormDefaultValues
        : updatedKeysPmrbNoWaterFormInitialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  // useEffect to fetch data stored in localforage
  useEffect(() => {
    // if condition because we don't want localforage value as initial values of form when user open form from history screen
    if (noOfCalves && feedingsPerDay) {
      // call to localforage readCalculatorInputs function to get data
      readCalculatorInputs(Enum_Calculator_Enum.PmrbNoWater)
        .then((res) => {
          if (res) {
            /* Local forage calculator inputs with farm id */
            const { farmId, ...restData } = res as PmrbNoWaterFormFieldsType &
              LocalForageFarmIdType;

            if (farmId === selectedFarmId) {
              reset(restData);
            } else {
              reset(pmrbNoWaterFormDefaultValues);
            }
          }
        })
        .catch((err) => {
          logger(err);
        });
    }
  }, [feedingsPerDay, noOfCalves, reset, selectedFarmId, setValue]);

  /* Input fields for form */
  const inputFields = pmrbNoWaterValues.filter(
    (item) => item.type === 'input' && !['noOfCalves', 'feedingsPerDay'].includes(item.key),
  ) as PmrbNOWaterInputFieldsType[];

  /* Variable to store primary calculated fields using form input values */
  const primaryCalculatedFields = filterArrayBasedOnType(pmrbNoWaterValues, 'primary');

  /* Variable to store secondary calculated fields using form input values */
  const secondaryCalculatedFields = filterArrayBasedOnType(
    pmrbNoWaterValues,
    'secondary',
  ) as PmrbNOWaterDataFieldsType[];

  // const to store array of fields whose unit is per calf per feeding or has no unit
  const perCalfPerFdgDataFields = secondaryCalculatedFields.filter(
    (item) =>
      (typeof item.description.unit === 'string' &&
        item.description.unit.includes('per calf per feeding')) ||
      item.description.unit === undefined,
  );

  // const to store array of fields whose unit is per calf per feeding or has no unit
  const perCalfDailyDataFields = secondaryCalculatedFields.filter(
    (item) =>
      typeof item.description.unit === 'string' && item.description.unit.includes('per calf daily'),
  );

  /* Function to submit form data */
  const onSubmit = () => {
    setShowSubmitModal(true);
  };

  /* Watch for targetPasteurizedMilkBalancerLbsPerCalfDaily fields */
  const watchTargetPasteurizedMilkBalancerLbsPerCalfDaily = watch(
    'targetPasteurizedMilkBalancerLbsPerCalfDaily',
  );
  /* Watch for labSolidsOrRefractometerSelection fields */
  const watchLabSolidsOrRefractometerSelection = watch('labSolidsOrRefractometerSelection');
  /* Watch for targetVolPerFdgQtPerCalf fields */
  const watchTargetVolPerFdgQtPerCalf = watch('targetVolPerFdgQtPerCalf');
  /* Watch for pasteurizedMilkBalancerCP fields */
  const watchPasteurizedMilkBalancerCP = watch('pasteurizedMilkBalancerCP');
  /* Watch for pasteurizedMilkBalancerFat fields */
  const watchPasteurizedMilkBalancerFat = watch('pasteurizedMilkBalancerFat');
  /* Watch for pasteurizedMilkCP fields */
  const watchPasteurizedMilkCP = watch('pasteurizedMilkCP');
  /* Watch for pasteurizedMilkFat fields */
  const watchPasteurizedMilkFat = watch('pasteurizedMilkFat');
  /* Watch for labSolidsOrRefractometer fields */
  const watchLabSolidsOrRefractometer = watch('labSolidsOrRefractometer');
  /* Watch for unit fields */
  const watchUnit = watch('unit');

  /* Calculated fields for PMRB no water type */
  const pmrbNoWaterFields = calculatedPMRBNoWaterFields({
    noOfCalves: noOfCalves || noOfCalvesFeedingsPerDayFormWatch('noOfCalves'),
    feedingsPerDay: feedingsPerDay || noOfCalvesFeedingsPerDayFormWatch('feedingsPerDay'),
    targetPasteurizedMilkBalancerLbsPerCalfDaily:
      watchTargetPasteurizedMilkBalancerLbsPerCalfDaily &&
      watchTargetPasteurizedMilkBalancerLbsPerCalfDaily > 0
        ? watchTargetPasteurizedMilkBalancerLbsPerCalfDaily
        : undefined,
    labSolidsOrRefractometerSelection:
      watchLabSolidsOrRefractometerSelection && watchLabSolidsOrRefractometerSelection > 0
        ? watchLabSolidsOrRefractometerSelection
        : undefined,
    targetVolPerFdgQtPerCalf:
      watchTargetVolPerFdgQtPerCalf && watchTargetVolPerFdgQtPerCalf > 0
        ? watchTargetVolPerFdgQtPerCalf
        : undefined,
    pasteurizedMilkBalancerCP:
      watchPasteurizedMilkBalancerCP && watchPasteurizedMilkBalancerCP > 0
        ? watchPasteurizedMilkBalancerCP
        : undefined,
    pasteurizedMilkBalancerFat:
      watchPasteurizedMilkBalancerFat && watchPasteurizedMilkBalancerFat > 0
        ? watchPasteurizedMilkBalancerFat
        : undefined,
    pasteurizedMilkCP:
      watchPasteurizedMilkCP && watchPasteurizedMilkCP > 0 ? watchPasteurizedMilkCP : undefined,
    pasteurizedMilkFat:
      watchPasteurizedMilkFat && watchPasteurizedMilkFat > 0 ? watchPasteurizedMilkFat : undefined,
    labSolidsOrRefractometer: (watchLabSolidsOrRefractometer as unknown) as number,
    unit: (watchUnit as unknown) as number,
  });

  return (
    <>
      {/* Reference name modal to add name to the calculation */}
      <ReferenceNameModalComponent
        showModal={showSubmitModal}
        setShowModal={setShowSubmitModal}
        control={control}
        selectedFarmId={selectedFarmId}
        setSelectedFarmId={setSelectedFarmId}
        getValues={getValues}
        type={Enum_Calculator_Enum.PmrbNoWater}
        noOfCalves={noOfCalves || noOfCalvesFeedingsPerDayFormWatch('noOfCalves')}
        feedingsPerDay={feedingsPerDay || noOfCalvesFeedingsPerDayFormWatch('feedingsPerDay')}
        setValue={setValue}
        errorText={errors.referenceName?.message}
        setError={setError}
        setCalculationData={setCalculationData}
      />

      {dataForReportGeneration && (
        <ReportNameModalComponent
          showModal={!!dataForReportGeneration}
          setShowModal={setDataForReportGeneration}
          {...dataForReportGeneration}
        />
      )}

      <Row gutter={20}>
        <Col span={11} className={styles.inputDataContainer}>
          {/* Input data section */}
          <SectionHeaderComponent title="Input Data" />
          {noOfCalves && feedingsPerDay ? null : (
            <NoOfCalvesFeedingsPerDayForm
              control={noOfCalvesFeedingsPerDayFormControl}
              errors={noOfCalvesFeedingsPerDayFormErrors}
            />
          )}
          {Array.isArray(inputFields) && inputFields.length > 0
            ? inputFields.map((item, index) => {
                /* Variable to store label of the item */
                const fieldLabel = `${
                  typeof item.description.label === 'function'
                    ? item.description.label(watch('labSolidsOrRefractometer'))
                    : item.description.label
                } ${(item.description.unit as string) || ''}`;
                // const to store index of labSolidsOrRefractometerSelection as breakpoint to set the margin left of the fields.
                const startIndexOfPasteurizeMilkFields = inputFields.findIndex(
                  (ele) => ele.key === 'labSolidsOrRefractometerSelection',
                );
                return (
                  <div key={item.key}>
                    {item.isSelectOption ? (
                      <FormItem
                        label={fieldLabel}
                        isRequired
                        displayMode="column"
                        customStyle={{
                          marginBottom: 10,
                          marginLeft: item.key === 'labSolidsOrRefractometer' ? 25 : 10,
                        }}
                      >
                        <RadioGroup
                          name={item.key}
                          rhfControllerProps={{ control }}
                          options={item.isSelectOption}
                          displayMode="row"
                        />
                      </FormItem>
                    ) : (
                      <>
                        <FormItem
                          label={fieldLabel}
                          isRequired
                          displayMode="column"
                          customStyle={{
                            marginBottom: 10,
                            marginLeft:
                              inputFields.findIndex(
                                (ele) => ele.key === 'labSolidsOrRefractometerSelection',
                              ) <= index
                                ? 25
                                : 0,
                          }}
                          errorText={errors[item.key]?.message}
                        >
                          <InputNumberComponent
                            placeholder="Please enter the value"
                            name={item.key as string}
                            rhfControllerProps={{ control }}
                            inputNumberProps={{
                              min: 0,
                            }}
                          />
                        </FormItem>
                        {index === startIndexOfPasteurizeMilkFields - 2 ? (
                          <div style={{ fontSize: 14, fontWeight: 'bold' }}>PASTEURIZED MILK</div>
                        ) : null}
                      </>
                    )}
                  </div>
                );
              })
            : null}
        </Col>

        <Col span={11} className={styles.calculatedDataContainer}>
          {/* Calculated data section */}
          <SectionHeaderComponent title="FINAL RATION per feeding" />

          <CalculatedFieldsListViewComponent
            listData={primaryCalculatedFields}
            calculatedFields={pmrbNoWaterFields}
            unit={watchUnit}
            noOfCalves={noOfCalves}
          />
          {/* Calculated data with unit per calf per feeding section */}
          <SectionHeaderComponent
            title="PER CALF PER FEEDING"
            showToggleBtn
            isSwitchOn={showPerCalfPerFdgData}
            updateSwitchState={setShowPerCalfPerFdgData}
          />

          {showPerCalfPerFdgData ? (
            <CalculatedFieldsListViewComponent
              listData={perCalfPerFdgDataFields}
              calculatedFields={pmrbNoWaterFields}
              noOfCalves={noOfCalves}
              unit={watch('unit')}
            />
          ) : null}

          {/* Calculated data with unit Per calf daily section */}
          <SectionHeaderComponent
            title="PER CALF DAILY"
            showToggleBtn
            isSwitchOn={showPerCalfDailyData}
            updateSwitchState={setShowPerCalfDailyData}
          />

          {showPerCalfDailyData ? (
            <>
              <div style={{ fontStyle: 'italic', marginTop: 7 }}>
                *Daily values assumes same ration fed each feed
              </div>
              <CalculatedFieldsListViewComponent
                listData={perCalfDailyDataFields}
                calculatedFields={pmrbNoWaterFields}
                noOfCalves={noOfCalves}
                unit={watch('unit')}
              />
            </>
          ) : null}
        </Col>
      </Row>

      <div className={styles.btnContainer}>
        <Button
          type="primary"
          className={`${styles.saveBtn} buttonColorRed`}
          htmlType="submit"
          onClick={() => {
            if (noOfCalves && feedingsPerDay) {
              /* eslint-disable @typescript-eslint/no-floating-promises */
              handleSubmit(onSubmit)();
            } else {
              noOfCalvesFeedingsPerDayFormHandleSubmit(() => {
                handleSubmit(onSubmit)();
              })();
              /* eslint-enable */
            }
          }}
        >
          Save to History
        </Button>
        <Button
          type="primary"
          className={`${styles.saveBtn} buttonColorRed`}
          htmlType="submit"
          onClick={() => {
            // const to store all input values entered by user
            const userInputValues: PmrbNoWaterFormFieldsWithoutReferenceNameType &
              LocalForageFarmIdType = {
              targetPasteurizedMilkBalancerLbsPerCalfDaily: watchTargetPasteurizedMilkBalancerLbsPerCalfDaily,
              labSolidsOrRefractometerSelection: watchLabSolidsOrRefractometerSelection,
              targetVolPerFdgQtPerCalf: watchTargetVolPerFdgQtPerCalf,
              pasteurizedMilkBalancerCP: watchPasteurizedMilkBalancerCP,
              pasteurizedMilkBalancerFat: watchPasteurizedMilkBalancerFat,
              pasteurizedMilkCP: watchPasteurizedMilkCP,
              pasteurizedMilkFat: watchPasteurizedMilkFat,
              labSolidsOrRefractometer: watchLabSolidsOrRefractometer,
              unit: watchUnit,
              farmId: selectedFarmId as string,
            };
            saveCalculatorInputs(
              JSON.stringify(userInputValues),
              Enum_Calculator_Enum.PmrbNoWater,
            ).catch((err) => logger(err));
          }}
        >
          Save Locally
        </Button>

        <Button
          type="default"
          className={styles.resetBtn}
          onClick={() => {
            removeCalculatorInputs(Enum_Calculator_Enum.PmrbNoWater)
              .then(() => {
                reset(pmrbNoWaterFormDefaultValues);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          Reset
        </Button>

        <Button
          type="primary"
          className={`${styles.saveBtn} buttonColorRed`}
          onClick={() => {
            if (noOfCalves && feedingsPerDay) {
              /* eslint-disable @typescript-eslint/no-floating-promises */
              handleSubmit((formData) => {
                /* Destructuring form data */
                const { referenceName, ...restData } = formData;

                setDataForReportGeneration({
                  inputsData: {
                    calculator: Enum_Calculator_Enum.PmrbNoWater,
                    farmName,
                    noOfCalves,
                    feedingsPerDay,
                    ...restData,
                  },
                  calculatedFieldsValue: pmrbNoWaterFields,
                  fieldValues: pmrbNoWaterValues,
                });
              })();
            } else {
              noOfCalvesFeedingsPerDayFormHandleSubmit((formData) => {
                handleSubmit((submittedData) => {
                  setDataForReportGeneration({
                    inputsData: {
                      calculator: Enum_Calculator_Enum.PmrbNoWater,
                      farmName,
                      ...formData,
                      ...submittedData,
                    },
                    calculatedFieldsValue: pmrbNoWaterFields,
                    fieldValues: pmrbNoWaterValues,
                  });
                })();
              })();
            }
            /* eslint-enable */
          }}
        >
          Generate Report
        </Button>
      </div>
    </>
  );
};

export default PmrbNoWaterForm;
