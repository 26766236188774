import saveAs from 'file-saver';

// type definition for download file from url
type DownloadFileFromUrlPropType = {
  // prop definition for url
  url: string;
  // prop definition for file name
  fileName: string;
};

const downloadFileFromUrl = async ({
  url,
  fileName,
}: DownloadFileFromUrlPropType): Promise<void> => {
  // const to fetch the file from the provided URL
  const response = await fetch(url);
  // const to convert the fetched file into a Blob object
  const blob = await response.blob();
  // save the Blob object as a file with the specified file name
  saveAs(blob, fileName);
};
export default downloadFileFromUrl;
