import { Divider } from 'antd';
import React from 'react';

// to display research provided by information in colostrum management result screen
const ResearchProviderInfoBar = (): JSX.Element => (
  <>
    <Divider style={{ marginBottom: 10 }} />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        fontSize: 13,
        fontStyle: 'italic',
        gap: 10,
      }}
    >
      <span style={{ fontWeight: 600 }}>Research Provided by:</span>
      <span style={{ fontWeight: 400 }}>
        Dr. Donald Sockett DVM PhD Diplomate ACVIM-LA University of Wisconsin-Madison
      </span>
    </div>
  </>
);

export default ResearchProviderInfoBar;
