// function use to generate temporary unique alphabet string
const generateTemporaryId = () => {
  // const to store consisting alphabets in small and capital letter
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';

  // variable use to store string
  let temporaryId = '';

  // loop to select a new character in each iteration
  for (let i = 0; i < 10; i++) {
    temporaryId += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return temporaryId;
};

export default generateTemporaryId;
