import {
  PooledColostrumReplacerInputsType,
  PooledColostrumReplacerOutputType,
} from '@ecp32/calculations';
import {
  CalculatorCalculatedFieldType,
  ColostrumReplacerCalculatorTypePropertyType,
  MeasurementsSystemSubType,
} from '../../utils/types';

// type use to store keys of inputs
export type InputKeysType = keyof PooledColostrumReplacerInputsType;

// type use to store outputs keys
type OutputKeysType = keyof PooledColostrumReplacerOutputType;

// type for pooled calculator form
export type PooledFormType = PooledColostrumReplacerInputsType & {
  referenceName: string | undefined;
};

// type for pooled calculator calculation data which is used to pass state for pooled calculation history screen modal
export type CalculationDataForPooledCalculatorType = {
  // type definition for reference name
  referenceName: string;
  // type definition for calculation input data
  calculationInputData: PooledColostrumReplacerInputsType;
  // type definition for calculation output data
  calculationOutputData: PooledColostrumReplacerOutputType;
};

// type for type property of calculator
type PooledCalculatorSectionKeyPropertyType = 'perCalf' | 'perNCalves';

// type to define pooled calculator all fields object type
export type PooledColostrumReplacerDataType = {
  // type for key which is use to store fields key
  key: InputKeysType | OutputKeysType;
  // type for property which determine type of field this can be input , calculated , fixed
  type: ColostrumReplacerCalculatorTypePropertyType;
  /* Calculator calculated field type */
  calculatedType?: CalculatorCalculatedFieldType;
  /* latest description of each calculator field */
  description: {
    /* Label of the field */
    label: string | ((type: string) => string);
    /* latest unit of the field */
    unit?: string;
    // unit system to be called for unit
    unitKey?: MeasurementsSystemSubType;
  };
  // type for property which is use to get define section for pooled calculator fields
  sectionKey?: PooledCalculatorSectionKeyPropertyType;
};

// const use to store pooled calculator fields object
export const pooledColostrumReplacerAllFields: PooledColostrumReplacerDataType[] = [
  {
    key: 'unit',
    type: 'fixed',
    description: {
      label: 'Unit',
    },
  },
  {
    key: 'desiredMaxAmtForFirstFeeding',
    type: 'fixed',
    description: {
      label: (unit: string) => `Desired Max ${unit} first feeding`,
      unitKey: 'defaultVolumeUnit',
    },
  },
  {
    key: 'desiredMaxAmtForSecondFeeding',
    type: 'fixed',
    description: {
      label: (unit: string) => `Desired Max ${unit} second feeding`,
      unitKey: 'defaultVolumeUnit',
    },
  },
  {
    key: 'igGInColostrumReplacer',
    type: 'fixed',
    description: {
      label: '% IgG in Colostrum Replacer',
    },
  },
  {
    key: 'noOfFirstFeedingCalves',
    type: 'input',
    description: {
      label: 'Number of first feeding calves',
    },
  },
  {
    key: 'noOfSecondFeedingCalves',
    type: 'input',
    description: {
      label: 'Number of second feeding calves',
    },
  },
  {
    key: 'birthBodyWt',
    type: 'input',
    description: {
      label: (unit: string) => `Average birth body weight in ${unit}`,
      unitKey: 'defaultCalfWtUnit',
    },
  },
  {
    key: 'brixReading',
    type: 'input',
    description: {
      label: 'Brix reading (%)',
    },
  },
  {
    key: 'brixUsedInPrevCalculation',
    type: 'input',
    description: {
      label: 'Previous Brix reading for second feeding calves (%)',
    },
  },

  {
    key: 'minAmtNeededInFirstFeeding',
    type: 'calculated',
    description: {
      label: (unit: string) => `Minimum ${unit} needed in first feeding`,
      unitKey: 'defaultVolumeUnit',
    },
    sectionKey: 'perCalf',
  },
  {
    key: 'crPowderNeededInFirstFeeding',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit} of Colostrum Replacer powder needed for first feeding`,
      unitKey: 'defaultMassUnit',
    },
    sectionKey: 'perCalf',
  },
  {
    key: 'amtNeededInSecondFeeding',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit} needed in second feeding`,
      unitKey: 'defaultVolumeUnit',
    },
    sectionKey: 'perCalf',
  },
  {
    key: 'amtOfCrUsedInSecondFeeding',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit} of Colostrum Replacer powder needed in second feeding`,
      unitKey: 'defaultMassUnit',
    },
    sectionKey: 'perCalf',
  },
  {
    key: 'totalAmtNeeded',
    type: 'calculated',
    description: {
      label: (unit: string) => `Total ${unit} needed`,
      unitKey: 'defaultVolumeUnit',
    },
    sectionKey: 'perNCalves',
  },
  {
    key: 'unitForTotalNeeded',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit === 'Qts' ? 'Total Gallons needed' : ''}`,
    },
    sectionKey: 'perNCalves',
  },
  {
    key: 'amtNeededBeforeCrAdd',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit} needed before Colostrum Replacer addition`,
      unitKey: 'defaultVolumeUnit',
    },
    sectionKey: 'perNCalves',
  },
  {
    key: 'unitBeforeCrAddition',
    type: 'calculated',
    description: {
      label: (unit: string) =>
        `${unit === 'Qts' ? 'Gallons needed before Colostrum Replacer addition' : ''}`,
    },
    sectionKey: 'perNCalves',
  },
  {
    key: 'amtOfCrPowderNeeded',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit} of Colostrum Replacer powder needed`,
      unitKey: 'defaultMassUnit',
    },
    sectionKey: 'perNCalves',
  },
  {
    key: 'unitForCrPowderNeeded',
    type: 'calculated',
    description: {
      label: (unit: string) => `${unit === 'Grams' ? 'KG Colostrum Replacer powder needed' : ''}`,
      unitKey: 'defaultMassUnit',
    },
    sectionKey: 'perNCalves',
  },
];

export default null;
