import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { Button, Table, message } from 'antd';
import dayjs from 'dayjs';
import Column from 'antd/lib/table/Column';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { byCalfColostrumReplacerOutput } from '@ecp32/calculations';
import {
  AllCalvesHistoryQuery,
  AllCalvesHistoryQueryVariables,
  Calf,
  Enum_Measurement_System_Enum,
  GenerateColostrumReplacerReportMutation,
  GenerateColostrumReplacerReportMutationVariables,
} from '../../graphql/graphql-types';
import {
  CalculationDataForByCalfCalculatorType,
  byCalfColostrumReplacerAllFields,
} from '../../calculator/colostrum-replacer-calculator/ByCalf';
import ColumnsFilterComponent from '../../components/ColumnsFilterComponent';
// import InputComponent from '../../components/InputComponent';
// import FormItem from '../../components/FormItem';
// import measurementSystemOptions from '../../utils/colostrum-replacer/measurementSystemOptions';
import { ColostrumReplacerInputAndOutputForReportType } from '../../utils/types/colostrum-replacer-types/ColostrumReplacerInputAndOutputForReportType';
import downloadFileFromUrl from '../../utils/colostrum-replacer/downloadFileFromUrl';
import { logger } from '../../utils/helpers';
import getColostrumReplacerAllFieldsWithLabels from '../../utils/colostrum-replacer/getColostrumReplacerAllFieldsWithLabels';
import ByCalfHistoryViewModalComponent from '../../components/colostrum-replacer/ByCalfHistoryViewModalComponent';
import ColostrumReplacerReportNameModal from './ColostrumReplacerReportNameModal';

const allCalvesHistoryQuery = loader('../../graphql/queries/allCalvesHistoryQuery.graphql');
const generateColostrumReplacerReportMutation = loader(
  '../../graphql/mutations/generateColostrumReplacerReportMutation.graphql',
);
// const deleteCalfByIdMutation = loader('../../graphql/mutations/deleteCalfByIdMutation.graphql');

// prop type definition for by calf calculator history screen
type ByCalfCalculatorHistoryPropType = {
  // prop type for farmId
  farmId: string;
};

const ByCalfCalculatorHistoryScreen = ({
  farmId,
}: ByCalfCalculatorHistoryPropType): JSX.Element => {
  // state to set selected calculation id for showing loading indicator after clicking on delete btn for that particular row
  // const [calculationIdToDltAndShowLoading, setCalculationIdToDltAndShowLoading] = useState<
  //   string | undefined
  // >(undefined);

  // state to store selected calf data when user selects checkbox for particular calf id
  const [selectedCalfIdData, setSelectedCalfIdData] = useState<AllCalvesHistoryQuery['calf']>([]);

  // state to show enter report name modal when user clicks on Generate report for selected calves button
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState<boolean>(false);

  // state to show loading indicator when user clicks on done button after entering report name for multiple calf report generation
  const [isDoneBtnLoading, setIsDoneBtnLoading] = useState<boolean>(false);

  // query to fetch all calves history data
  const { data, loading, error } = useQuery<AllCalvesHistoryQuery, AllCalvesHistoryQueryVariables>(
    allCalvesHistoryQuery,
    { variables: { farmId }, fetchPolicy: 'network-only' },
  );

  // mutation to generate colostrum replacer by calf calculator report
  const [generateColostrumReport] = useMutation<
    GenerateColostrumReplacerReportMutation,
    GenerateColostrumReplacerReportMutationVariables
  >(generateColostrumReplacerReportMutation);

  // const to store selected calf data
  const selectedCalfData = data && data.calf;

  // const to store selected farm data
  const selectedFarmData = data && data.farm_by_pk;

  // state to store by calf calculation input data, output data and reference name for particular calf id when user clicks on view button
  const [
    calculationDataForByCalfCalculator,
    setCalculationDataForByCalfCalculator,
  ] = useState<CalculationDataForByCalfCalculatorType | null>(null);

  // mutation to delete calculation
  // const [deleteCalf] = useMutation<DeleteCalfByIdMutation, DeleteCalfByIdMutationVariables>(
  //   deleteCalfByIdMutation,
  // );

  // const to store all calves history data
  const ByCalfCalculationData = data && data.calf;

  // const to store getIgGInColostrumReplacer value
  const igGInColostrumReplacerVal =
    data && data.getIgGInColostrumReplacer
      ? data.getIgGInColostrumReplacer.igGInColostrumReplacer
      : 21;

  // function use to calculate input and output values for by-calf calculator, based on provided input values
  const getByCalfCalculationInputAndOutputResults = (
    // argument for calf data
    calfData: Pick<
      Calf,
      | 'id'
      | 'birth_body_wt'
      | 'colostrum_first_fdg_brix_reading'
      | 'colostrum_second_fdg_brix_reading'
      | 'system_id'
      | 'created_at'
      | 'col_rep_measurement_system_id'
      | 'colostrum_max_first_fdg'
      | 'colostrum_max_second_fdg'
    >,
  ) => {
    // const to check weather it is first feeding or second feeding, if 0 then it is first feeding
    const isFirstFeeding = calfData.colostrum_second_fdg_brix_reading === 0;

    // const to store input data which is used to display in modal
    const calculationInputData = {
      birthBodyWt: calfData.birth_body_wt as number,
      unit: calfData.col_rep_measurement_system_id,
      desiredMaxAmtForFirstFeeding: calfData.colostrum_max_first_fdg as number,
      desiredMaxAmtForSecondFeeding: calfData.colostrum_max_second_fdg as number,
      // if first feeding, then first feeding brix reading is brix reading otherwise in second feeding brix reading is for second feeding brix reading
      brixReading: isFirstFeeding
        ? (calfData.colostrum_first_fdg_brix_reading as number)
        : (calfData.colostrum_second_fdg_brix_reading as number),

      // if first feeding then pass value 0 for calculation otherwise in second feeding it is first feeding brix reading
      brixUsedInPrevCalculation: isFirstFeeding
        ? 0
        : (calfData.colostrum_first_fdg_brix_reading as number),
      igGInColostrumReplacer: igGInColostrumReplacerVal,
    };

    // calling by calf colostrum replacer output function to get calculations results
    const byCalfCalculationResultOutput = byCalfColostrumReplacerOutput(calculationInputData);

    return {
      calculationInputData,
      byCalfCalculationResultOutput,
      isFirstFeeding,
    };
  };

  // If any error occurs while fetching farm options data
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  return (
    <>
      {calculationDataForByCalfCalculator && selectedFarmData ? (
        <ByCalfHistoryViewModalComponent
          calculationDataForByCalfCalculator={calculationDataForByCalfCalculator}
          setCalculationDataForByCalfCalculator={setCalculationDataForByCalfCalculator}
          selectedFarmName={selectedFarmData.name}
        />
      ) : null}
      {isGenerateReportModalOpen ? (
        <ColostrumReplacerReportNameModal
          onOk={async (e, formData, reset) => {
            setIsDoneBtnLoading(true);

            try {
              if (selectedCalfData && selectedFarmData) {
                // const to store calculated input values and output values data for multiple selected calf id's
                const calculationInputValuesAndOutputValuesForAllCalves: ColostrumReplacerInputAndOutputForReportType[] = selectedCalfIdData.map(
                  (item) => {
                    // calling getByCalfCalculationInputAndOutputResults function to get input and output values
                    const calculatedResultData = getByCalfCalculationInputAndOutputResults(item);

                    return {
                      inputs_fields: {
                        ...calculatedResultData.calculationInputData,
                        unit: item.col_rep_measurement_system_id,
                        igGInColostrumReplacer: igGInColostrumReplacerVal,
                        typeOfFeeding: calculatedResultData.isFirstFeeding
                          ? 'firstFeeding'
                          : 'secondFeeding',
                        calfId: item.id,
                      },
                      calculated_fields: calculatedResultData.byCalfCalculationResultOutput,
                    };
                  },
                );

                // const to store all input and output label fields
                const allFieldsWithLabel = getColostrumReplacerAllFieldsWithLabels({
                  allFieldsLabels: byCalfColostrumReplacerAllFields,
                });

                // const to store generate report mutation variable data which is used to passed for generate report mutation
                const generateReportMutationVariable = {
                  calculator: 'by_calf',
                  farm_name: selectedFarmData && selectedFarmData.name,
                  report_name: formData.name,
                  calculator_fields_labels: {
                    [Enum_Measurement_System_Enum.UsCustomary]:
                      allFieldsWithLabel.colostrumReplacerAllFieldsWithLabelsForUsCustomary,
                    [Enum_Measurement_System_Enum.Metric]:
                      allFieldsWithLabel.colostrumReplacerAllFieldsWithLabelsForMetric,
                  },
                  calculation_data: calculationInputValuesAndOutputValuesForAllCalves,
                };

                // mutation call to generate by calf report for multiple selected calf id
                const generateReport = await generateColostrumReport({
                  variables: { data: JSON.stringify(generateReportMutationVariable) },
                });

                if (
                  !generateReport ||
                  !generateReport.data ||
                  !generateReport.data.generateColostrumReplacerReport ||
                  !generateReport.data.generateColostrumReplacerReport.fileName ||
                  !generateReport.data.generateColostrumReplacerReport.report_url
                ) {
                  throw new Error('Report URL not found');
                }

                await downloadFileFromUrl({
                  url: generateReport.data.generateColostrumReplacerReport.report_url,
                  fileName: generateReport.data.generateColostrumReplacerReport.fileName,
                });

                setIsDoneBtnLoading(false);
                setIsGenerateReportModalOpen(false);
                setSelectedCalfIdData([]);

                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.success('Report downloaded successfully');
              }
            } catch (err) {
              const errObj = err as ApolloError;
              logger(errObj);
              setIsDoneBtnLoading(false);
              setIsGenerateReportModalOpen(false);
              setSelectedCalfIdData([]);
              reset();
            }
          }}
          isDoneBtnLoading={isDoneBtnLoading}
          isGenerateReportModalOpen={isGenerateReportModalOpen}
          onCancel={() => {
            setIsGenerateReportModalOpen(false);
          }}
        />
      ) : null}
      <Table<AllCalvesHistoryQuery['calf'][0]>
        dataSource={ByCalfCalculationData}
        loading={loading}
        style={{ width: '70%' }}
        size="small"
        bordered
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selectedCalfIdData.map((item) => item.id),
          onChange: (selectedRowKey, selectedRow) => {
            if (setSelectedCalfIdData) {
              setSelectedCalfIdData(selectedRow);
            }
          },
        }}
      >
        <Column<AllCalvesHistoryQuery['calf'][0]>
          key="calfId"
          title="Calf ID"
          align="center"
          dataIndex="id"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsFilterComponent
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="name"
              type="search"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? 'var(--color--lightBlue)' : undefined }} />
          )}
          onFilter={(value, record) =>
            record.id ? record.id.toLowerCase().includes(value.toString().toLowerCase()) : false
          }
        />
        <Column
          key="created_at"
          title="Date"
          align="center"
          dataIndex="created_at"
          render={(text: string) => dayjs(text).format('DD MMMM, YYYY') || '-'}
        />
        <Column<AllCalvesHistoryQuery['calf'][0]>
          key="actions"
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(text, record) => (
            <>
              <Button
                size="small"
                icon={<EyeOutlined />}
                className="buttonColorRed"
                onClick={() => {
                  if (selectedCalfData) {
                    // calling getByCalfCalculationInputAndOutputResults function to get input and output values
                    const calculatedResultData = getByCalfCalculationInputAndOutputResults(record);

                    setCalculationDataForByCalfCalculator({
                      referenceName: record.id,
                      selectedCalculationsInputData: calculatedResultData.calculationInputData,
                      selectedCalculationsOutputData:
                        calculatedResultData.byCalfCalculationResultOutput,
                    });
                  }
                }}
              >
                View
              </Button>

              {/* Delete Button is intentionally commented */}
              {/* <Popconfirm
                title="Delete calculation. Are you sure?"
                onConfirm={() => {
                  setCalculationIdToDltAndShowLoading(record.id);

                  // mutation to delete calf
                  deleteCalf({
                    variables: {
                      calfId: record.id,
                    },
                    // updating cached data to reflect updated data after deletion at the same time
                    update: (cache, { data: deletedData }) => {
                      cache.modify({
                        fields: {
                          calf(existingRefs: Array<Reference>, { readField }) {
                            // const to store id of the deleted to data to filter from query data after deletion
                            const idToFilter = deletedData?.update_calf?.returning[0].id;
                            if (idToFilter) {
                              return existingRefs.filter(
                                (ref) => idToFilter !== readField('id', ref),
                              );
                            }
                            return existingRefs;
                          },
                        },
                      });
                    },
                  })
                    .then(() => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      message.success('Calf has been deleted successfully');
                      setCalculationIdToDltAndShowLoading(undefined);
                    })
                    .catch((err: ApolloError) => {
                      logger(err);
                      setCalculationIdToDltAndShowLoading(undefined);
                    });
                }}
                icon={<WarningFilled style={{ color: 'red' }} />}
                cancelText="No"
                okText="Yes"
              >
                <Button
                  loading={calculationIdToDltAndShowLoading === record.id}
                  icon={<DeleteOutlined />}
                >
                  Delete
                </Button>
              </Popconfirm> */}
            </>
          )}
        />
      </Table>
      <Button
        className="buttonColorRed"
        style={{ marginTop: 20 }}
        disabled={selectedCalfIdData.length < 1}
        onClick={() => {
          setIsGenerateReportModalOpen(true);
        }}
      >
        Generate report for selected calves
      </Button>
    </>
  );
};

export default ByCalfCalculatorHistoryScreen;
