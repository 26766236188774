import { Button, Col, Modal, Row, message } from 'antd';
import React, { useState } from 'react';
import { Enum_Calculator_Enum } from '@ecp32/calculations/src/graphql';
import { ApolloError, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import SectionHeaderComponent from '../SectionHeaderComponent';
import getPooledFieldsBasedOnProperty from '../../utils/colostrum-replacer/getPooledFieldsBasedOnProperty';
import SettingsFixedFieldsComponent from './SettingsFixedFieldsComponent';
import renderLabelBasedOnUnit from '../../utils/colostrum-replacer/renderLabelBasedOnUnit';
import styles from '../../forms/calculator/CalculatorForms.module.scss';
import CalculatedOutputFieldsComponent from './CalculatedOutputFieldsComponent';
import {
  CalculationDataForPooledCalculatorType,
  pooledColostrumReplacerAllFields,
} from '../../calculator/colostrum-replacer-calculator/Pooled';
import {
  Enum_Measurement_System_Enum,
  GenerateColostrumReplacerReportMutation,
  GenerateColostrumReplacerReportMutationVariables,
} from '../../graphql/graphql-types';
import measurementSystemOptions from '../../utils/colostrum-replacer/measurementSystemOptions';
import getColostrumReplacerAllFieldsWithLabels from '../../utils/colostrum-replacer/getColostrumReplacerAllFieldsWithLabels';
import downloadFileFromUrl from '../../utils/colostrum-replacer/downloadFileFromUrl';
import { logger } from '../../utils/helpers';
import ColostrumReplacerReportNameModal from '../../screens/colostrum-replacer/ColostrumReplacerReportNameModal';
import ResearchProviderInfoBar from './ResearchProviderInfoBar';

const generateColostrumReplacerReportMutation = loader(
  '../../graphql/mutations/generateColostrumReplacerReportMutation.graphql',
);

// prop type definition for pooled calculator history view modal component
type PooledCalculatorHistoryViewModalComponentPropType = {
  // type definition for calculation data for pooled calculator
  calculationDataForPooledCalculator: CalculationDataForPooledCalculatorType;
  // type definition to set state for calculation data for pooled calculator
  setCalculationDataForPooledCalculator: React.Dispatch<
    React.SetStateAction<CalculationDataForPooledCalculatorType | null>
  >;
  // type definition for selected farm name
  selectedFarmName: string;
};

// calling PooledFieldsBasedOnProperty to get input fields, output fields, fixed fields, per n calves fields for pooled calculator
const {
  pooledInputFields,
  pooledPerCalfOutputFields,
  pooledPerNCalvesOutputFields,
  pooledFixedFields,
} = getPooledFieldsBasedOnProperty();

const PooledHistoryViewModalComponent = ({
  calculationDataForPooledCalculator,
  setCalculationDataForPooledCalculator,
  selectedFarmName,
}: PooledCalculatorHistoryViewModalComponentPropType): JSX.Element => {
  // destructing calculation data for pooled calculator state
  const {
    calculationInputData,
    calculationOutputData,
    referenceName,
  } = calculationDataForPooledCalculator;

  // state to show enter report name modal
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState<boolean>(false);

  // state to show loading indicator when user clicks on done button after entering report name
  const [isDoneBtnLoading, setIsDoneBtnLoading] = useState<boolean>(false);

  // mutation to generate colostrum replacer pooled calculator report
  const [generateColostrumReport] = useMutation<
    GenerateColostrumReplacerReportMutation,
    GenerateColostrumReplacerReportMutationVariables
  >(generateColostrumReplacerReportMutation);

  // const to store unit which is used for calculation
  const calculationDataUnit = calculationInputData.unit;

  // const use to store unit field label and value object
  const unitFieldObject = measurementSystemOptions.find(
    (item) => item.value === calculationDataUnit,
  );

  return (
    <>
      {isGenerateReportModalOpen ? (
        <ColostrumReplacerReportNameModal
          onOk={async (e, formData, reset) => {
            setIsDoneBtnLoading(true);

            try {
              if (unitFieldObject) {
                // const to store all input and output label fields
                const allFieldsWithLabel = getColostrumReplacerAllFieldsWithLabels({
                  allFieldsLabels: pooledColostrumReplacerAllFields,
                });

                // const to store generate mutation variable data which is used to passed for generate report mutation
                const generateReportMutationVariable = {
                  calculator: Enum_Calculator_Enum.ColRepPooled,
                  farm_name: selectedFarmName,
                  report_name: formData.name,
                  calculator_fields_labels: {
                    [Enum_Measurement_System_Enum.UsCustomary]:
                      allFieldsWithLabel.colostrumReplacerAllFieldsWithLabelsForUsCustomary,
                    [Enum_Measurement_System_Enum.Metric]:
                      allFieldsWithLabel.colostrumReplacerAllFieldsWithLabelsForMetric,
                  },
                  calculation_data: [
                    {
                      inputs_fields: calculationInputData,
                      calculated_fields: {
                        ...calculationOutputData,
                        // rounding off the value from frontend for label Lbs/KG of Colostrum Replacer powder needed and total amount needed for pooled calculator from frontend
                        amtOfCrPowderNeeded:
                          Math.round(calculationOutputData.amtOfCrPowderNeeded * 100) / 100,
                        totalAmtNeeded:
                          Math.round(calculationOutputData.totalAmtNeeded * 100) / 100,
                      },
                    },
                  ],
                };

                // mutation call to generate by calf report for selected calf id
                const generateReport = await generateColostrumReport({
                  variables: { data: JSON.stringify(generateReportMutationVariable) },
                });

                if (
                  !generateReport ||
                  !generateReport.data ||
                  !generateReport.data.generateColostrumReplacerReport ||
                  !generateReport.data.generateColostrumReplacerReport.fileName ||
                  !generateReport.data.generateColostrumReplacerReport.report_url
                ) {
                  throw new Error('Report URL not found');
                }

                await downloadFileFromUrl({
                  url: generateReport.data.generateColostrumReplacerReport.report_url,
                  fileName: generateReport.data.generateColostrumReplacerReport.fileName,
                });

                setIsDoneBtnLoading(false);
                setIsGenerateReportModalOpen(false);

                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.success('Report downloaded successfully');
              }
            } catch (err) {
              const errObj = err as ApolloError;
              logger(errObj);
              setIsDoneBtnLoading(false);
              setIsGenerateReportModalOpen(false);
              reset();
            }
          }}
          isDoneBtnLoading={isDoneBtnLoading}
          isGenerateReportModalOpen={isGenerateReportModalOpen}
          onCancel={() => {
            setIsGenerateReportModalOpen(false);
          }}
        />
      ) : null}

      <Modal
        visible={!!calculationInputData}
        footer={null}
        width={1200}
        onCancel={() => {
          setCalculationDataForPooledCalculator(null);
        }}
      >
        <Row style={{ columnGap: 100 }}>
          <Col span={10}>
            <SectionHeaderComponent title="Inputs" />
            <Row gutter={50} style={{ marginTop: 10, marginBottom: 20 }}>
              <Col span={13}>Reference Name</Col>
              <Col>{referenceName}</Col>
            </Row>

            {/* render fixed fields */}
            <SettingsFixedFieldsComponent
              fieldData={pooledFixedFields.filter((item) => item.key !== 'igGInColostrumReplacer')}
              settingsFieldValue={{
                unit: calculationDataUnit,
                desiredMaxAmtForFirstFeeding: calculationInputData.desiredMaxAmtForFirstFeeding,
                desiredMaxAmtForSecondFeeding: calculationInputData.desiredMaxAmtForSecondFeeding,
              }}
              customStyle={{ marginTop: 10, marginBottom: 20 }}
            />

            {/* render input fields */}
            {pooledInputFields.map((ele) => (
              <Row gutter={50} style={{ marginTop: 20, marginBottom: 20 }} key={ele.key}>
                <Col span={13}>
                  {renderLabelBasedOnUnit(
                    ele.description.label,
                    calculationDataUnit,
                    ele.description.unitKey,
                  )}
                </Col>
                <Col span={8}>
                  {(calculationInputData &&
                    calculationInputData[ele.key as keyof typeof calculationInputData]) ||
                    '-'}
                </Col>
              </Row>
            ))}
          </Col>

          {/* render output fields */}
          <Col span={10}>
            {/* render per calf fields */}
            <SectionHeaderComponent title="Per Calf" />
            <CalculatedOutputFieldsComponent
              fieldData={pooledPerCalfOutputFields}
              outputValues={calculationOutputData}
              selectedUnitValue={calculationDataUnit}
            />

            {/* render per n calves fields */}
            <SectionHeaderComponent
              title={`Per ${
                calculationInputData.noOfFirstFeedingCalves +
                calculationInputData.noOfSecondFeedingCalves
              } Calves`}
            />
            <CalculatedOutputFieldsComponent
              fieldData={pooledPerNCalvesOutputFields}
              outputValues={calculationOutputData}
              selectedUnitValue={calculationDataUnit}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'grid', margin: 'auto' }}>
            <Button
              className={`${styles.saveBtn} buttonColorRed`}
              onClick={() => {
                setIsGenerateReportModalOpen(true);
              }}
              style={{ marginTop: 10 }}
            >
              Generate Report
            </Button>
          </Col>
        </Row>

        <ResearchProviderInfoBar />
      </Modal>
    </>
  );
};

export default PooledHistoryViewModalComponent;
