import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import { DeepMap, FieldError, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  PmAndWaterFormFieldsType,
  PmAndWaterInputFieldsType,
  pmAndWaterValues,
  calculatedPMAndWaterFields,
  PowderFeedingCountSelectionType,
  PmAndWaterDataFieldsType,
  PmAndWaterFormFieldsWithoutReferenceNameType,
} from '../../calculator/PMAndWater';
import {
  noOfCalvesFeedingsPerDayFormValidationSchema,
  numberGreaterThanZeroSchema,
} from '../../utils/globals';
import {
  CalculatorFormsPropsType,
  LocalForageFarmIdType,
  NoOfCalvesFeedingsPerDayFormType,
} from '../../utils/types';
import {
  filterArrayBasedOnType,
  logger,
  saveCalculatorInputs,
  readCalculatorInputs,
  removeCalculatorInputs,
} from '../../utils/helpers';
import SectionHeaderComponent from '../../components/SectionHeaderComponent';
import FormItem from '../../components/FormItem';
import RadioGroup from '../../components/RadioGroup';
import InputNumberComponent from '../../components/InputNumberComponent';
import CalculatedFieldsListViewComponent from './CalculatedFieldsListViewComponent';
import ReferenceNameModalComponent from './ReferenceNameModalComponent';
import { Enum_Calculator_Enum } from '../../graphql/graphql-types';
import styles from './CalculatorForms.module.scss';
import WarningText from '../../components/WarningText';
import NoOfCalvesFeedingsPerDayForm from '../NoOfCalvesFeedingPerDayForm';
import ReportNameModalComponent, { ReportGenerationDataType } from './ReportNameModalComponent';

// validation schema for powderTwoCP and powderTwoFat and lbOfSecondPowderPerHdPerDay
const feedTwoPowderSchema = yup.number().when('powderFeedingCount', {
  is: (powderFeedingCount: PowderFeedingCountSelectionType) => {
    if (powderFeedingCount === 1) {
      return false;
    }
    return true;
  },
  then: numberGreaterThanZeroSchema,
  otherwise: yup.number().notRequired().nullable(),
});

/* PM & water Validation schema */
const validationSchema = yup.object().shape({
  totalVolAvailableGallonsPerFdg: numberGreaterThanZeroSchema,
  labSolidsOrRefractometerSelection: numberGreaterThanZeroSchema,
  targetSolidsFinalSolution: numberGreaterThanZeroSchema,
  targetVolPerFdgQtPerCalf: numberGreaterThanZeroSchema,
  lbOfSecondPowderPerHdPerDay: feedTwoPowderSchema,
  pasteurizedMilkCP: numberGreaterThanZeroSchema,
  pasteurizedMilkFat: numberGreaterThanZeroSchema,
  powderOneCP: numberGreaterThanZeroSchema,
  powderOneFat: numberGreaterThanZeroSchema,
  powderTwoCP: feedTwoPowderSchema,
  powderTwoFat: feedTwoPowderSchema,
});

// array to store input key linked to feed 2 types of powder.
const inputKeyLinkedToFeedingTwoPowder = [
  'lbOfSecondPowderPerHdPerDay',
  'powderTwoCP',
  'powderTwoFat',
  'powderOneCP',
  'powderOneFat',
];

// array to store input key linked to feeding on powder
const inputKeyLinkedToFeedingOnePowder = ['powderOneFat', 'powderOneCP', 'powderType'];

/* PM & water default values */
const pmWaterFormDefaultValues: PmAndWaterFormFieldsType = {
  totalVolAvailableGallonsPerFdg: null,
  labSolidsOrRefractometerSelection: null,
  targetSolidsFinalSolution: null,
  targetVolPerFdgQtPerCalf: null,
  lbOfSecondPowderPerHdPerDay: null,
  pasteurizedMilkCP: null,
  pasteurizedMilkFat: null,
  powderOneCP: null,
  powderOneFat: null,
  powderTwoCP: null,
  powderTwoFat: null,
  labSolidsOrRefractometer: 'labSolids',
  referenceName: '',
  powderFeedingCount: 2,
  powderType: 'milkReplacer',
};

/* React functional component */
const PmWaterForm: React.FC<CalculatorFormsPropsType> = ({
  selectedFarmId,
  setSelectedFarmId,
  noOfCalves,
  feedingsPerDay,
  initialInputs,
  setCalculationData,
  farmName,
}) => {
  /* State to decide whether to show data with unit per calf per feeding or not */
  const [showPerCalfPerFdgData, setShowPerCalfPerFdgData] = useState<boolean>(false);

  /* State to decide whether to show data with unit per calf daily or not */
  const [showPerCalfDailyData, setShowPerCalfDailyData] = useState<boolean>(false);

  /* State used to store data that will be used for report generation */
  const [
    dataForReportGeneration,
    setDataForReportGeneration,
  ] = useState<ReportGenerationDataType | null>(null);

  /* State used to decide whether to show submit modal(modal with reference name field) or not */
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);

  // variable to store form initial values when user want to update existing calculator from history screen
  let pmWaterFormInitialValues: Record<string, string | number | undefined> = {};

  // variable to store form initial value of noOFCalves and  feedingsPerDay when user want to update existing calculator from history screen
  let noOfCalvesFeedingsPerDayInitialValue = {};

  // logic to get initialValues when form is called from history screen
  /* eslint-disable  @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
  if ((noOfCalves === undefined || feedingsPerDay === undefined) && initialInputs) {
    Object.keys(initialInputs).forEach((input) => {
      if (input !== 'noOfCalves' && input !== 'feedingsPerDay') {
        let inputValue;
        if (initialInputs[input] === null || input === 'powderFeedingCount') {
          inputValue = initialInputs[input];
        } else {
          inputValue = initialInputs[input];
        }
        pmWaterFormInitialValues = {
          ...pmWaterFormInitialValues,
          [input]: inputValue,
        };
      } else {
        noOfCalvesFeedingsPerDayInitialValue = {
          ...noOfCalvesFeedingsPerDayInitialValue,
          [input]: initialInputs[input],
        } as PmAndWaterFormFieldsType;
      }
    });
  } else {
    noOfCalvesFeedingsPerDayInitialValue = {
      noOfCalves: undefined,
      feedingsPerDay: 2,
    };
  }
  /* eslint-enable */

  /* Variable to store updated cmr form initial values if needed */
  let updatedKeysPmWaterFormInitialValues = pmWaterFormInitialValues;

  /**
   * This code block is written because some keys in web and mobile are spelled differently but
   * associated with the same field which is making issues while assigning default values to the form in edit mode
   * Hence here key is renamed to the correct one
   */
  if (Object.keys(pmWaterFormInitialValues).length > 0) {
    /* Destructuring Pm water form initial values */
    const {
      targetSolidsFinalSolution,
      targetSolidsOfFinalSolution,
      milkFedPerCalfPerFdg,
      milkFedPerCalPerFdg,
      totalBalancerNeededPerFdg,
      totalBalancerNeeded,
      totalVolAvailableGallonsPerFdg,
      totalVolAvailableGallons,
      lbOfSecondPowderPerHdPerDay,
      lbOfBalancerPerCalfPerDay,
      ...rest
    } = pmWaterFormInitialValues;

    /* Updating initial values */
    updatedKeysPmWaterFormInitialValues = {
      ...rest,
      targetSolidsFinalSolution: targetSolidsFinalSolution || targetSolidsOfFinalSolution,
      milkFedPerCalfPerFdg: milkFedPerCalfPerFdg || milkFedPerCalPerFdg,
      totalBalancerNeededPerFdg: totalBalancerNeededPerFdg || totalBalancerNeeded,
      totalVolAvailableGallonsPerFdg: totalVolAvailableGallonsPerFdg || totalVolAvailableGallons,
      lbOfSecondPowderPerHdPerDay: lbOfSecondPowderPerHdPerDay || lbOfBalancerPerCalfPerDay,
    };
  }

  /* useForm declaration for  form containing only noOfCalves and feedingsPerDay fields */
  const {
    control: noOfCalvesFeedingsPerDayFormControl,
    formState: { errors: noOfCalvesFeedingsPerDayFormErrors },
    watch: noOfCalvesFeedingsPerDayFormWatch,
    handleSubmit: noOfCalvesFeedingsPerDayFormHandleSubmit,
  } = useForm<NoOfCalvesFeedingsPerDayFormType>({
    defaultValues:
      noOfCalves && feedingsPerDay
        ? {
            noOfCalves: undefined,
            feedingsPerDay: 2,
          }
        : noOfCalvesFeedingsPerDayInitialValue,
    resolver: yupResolver(noOfCalvesFeedingsPerDayFormValidationSchema),
  });

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    reset,
    setValue,
    setError,
  } = useForm<PmAndWaterFormFieldsType>({
    defaultValues:
      noOfCalves && feedingsPerDay ? pmWaterFormDefaultValues : updatedKeysPmWaterFormInitialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  // useEffect to fetch data stored in localforage
  useEffect(() => {
    // if condition because we don't want localforage value as initial values of form when user open form from history screen
    if (noOfCalves && feedingsPerDay) {
      // call to localforage readCalculatorInputs function to get data
      readCalculatorInputs(Enum_Calculator_Enum.PmWater)
        .then((res) => {
          if (res) {
            /* Local forage calculator inputs with farm id */
            const { farmId, ...restData } = res as PmAndWaterFormFieldsType & LocalForageFarmIdType;

            if (farmId === selectedFarmId) {
              reset(restData);
            } else {
              reset(pmWaterFormDefaultValues);
            }
          }
        })
        .catch((err) => {
          logger(err);
        });
    }
  }, [feedingsPerDay, noOfCalves, reset, selectedFarmId, setValue]);

  /* Variable to store input fields for Pm & water form it exclude fields related to 2nd powder */
  const inputFields = pmAndWaterValues.filter(
    (item) =>
      item.type === 'input' &&
      !['noOfCalves', 'feedingsPerDay'].includes(item.key) &&
      !inputKeyLinkedToFeedingTwoPowder.includes(item.key) &&
      !inputKeyLinkedToFeedingOnePowder.includes(item.key),
  ) as PmAndWaterInputFieldsType[];

  /* Variable to store primary calculated fields using form input values */
  const primaryCalculatedFields = filterArrayBasedOnType(
    pmAndWaterValues,
    'primary',
  ) as PmAndWaterDataFieldsType[];

  /* Variable to store secondary calculated fields using form input values */
  const secondaryCalculatedFields = filterArrayBasedOnType(
    pmAndWaterValues,
    'secondary',
  ) as PmAndWaterDataFieldsType[];

  // const to store output fields whose unit is per calf per feeding or has no unit
  const perCalfPerFdgDataFields = secondaryCalculatedFields.filter(
    (item) =>
      (typeof item.description.unit === 'string' &&
        item.description.unit.includes('per calf per feeding')) ||
      item.description.unit === undefined,
  );

  // const to store output fields whose unit is per calf daily
  const perCalfDailyDataFields = secondaryCalculatedFields.filter(
    (item) =>
      typeof item.description.unit === 'string' && item.description.unit.includes('per calf daily'),
  );

  // array to store input items linked feed 2 powder, ie it will store items whose key matches with inputKeyLinkedToFeedingTwoPowder array
  const arrayInputRelatedToFeedingTwoPowder = pmAndWaterValues.filter((item) =>
    inputKeyLinkedToFeedingTwoPowder.includes(item.key),
  ) as PmAndWaterInputFieldsType[];

  // array to store input items linked to feed only one powder
  const arrayInputRelatedToFeedingOnePowder = pmAndWaterValues.filter((item) =>
    inputKeyLinkedToFeedingOnePowder.includes(item.key),
  ) as PmAndWaterInputFieldsType[];

  // const to decide the label of powderOneCp and powderOneFat based on powderType
  const feedOnePowderLabel =
    watch('powderType') === 'milkBalancer' && watch('powderFeedingCount') === 1
      ? 'Balancer'
      : 'Milk Replacer';

  /* Function to submit form data */
  const onSubmit = () => {
    setShowSubmitModal(true);
  };

  /* Watch for totalVolAvailableGallonsPerFdg fields */
  const watchTotalVolAvailableGallonsPerFdg = watch('totalVolAvailableGallonsPerFdg');
  /* Watch for labSolidsOrRefractometerSelection fields */
  const watchLabSolidsOrRefractometerSelection = watch('labSolidsOrRefractometerSelection');
  /* Watch for targetSolidsFinalSolution fields */
  const watchTargetSolidsFinalSolution = watch('targetSolidsFinalSolution');
  /* Watch for targetVolPerFdgQtPerCalf fields */
  const watchTargetVolPerFdgQtPerCalf = watch('targetVolPerFdgQtPerCalf');
  /* Watch for lbOfSecondPowderPerHdPerDay fields */
  const watchLbOfSecondPowderPerHdPerDay = watch('lbOfSecondPowderPerHdPerDay');
  /* Watch for pasteurizedMilkCP fields */
  const watchPasteurizedMilkCP = watch('pasteurizedMilkCP');
  /* Watch for pasteurizedMilkFat fields */
  const watchPasteurizedMilkFat = watch('pasteurizedMilkFat');
  /* Watch for powderOneCP fields */
  const watchPowderOneCP = watch('powderOneCP');
  /* Watch for powderOneFat fields */
  const watchPowderOneFat = watch('powderOneFat');
  /* Watch for powderTwoCP fields */
  const watchPowderTwoCP = watch('powderTwoCP');
  /* Watch for powderTwoFat fields */
  const watchPowderTwoFat = watch('powderTwoFat');

  /* Calculated fields for PM & water type */
  const pmWaterFields = calculatedPMAndWaterFields({
    noOfCalves: noOfCalves || noOfCalvesFeedingsPerDayFormWatch('noOfCalves'),
    feedingsPerDay: feedingsPerDay || noOfCalvesFeedingsPerDayFormWatch('feedingsPerDay'),
    totalVolAvailableGallonsPerFdg:
      watchTotalVolAvailableGallonsPerFdg && watchTotalVolAvailableGallonsPerFdg > 0
        ? watchTotalVolAvailableGallonsPerFdg
        : undefined,
    labSolidsOrRefractometerSelection:
      watchLabSolidsOrRefractometerSelection && watchLabSolidsOrRefractometerSelection > 0
        ? watchLabSolidsOrRefractometerSelection
        : undefined,
    targetSolidsFinalSolution:
      watchTargetSolidsFinalSolution && watchTargetSolidsFinalSolution > 0
        ? watchTargetSolidsFinalSolution
        : undefined,
    targetVolPerFdgQtPerCalf:
      watchTargetVolPerFdgQtPerCalf && watchTargetVolPerFdgQtPerCalf > 0
        ? watchTargetVolPerFdgQtPerCalf
        : undefined,

    lbOfSecondPowderPerHdPerDay:
      watchLbOfSecondPowderPerHdPerDay && watchLbOfSecondPowderPerHdPerDay > 0
        ? watchLbOfSecondPowderPerHdPerDay
        : 0,
    pasteurizedMilkCP:
      watchPasteurizedMilkCP && watchPasteurizedMilkCP > 0 ? watchPasteurizedMilkCP : undefined,
    pasteurizedMilkFat:
      watchPasteurizedMilkFat && watchPasteurizedMilkFat > 0 ? watchPasteurizedMilkFat : undefined,
    powderOneCP: watchPowderOneCP && watchPowderOneCP > 0 ? watchPowderOneCP : undefined,
    powderOneFat: watchPowderOneFat && watchPowderOneFat > 0 ? watchPowderOneFat : undefined,
    powderTwoCP: watchPowderTwoCP && watchPowderTwoCP > 0 ? watchPowderTwoCP : 0,
    powderTwoFat: watchPowderTwoFat && watchPowderTwoFat > 0 ? watchPowderTwoFat : 0,
    labSolidsOrRefractometer: (watch('labSolidsOrRefractometer') as unknown) as number,
    powderFeedingCount: (watch('powderFeedingCount') as unknown) as number,
    powderType: (watch('powderType') as unknown) as number,
  });

  // function which will render form input field
  const renderInputFieldsUI = (
    label: string,
    key: PmAndWaterInputFieldsType['key'],
    error: DeepMap<PmAndWaterFormFieldsType, FieldError>,
    pasteurizedMilkStartIndex: number | null,
    currentIndex: number | null,
  ) => (
    <FormItem
      label={label}
      isRequired
      customStyle={{
        marginBottom: 10,
        marginLeft:
          pasteurizedMilkStartIndex && currentIndex && currentIndex > pasteurizedMilkStartIndex
            ? 25
            : 0,
      }}
      displayMode="column"
      errorText={error[key]?.message}
    >
      <InputNumberComponent
        name={key}
        placeholder="Please enter the value"
        rhfControllerProps={{ control }}
        inputNumberProps={{
          min: 0,
        }}
      />
    </FormItem>
  );

  // destructuring from pmWaterFields
  const { totalMilkNeededPerFdg, waterNeededPerFdg, totalBalancerNeededPerFdg } = pmWaterFields;

  // const to check whether powder one or powder two or total gallon of water is negative or not
  const isCalculatedValueNegative =
    (totalMilkNeededPerFdg && totalMilkNeededPerFdg < 0) ||
    (waterNeededPerFdg && waterNeededPerFdg < 0) ||
    (totalBalancerNeededPerFdg && totalBalancerNeededPerFdg < 0);

  return (
    <>
      {/* Reference name modal to add name to the calculation */}
      <ReferenceNameModalComponent
        showModal={showSubmitModal}
        setShowModal={setShowSubmitModal}
        control={control}
        selectedFarmId={selectedFarmId}
        setSelectedFarmId={setSelectedFarmId}
        getValues={getValues}
        type={Enum_Calculator_Enum.PmWater}
        noOfCalves={noOfCalves || noOfCalvesFeedingsPerDayFormWatch('noOfCalves')}
        feedingsPerDay={feedingsPerDay || noOfCalvesFeedingsPerDayFormWatch('feedingsPerDay')}
        setValue={setValue}
        errorText={errors.referenceName?.message}
        setError={setError}
        setCalculationData={setCalculationData}
      />

      {dataForReportGeneration && (
        <ReportNameModalComponent
          showModal={!!dataForReportGeneration}
          setShowModal={setDataForReportGeneration}
          {...dataForReportGeneration}
        />
      )}

      <Row gutter={20}>
        <Col span={11} className={styles.inputDataContainer}>
          {/* Input data section */}
          <SectionHeaderComponent title="Input Data" />
          {noOfCalves && feedingsPerDay ? null : (
            <NoOfCalvesFeedingsPerDayForm
              control={noOfCalvesFeedingsPerDayFormControl}
              errors={noOfCalvesFeedingsPerDayFormErrors}
            />
          )}
          {Array.isArray(inputFields) && inputFields.length > 0
            ? inputFields.map((item, index) => {
                /* Variable to store label of the item */
                const fieldLabel = `${
                  typeof item.description.label === 'function'
                    ? item.description.label(watch('labSolidsOrRefractometer'))
                    : item.description.label
                } ${(item.description.unit as string) || ''}`;
                /**
                 *  const to store index of targetSolidsFinalSolution as a breakpoint
                 * so that we set marginLeft of all fields after this index
                 */
                const startIndexOfPasteurizeMilkFields = inputFields.findIndex(
                  (ele) => ele.key === 'targetSolidsFinalSolution',
                );
                return (
                  <div key={item.key}>
                    {item.isSelectOption ? (
                      <FormItem
                        label={fieldLabel}
                        isRequired
                        displayMode="column"
                        customStyle={{
                          marginLeft: item.key === 'labSolidsOrRefractometer' ? 25 : 0,
                          marginBottom: 10,
                        }}
                      >
                        <RadioGroup
                          name={item.key}
                          rhfControllerProps={{ control }}
                          options={item.isSelectOption}
                          onChange={() => {
                            if (item.key === 'powderFeedingCount') {
                              setValue('lbOfSecondPowderPerHdPerDay', undefined);
                              setValue('powderTwoCP', undefined);
                              setValue('powderTwoFat', undefined);
                              setValue('powderOneFat', undefined);
                              setValue('powderOneCP', undefined);
                              setValue('powderType', 'milkReplacer');
                            }
                          }}
                        />
                      </FormItem>
                    ) : (
                      renderInputFieldsUI(
                        fieldLabel,
                        item.key,
                        errors,
                        startIndexOfPasteurizeMilkFields,
                        index,
                      )
                    )}
                    {index === startIndexOfPasteurizeMilkFields ? (
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          marginBottom: 5,
                        }}
                      >
                        PASTEURIZED MILK
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}

          {watch('powderFeedingCount') === 2
            ? arrayInputRelatedToFeedingTwoPowder.map((item) => {
                const fieldLabel = `${
                  typeof item.description.label === 'function'
                    ? item.description.label(feedOnePowderLabel)
                    : item.description.label
                } ${(item.description.unit as string) || ''}`;
                return (
                  <div key={item.key}>
                    {renderInputFieldsUI(fieldLabel, item.key, errors, null, null)}
                  </div>
                );
              })
            : arrayInputRelatedToFeedingOnePowder.map((item) => {
                const fieldLabel =
                  typeof item.description.label === 'function'
                    ? item.description.label(feedOnePowderLabel)
                    : item.description.label;
                return (
                  <div key={item.key}>
                    {item.key === 'powderType' && item.isSelectOption ? (
                      <FormItem
                        displayMode="column"
                        label={fieldLabel}
                        isRequired
                        customStyle={{ marginBottom: 10 }}
                      >
                        <RadioGroup
                          name={item.key}
                          rhfControllerProps={{ control }}
                          options={item.isSelectOption}
                          onChange={() => {
                            setValue('powderOneCP', undefined);
                            setValue('powderOneFat', undefined);
                          }}
                        />
                      </FormItem>
                    ) : (
                      renderInputFieldsUI(fieldLabel, item.key, errors, null, null)
                    )}
                  </div>
                );
              })}
        </Col>

        <Col span={11} className={styles.calculatedDataContainer}>
          {/* Calculated data section */}
          <SectionHeaderComponent title="FINAL RATION per feeding" />

          <CalculatedFieldsListViewComponent
            listData={primaryCalculatedFields}
            calculatedFields={pmWaterFields}
            powderType={watch('powderType')}
            powderFeedingCount={watch('powderFeedingCount')}
          />

          {isCalculatedValueNegative ? (
            <WarningText customStyles={{ marginTop: 10 }}>
              Calculated data is negative. Please recheck your input values or use another
              calculator.
            </WarningText>
          ) : null}
          {/* Calculated data with unit per calf per feeding section */}
          <SectionHeaderComponent
            title="PER CALF PER FEEDING"
            showToggleBtn
            isSwitchOn={showPerCalfPerFdgData}
            updateSwitchState={setShowPerCalfPerFdgData}
          />

          {showPerCalfPerFdgData ? (
            <CalculatedFieldsListViewComponent
              listData={perCalfPerFdgDataFields}
              calculatedFields={pmWaterFields}
              powderType={watch('powderType')}
              powderFeedingCount={watch('powderFeedingCount')}
            />
          ) : null}

          {/* Calculated data with unit Per calf daily section */}
          <SectionHeaderComponent
            title="PER CALF DAILY"
            showToggleBtn
            isSwitchOn={showPerCalfDailyData}
            updateSwitchState={setShowPerCalfDailyData}
          />

          {showPerCalfDailyData ? (
            <CalculatedFieldsListViewComponent
              listData={perCalfDailyDataFields}
              calculatedFields={pmWaterFields}
              powderType={watch('powderType')}
              powderFeedingCount={watch('powderFeedingCount')}
            />
          ) : null}
        </Col>
      </Row>

      <div className={styles.btnContainer}>
        <Button
          type="primary"
          className={`${styles.saveBtn} buttonColorRed`}
          htmlType="submit"
          disabled={!!isCalculatedValueNegative}
          onClick={
            () => {
              /* eslint-disable @typescript-eslint/no-floating-promises */
              if (noOfCalves && feedingsPerDay) {
                handleSubmit(onSubmit)();
              } else {
                noOfCalvesFeedingsPerDayFormHandleSubmit(() => {
                  handleSubmit(onSubmit)();
                })();
              }
            }
            /* eslint-enable */
          }
        >
          Save to History
        </Button>
        <Button
          type="primary"
          className={`${styles.saveBtn} buttonColorRed`}
          htmlType="submit"
          onClick={() => {
            // const to store all input values entered by user
            const userInputValues: PmAndWaterFormFieldsWithoutReferenceNameType &
              LocalForageFarmIdType = {
              totalVolAvailableGallonsPerFdg: watchTotalVolAvailableGallonsPerFdg,
              labSolidsOrRefractometerSelection: watchLabSolidsOrRefractometerSelection,
              targetSolidsFinalSolution: watchTargetSolidsFinalSolution,
              targetVolPerFdgQtPerCalf: watchTargetVolPerFdgQtPerCalf,
              lbOfSecondPowderPerHdPerDay: watchLbOfSecondPowderPerHdPerDay,
              pasteurizedMilkCP: watchPasteurizedMilkCP,
              pasteurizedMilkFat: watchPasteurizedMilkFat,
              powderOneCP: watchPowderOneCP,
              powderOneFat: watchPowderOneFat,
              powderTwoCP: watchPowderTwoCP,
              powderTwoFat: watchPowderTwoFat,
              labSolidsOrRefractometer: watch('labSolidsOrRefractometer'),
              powderFeedingCount: watch('powderFeedingCount'),
              powderType: watch('powderType'),
              farmId: selectedFarmId as string,
            };

            saveCalculatorInputs(
              JSON.stringify(userInputValues),
              Enum_Calculator_Enum.PmWater,
            ).catch((err) => logger(err));
          }}
        >
          Save Locally
        </Button>

        <Button
          type="default"
          className={styles.resetBtn}
          onClick={() => {
            removeCalculatorInputs(Enum_Calculator_Enum.PmWater)
              .then(() => {
                reset(pmWaterFormDefaultValues);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          Reset
        </Button>

        <Button
          type="primary"
          className={`${styles.saveBtn} buttonColorRed`}
          disabled={!!isCalculatedValueNegative}
          onClick={() => {
            /* eslint-disable @typescript-eslint/no-floating-promises */
            if (noOfCalves && feedingsPerDay) {
              handleSubmit((formData) => {
                /* Destructuring form data */
                const { referenceName, ...restData } = formData;

                setDataForReportGeneration({
                  inputsData: {
                    calculator: Enum_Calculator_Enum.PmWater,
                    farmName,
                    noOfCalves,
                    feedingsPerDay,
                    ...restData,
                  },
                  calculatedFieldsValue: pmWaterFields,
                  fieldValues: pmAndWaterValues,
                });
              })();
            } else {
              noOfCalvesFeedingsPerDayFormHandleSubmit((formData) => {
                handleSubmit((submittedData) => {
                  setDataForReportGeneration({
                    inputsData: {
                      calculator: Enum_Calculator_Enum.PmWater,
                      farmName,
                      ...formData,
                      ...submittedData,
                    },
                    calculatedFieldsValue: pmWaterFields,
                    fieldValues: pmAndWaterValues,
                  });
                })();
              })();
            }
            /* eslint-enable */
          }}
        >
          Generate Report
        </Button>
      </div>
    </>
  );
};

export default PmWaterForm;
