import { Row, Col } from 'antd';
import React from 'react';
import renderLabelBasedOnUnit from '../../utils/colostrum-replacer/renderLabelBasedOnUnit';
import { PooledColostrumReplacerDataType } from '../../calculator/colostrum-replacer-calculator/Pooled';
import measurementSystemOptions from '../../utils/colostrum-replacer/measurementSystemOptions';
import { ByCalfColostrumReplacerDataType } from '../../calculator/colostrum-replacer-calculator/ByCalf';
import { SettingFieldsType } from '../../utils/types';

// type use to store props of component
type SettingsFixedFieldsComponentPropTypes = {
  // fieldData use to store array of settings fields which are fixed fields in UI
  fieldData: Array<PooledColostrumReplacerDataType> | Array<ByCalfColostrumReplacerDataType>;
  // passing settings value of for selected farm/calf
  settingsFieldValue: SettingFieldsType;
  // prop definition for custom style
  customStyle?: React.CSSProperties;
};

// react functional component
const SettingsFixedFieldsComponent = ({
  fieldData,
  settingsFieldValue,
  customStyle,
}: SettingsFixedFieldsComponentPropTypes): JSX.Element => {
  // const use to store unit field label and value object
  const unitFieldObject = measurementSystemOptions.find(
    (item) => item.value === settingsFieldValue.unit,
  );

  return (
    // function to render settings field value which will be shown as hardcoded value
    <>
      {fieldData.map((ele: PooledColostrumReplacerDataType | ByCalfColostrumReplacerDataType) => (
        <Row gutter={50} style={customStyle || { marginTop: 10, marginBottom: 8 }} key={ele.key}>
          <Col span={13}>
            {renderLabelBasedOnUnit(
              ele.description.label,
              settingsFieldValue.unit,
              ele.description.unitKey,
            )}
          </Col>
          <Col span={8}>
            {ele.key === 'unit' && unitFieldObject
              ? unitFieldObject.label
              : (settingsFieldValue &&
                  settingsFieldValue[ele.key as keyof typeof settingsFieldValue]) ||
                '-'}
          </Col>
        </Row>
      ))}
    </>
  );
};
export default SettingsFixedFieldsComponent;

SettingsFixedFieldsComponent.defaultProps = {
  customStyle: undefined,
};
