import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import FormItem from '../components/FormItem';
import InputNumberComponent from '../components/InputNumberComponent';
import RadioGroup from '../components/RadioGroup';
import { NoOfCalvesFeedingsPerDayFormType } from '../utils/types';

// NoOfCalvesFeedingsPerDayForm prop type
type NoOfCalvesFeedingsPerDayFormPropType = {
  // useForm control prop type
  control: Control<NoOfCalvesFeedingsPerDayFormType>;
  // useForm error form type
  errors: DeepMap<NoOfCalvesFeedingsPerDayFormType, FieldError>;
};

// react functional component
const NoOfCalvesFeedingsPerDayForm: React.FC<NoOfCalvesFeedingsPerDayFormPropType> = ({
  control,
  errors,
}) => (
  <>
    <FormItem
      label="Number of calves:"
      errorText={errors.noOfCalves?.message}
      isRequired
      displayMode="column"
    >
      <InputNumberComponent
        placeholder="Please enter a value"
        name="noOfCalves"
        customStyles={{ marginBottom: 10 }}
        rhfControllerProps={{ control }}
        inputNumberProps={{
          min: 0,
        }}
        // onBlur={inputsOnBlurFunc}
      />
    </FormItem>
    <FormItem label="Feedings per day" isRequired displayMode="column">
      <RadioGroup
        name="feedingsPerDay"
        rhfControllerProps={{ control }}
        style={{ marginBottom: 10 }}
        options={[
          { label: 1, value: 1 },
          { label: 2, value: 2 },
          { label: 3, value: 3 },
          { label: 4, value: 4 },
        ]}
        // onChange={() => {
        //   inputsOnBlurFunc();
        // }}
        displayMode="row"
      />
    </FormItem>
  </>
);

export default NoOfCalvesFeedingsPerDayForm;
