import React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './ModuleLayout.module.scss';
import { CaddieModuleType } from '../utils/types';

// type for moduleLayout component props
type ModuleLayoutPropsType = {
  // prop use to pass name of the module
  moduleName: CaddieModuleType;
  // prop use to pass title of the module
  moduleTitle: string;
};

const ModuleLayout: React.FC<ModuleLayoutPropsType> = ({ moduleName, moduleTitle }) => {
  // location is object coming from useLocation hooks of react router dom
  const location = useLocation();

  // navigate variable contain useNavigate hooks
  const navigate = useNavigate();
  // When user click on the title of the app then this function gets invoke
  const handleOnClickColostrumReplacer = () => {
    navigate(`/${moduleName}/calculators`);
  };

  return (
    <>
      <div className={styles.headerContainer}>
        {/* Title of the header bar */}
        <div
          onClick={handleOnClickColostrumReplacer}
          onKeyPress={handleOnClickColostrumReplacer}
          role="button"
          tabIndex={0}
          className={styles.headerTitle}
        >
          {moduleTitle}
        </div>

        {/* Here is the menus which is display in the header */}
        <div className={styles.menuContainer}>
          <div className={styles.menuList}>
            <Link
              className={
                location.pathname.includes('calculators') ? styles.linkActive : styles.link
              }
              to={`/${moduleName}/calculators`}
            >
              Calculators
            </Link>
            <Link
              className={location.pathname.includes('history') ? styles.linkActive : styles.link}
              to={`/${moduleName}/history`}
            >
              History
            </Link>
            {moduleName === 'colostrum-management' ? (
              <Link
                className={location.pathname.includes('settings') ? styles.linkActive : styles.link}
                // Todo reconfirm for this settings route
                to={`/${moduleName}/settings/all`}
              >
                Settings
              </Link>
            ) : null}
          </div>
        </div>
      </div>
      <div style={{ padding: 20 }}>
        <Outlet />
      </div>
    </>
  );
};

export default ModuleLayout;
