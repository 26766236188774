import React, { useState } from 'react';
import { Button, Card, Col, Modal, Row, Tag, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation } from '@apollo/client';
import {
  ModuleRequestAccessEnum,
  ModuleRequestAccessMutation,
  ModuleRequestAccessMutationVariables,
} from '../../graphql/graphql-types';
import { logger } from '../../utils/helpers';
import useAuth from '../../utils/auth/useAuth';
import Colostrum_replacer from '../../assests/images/Colostrum_replacer.jpg';
import Calf_milk_replacer from '../../assests/images/Calf_milk_replacer.jpg';
import Growth_Heifer_Model from '../../assests/images/Growth_Heifer_Model.jpg';

const moduleRequestAccessMutation = loader(
  '../../graphql/mutations/moduleRequestAccessMutation.graphql',
);

// extracting meta from card
const { Meta } = Card;

// color used for descriptive text on screen and in modal
const textColor = '#000000b3';

// react functional component
const CaddieHomeScreen: React.FC = () => {
  // extracting navigate from useNavigation
  const navigate = useNavigate();

  // destructuring from useAuth hook
  const { user } = useAuth();

  // mutation for request access to specific module
  const [moduleRequestAccess] = useMutation<
    ModuleRequestAccessMutation,
    ModuleRequestAccessMutationVariables
  >(moduleRequestAccessMutation);

  // state used to store the title of the card, to handle visibility and content of the modal
  const [selectedCardTitle, setSelectedCardTitle] = useState<string | null>(null);

  // state to store selected calculator name and show loading indicator when user clicks on request access button
  const [requestAccessForSelectedCalculator, setRequestAccessForSelectedCalculator] = useState<
    'Calf Milk Ration' | 'Colostrum Management' | null
  >(null);

  // const to check whether user is Admin or not
  const isUserAdmin = user && user.isAdmin;

  // const to store boolean value to check weather user having access for calf milk ration or not
  const hasCalfMilkReplacerRequestAccess =
    isUserAdmin ||
    (user &&
      user.hasAccessToModules &&
      Array.isArray(user.hasAccessToModules) &&
      user.hasAccessToModules.length > 0 &&
      user.hasAccessToModules.includes('calf-milk-ration'));

  // const to store boolean value to check weather user having access for colostrum management or not
  const hasColostrumReplacerRequestAccess =
    isUserAdmin ||
    (user &&
      user.hasAccessToModules &&
      Array.isArray(user.hasAccessToModules) &&
      user.hasAccessToModules.length > 0 &&
      user.hasAccessToModules.includes('colostrum-management'));

  // const to store different module data to display in card
  const cardData = [
    {
      title: 'Calf Milk Ration',
      imgUrl: Calf_milk_replacer,
      btnLabel: hasCalfMilkReplacerRequestAccess ? 'Get Started' : 'Request Access',
      btnClick: () => {
        if (!hasCalfMilkReplacerRequestAccess) {
          setRequestAccessForSelectedCalculator('Calf Milk Ration');

          // mutation call for get request access for selected module
          moduleRequestAccess({ variables: { module: ModuleRequestAccessEnum.CalfMilkRation } })
            .then(() => {
              setRequestAccessForSelectedCalculator(null);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success(
                'Your access request has been sent to the admins. The approval status will be conveyed by email',
              );
            })
            .catch((err: ApolloError) => {
              setRequestAccessForSelectedCalculator(null);
              logger(
                err,
                'Your request could not be completed successfully. Please contact the administrator for further assistance.',
              );
            });
        } else {
          navigate('/calf-milk-ration/calculators');
        }
      },
      cardDescription:
        'Designed to help improve the accuracy of your calf feeding program. The app provides a variety of calculators to improve the provision of milk rations, including feeding milk replacer, and balancer with whole milk.',
      modalDescription: () => (
        <>
          <p>
            Designed to help improve the accuracy of your calf feeding program. The app provides a
            variety of calculators to improve the provision of milk rations, including feeding milk
            replacer, and balancer with whole milk. The Calf Milk Ration App will calculate the
            pounds needed and gallons of water and or solution per feeding for you.
          </p>
          <p style={{ fontWeight: 'bold' }}>A simple amount of information is needed: </p>
          <ul>
            <li>Number of calves</li>
            <li>Feedings per day</li>
            <li>Type of milk you are feeding</li>
            <li>Target solids of final solution or target volume</li>
          </ul>
          <i>
            For more information, please contact your local Calf and Heifer salesperson for support.
          </i>
        </>
      ),
    },
    {
      title: 'Colostrum Management',
      imgUrl: Colostrum_replacer,
      btnLabel: hasColostrumReplacerRequestAccess ? 'Get Started' : 'Request Access',
      btnClick: () => {
        if (!hasColostrumReplacerRequestAccess) {
          setRequestAccessForSelectedCalculator('Colostrum Management');

          // mutation call for get request access for selected module
          moduleRequestAccess({ variables: { module: ModuleRequestAccessEnum.ColostrumReplacer } })
            .then(() => {
              setRequestAccessForSelectedCalculator(null);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success(
                'Your access request has been sent to the admins. The approval status will be conveyed by email',
              );
            })
            .catch((err: ApolloError) => {
              setRequestAccessForSelectedCalculator(null);
              logger(
                err,
                'Your request could not be completed successfully. Please contact the administrator for further assistance.',
              );
            });
        } else {
          navigate('/colostrum-management/calculators');
        }
      },
      cardDescription:
        'Allows maternity and calf care professional to feed more accurate and targeted volumes of colostrum, reducing the risk of overfeeding colostrum, while still providing adequate IgG to allow for transfer of passive immunity.',
      modalDescription: () => (
        <>
          <div>
            <Row style={{ fontStyle: 'italic' }}>
              <Col style={{ fontSize: 14, fontWeight: 600 }}>Research Provided by:</Col>
              <Col style={{ marginLeft: 10, marginBottom: 20, fontSize: 14 }}>
                Dr. Donald Sockett DVM PhD Diplomate ACVIM-LA University of Wisconsin-Madison
              </Col>
            </Row>
          </div>
          <p>
            Allows maternity and calf care professional to feed more accurate and targeted volumes
            of colostrum, reducing the risk of overfeeding colostrum, while still providing adequate
            IgG to allow for transfer of passive immunity. We’ve partnered with Dr. Sockett,
            UW-Veterinary Diagnostic Lab, to develop a model that allows for maximum benefit to the
            calf using Brix to properly manage colostrum volume and supply.
          </p>
          <i>
            For more information, please contact your local Calf and Heifer salesperson for support.
          </i>
        </>
      ),
    },
    {
      title: 'Heifer Growth Model',
      imgUrl: Growth_Heifer_Model,
      btnLabel: 'Get Started',
      btnClick: () => {},
      cardDescription:
        'Monitoring heifer growth is one strategy that can help you achieve success in raising heifers, and it is one of the most important ways to gauge the performance of a heifer program.',
      modalDescription: () => (
        <>
          <p>
            Monitoring heifer growth is one strategy that can help you achieve success in raising
            heifers, and it is one of the most important ways to gauge the performance of a heifer
            program. It is also one of the best ways to determine if rearing costs are in line with
            the results you are achieving and to ensure that future production is not impaired by
            poor management or nutrition.
          </p>
          <i>
            For more information, please contact your local Calf and Heifer salesperson for support.
          </i>
        </>
      ),
    },
  ];

  // const used to store selected card data to be displayed in the modal
  const selectedCardData = cardData.find((item) => item.title === selectedCardTitle);

  return (
    <div style={{ padding: '50px 80px 0px 80px' }}>
      <h3
        style={{
          fontWeight: 'normal',
          color: textColor,
          marginBottom: 20,
        }}
      >
        Home to a variety of tools all designed to help track the health, nutrition, and management
        of calves and heifers.
      </h3>
      <div
        style={{
          display: 'flex',
          paddingTop: 10,
          paddingBottom: 10,
          justifyContent: 'space-between',
        }}
      >
        {cardData.map((item, index) => {
          // const used to store boolean value of whether the card is of module growth heifer or not
          const isHeiferGrowthModel = item.title === 'Heifer Growth Model';

          return (
            <Card
              key={index.toString()}
              style={{ width: '27vw' }}
              cover={<img alt="example" src={item.imgUrl} height={280} />}
              actions={[
                <div
                  style={{
                    paddingLeft: 24,
                    paddingRight: 24,
                    display: 'flex',
                    justifyContent: isHeiferGrowthModel ? 'center' : 'space-between',
                  }}
                >
                  {!isHeiferGrowthModel ? (
                    <Button
                      loading={
                        item.title === requestAccessForSelectedCalculator && !selectedCardData
                      }
                      className="buttonColorRed"
                      style={{ width: 150 }}
                      onClick={item.btnClick}
                    >
                      {item.btnLabel}
                    </Button>
                  ) : null}
                  <Button
                    style={{ width: 150 }}
                    onClick={() => {
                      setSelectedCardTitle(item.title);
                    }}
                  >
                    More Information
                  </Button>
                </div>,
              ]}
            >
              <Meta
                style={{ height: 140 }}
                title={[
                  <div style={{ display: 'flex', height: 25, justifyContent: 'space-between' }}>
                    <p>{item.title}</p>
                    {/* display tag for Heifer Growth Model */}
                    {isHeiferGrowthModel ? (
                      <Tag color="warning" style={{ height: 20 }}>
                        COMING SOON
                      </Tag>
                    ) : null}
                  </div>,
                ]}
                description={item.cardDescription}
              />
            </Card>
          );
        })}
      </div>

      {/* modal with additional information would be displayed for a particular module */}
      {selectedCardData ? (
        <Modal
          width={800}
          centered
          visible={selectedCardTitle !== null}
          onCancel={() => setSelectedCardTitle(null)}
          footer={[
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: 4,
              }}
            >
              <Button
                loading={requestAccessForSelectedCalculator === selectedCardData.title}
                style={{
                  visibility:
                    selectedCardData.title === 'Heifer Growth Model' ? 'hidden' : 'visible',
                }}
                className="buttonColorRed"
                onClick={selectedCardData.btnClick}
              >
                {selectedCardData.btnLabel}
              </Button>
            </div>,
          ]}
        >
          <>
            <h1
              style={{
                fontSize: 24,
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: 15,
              }}
            >
              {selectedCardData.title}
            </h1>
            <div
              style={{
                fontSize: 16,
                paddingTop: 10,
                paddingBottom: 20,
                paddingRight: 20,
                paddingLeft: 20,
                color: textColor,
              }}
            >
              <selectedCardData.modalDescription />
            </div>
          </>
        </Modal>
      ) : null}
    </div>
  );
};

export default CaddieHomeScreen;
