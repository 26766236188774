import React, { useState } from 'react';
import { Spin, Select } from 'antd';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { useForm } from 'react-hook-form';
import WarningText from '../../components/WarningText';
import FormItem from '../../components/FormItem';
import InputNumberComponent from '../../components/InputNumberComponent';
import {
  FarmOptionsQuery,
  FarmOptionsQueryVariables,
  UpdateFarmCalculationValuesMutation,
  UpdateFarmCalculationValuesMutationVariables,
} from '../../graphql/graphql-types';
import { logger } from '../../utils/helpers';
import { CalculatorFormsPropsType } from '../../utils/types';
import CmrSetSolidsForm from '../../forms/calculator/CmrSetSolidsForm';
import PmrbNoWaterForm from '../../forms/calculator/PmrbNoWaterForm';
import PmWaterForm from '../../forms/calculator/PmWaterForm';
import RadioGroup from '../../components/RadioGroup';

const { Option } = Select;

/* type for farm fields type */
type FormFieldsType = {
  /* Number of calves */
  noOfCalves: number | undefined;
  /* Feedings per day */
  feedingsPerDay: number | undefined;
};

const farmOptionsQuery = loader('../../graphql/queries/farmOptionsQuery.graphql');
const updateFarmCalculationValuesMutation = loader(
  '../../graphql/mutations/updateFarmCalculationValuesMutation.graphql',
);

/* Schema for positive integer grated than 0 */
const integerGraterThanZeroSchema = yup
  .number()
  .required('Please enter a value and try again')
  .integer('Please enter a positive integer value greater than 0')
  .transform((currentValue, originalValue) =>
    originalValue || originalValue === 0 ? (originalValue as number) : undefined,
  )
  .moreThan(0, 'Please enter a positive integer value greater than 0');

/* Form validation schema */
const validationSchema = yup.object().shape({
  noOfCalves: integerGraterThanZeroSchema,
  feedingsPerDay: integerGraterThanZeroSchema,
});

/* React functional component */
const CalculatorsScreen: React.FC = () => {
  /* State to store selected farm ID */
  const [selectedFarmId, setSelectedFarmId] = useState<string | undefined>(undefined);
  /* Query to fetch farm options data */
  const { loading, data, error } = useQuery<FarmOptionsQuery, FarmOptionsQueryVariables>(
    farmOptionsQuery,
  );

  /* Mutation used to update farm calculation values */
  const [updateFarmCalculationValues] = useMutation<
    UpdateFarmCalculationValuesMutation,
    UpdateFarmCalculationValuesMutationVariables
  >(updateFarmCalculationValuesMutation);

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm<FormFieldsType>({
    defaultValues: {
      noOfCalves: undefined,
      feedingsPerDay: 2,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  /* Showing loading indicator while fetching farm options */
  if (loading) {
    return (
      <div className="loadingIndicator">
        <Spin size="large" />
      </div>
    );
  }

  /* If any error occurs while fetching farm options data */
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  /* Variable to store farm options data */
  const farmOptionsData = data?.farm;
  /* Function used to call when inputs onBlur event is invoked */
  const inputsOnBlurFunc = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSubmit((formData: FormFieldsType) => {
      if (selectedFarmId) {
        updateFarmCalculationValues({
          variables: {
            id: selectedFarmId,
            calves_count: formData.noOfCalves as number,
            feedings_per_day: watch('feedingsPerDay') as number,
          },
        }).catch((err: ApolloError) => {
          logger(err);
        });
      }
    })();
  };

  /* Storing props that we have to pass to all types of calculator from */
  const calculatorFormProps: CalculatorFormsPropsType = {
    noOfCalves: getValues('noOfCalves') as number,
    feedingsPerDay: getValues('feedingsPerDay') as number,
    selectedFarmId,
    setSelectedFarmId,
    farmName: farmOptionsData?.find((item) => item.id === selectedFarmId)?.name,
  };

  return (
    <>
      {Array.isArray(farmOptionsData) && farmOptionsData.length > 0 ? (
        <>
          {/* Select farm option */}
          <FormItem label="Select a farm:">
            <Select
              showSearch
              value={selectedFarmId}
              style={{ width: 350 }}
              placeholder="Select a farm"
              optionFilterProp="children"
              onChange={(val: string) => {
                setSelectedFarmId(val);
                /* Variable to store selected farm */
                const selectedFarm = farmOptionsData.find((item) => item.id === val);
                if (selectedFarm && selectedFarm.calves_count && selectedFarm.feedings_per_day) {
                  setValue('noOfCalves', selectedFarm.calves_count);
                  setValue('feedingsPerDay', selectedFarm.feedings_per_day);
                }
              }}
              filterOption={(input, option) => {
                if (option) {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
            >
              {farmOptionsData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          {selectedFarmId ? (
            <>
              {/* Form inputs */}
              <div style={{ marginBottom: 35 }}>
                <FormItem
                  label="Number of calves:"
                  errorText={errors.noOfCalves?.message}
                  isRequired
                >
                  <InputNumberComponent
                    name="noOfCalves"
                    rhfControllerProps={{ control }}
                    inputNumberProps={{
                      min: 0,
                    }}
                    onBlur={inputsOnBlurFunc}
                  />
                </FormItem>
                <FormItem label="Feedings per day" isRequired>
                  <RadioGroup
                    name="feedingsPerDay"
                    rhfControllerProps={{ control }}
                    options={[
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 },
                    ]}
                    onChange={() => {
                      inputsOnBlurFunc();
                    }}
                    displayMode="row"
                  />
                </FormItem>
              </div>

              {/* Tabs for different calculator selection */}
              <div className="tabContainer">
                <Tabs defaultIndex={-1} forceRenderTabPanel>
                  <TabList style={{ padding: '0px 35px', fontSize: '16px' }}>
                    <Tab>Milk Replacer Only Set Solids</Tab>
                    <Tab>Pasteurized Milk Balancer + Milk No Water</Tab>
                    <Tab>Multi Ingredient Milk Ration Milk + Powder + Water</Tab>
                  </TabList>
                  <TabPanel style={{ paddingTop: 10 }}>
                    <CmrSetSolidsForm {...calculatorFormProps} />
                  </TabPanel>
                  <TabPanel style={{ paddingTop: 10 }}>
                    <PmrbNoWaterForm {...calculatorFormProps} />
                  </TabPanel>
                  <TabPanel style={{ paddingTop: 10 }}>
                    <PmWaterForm {...calculatorFormProps} />
                  </TabPanel>
                </Tabs>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <WarningText customStyles={{ width: 570, margin: 'auto' }}>
            <div>
              Please add a farm using <b>'Add new farm'</b> tab to begin using the app.
            </div>
          </WarningText>
        </div>
      )}
    </>
  );
};

export default CalculatorsScreen;
