import * as yup from 'yup';

// variable to store local forage key which stores auth data
export const LOCALFORAGE_KEY = 'auth';

/* Schema for number grater than 0 */
export const numberGreaterThanZeroSchema = yup
  .number()
  .transform((currentValue, originalValue) =>
    originalValue === '' ? undefined : (currentValue as number),
  )
  .required('Please enter a value and try again')
  .moreThan(0, 'Please enter a positive integer value greater than 0')
  .nullable();

// const to store validation schema for noOfCalves field only. Its basically link to noOfCalvesFeedingsPerDayForm.
export const noOfCalvesFeedingsPerDayFormValidationSchema = yup.object().shape({
  noOfCalves: numberGreaterThanZeroSchema,
});

export default null;
