import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  pooledColostrumReplacer,
  PooledColostrumReplacerInputsType,
  CustomError,
  CustomErrorTypeEnum,
} from '@ecp32/calculations';
import { Col, Row, Button, Spin, Modal, message, Card } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import {
  InputKeysType,
  PooledFormType,
} from '../../calculator/colostrum-replacer-calculator/Pooled';
import InputNumber from '../../components/InputNumberComponent';
import FormItem from '../../components/FormItem';
import {
  AddCalculationMutation,
  AddCalculationMutationVariables,
  Enum_Calculator_Enum,
  Enum_Measurement_System_Enum,
  FarmSettingsByFarmIdQuery,
  FarmSettingsByFarmIdQueryVariables,
} from '../../graphql/graphql-types';
import SectionHeaderComponent from '../../components/SectionHeaderComponent';
import renderLabelBasedOnUnit from '../../utils/colostrum-replacer/renderLabelBasedOnUnit';
import CalculatedOutputFieldsComponent from '../../components/colostrum-replacer/CalculatedOutputFieldsComponent';
import SettingsFixedFieldsComponent from '../../components/colostrum-replacer/SettingsFixedFieldsComponent';
import InputComponent from '../../components/InputComponent';
import WarningText from '../../components/WarningText';
import getPooledFieldsBasedOnProperty from '../../utils/colostrum-replacer/getPooledFieldsBasedOnProperty';
import renderBrixReadingValidationWarning from '../../utils/colostrum-replacer/renderBrixReadingValidationWarning';
import styles from '../../forms/calculator/CalculatorForms.module.scss';
import { logger } from '../../utils/helpers';
import ResearchProviderInfoBar from '../../components/colostrum-replacer/ResearchProviderInfoBar';

const farmSettingsQuery = loader('../../graphql/queries/farmSettingByFarmIdQuery.graphql');
const addCalculationMutation = loader('../../graphql/mutations/addCalculationMutation.graphql');

// yup object schema to define validations
const schema = yup.object().shape({
  birthBodyWt: yup
    .number()
    .required('Please enter birth body weight')
    .test('is-in-range', 'Out of normal range', (value: number | undefined, context) => {
      const { unit } = context.parent as { unit: Enum_Measurement_System_Enum };
      if (value) {
        // const use to store updated value of  birthBodyWt value unit is metric
        const valueWhenUnitIsMetric =
          unit === Enum_Measurement_System_Enum.Metric ? value * 2.2046 : value;

        return valueWhenUnitIsMetric >= 50 && valueWhenUnitIsMetric <= 130;
      }
      return false;
    })
    .nullable(),
  brixReading: yup
    .number()
    .min(1, 'Brix reading should be more than or equal to 1')
    .max(40, 'Out of normal range')
    .required('Please enter brix reading')
    .nullable(),
  brixUsedInPrevCalculation: yup
    .number()
    .max(40, 'Out of normal range')
    .when('noOfSecondFeedingCalves', {
      is: (value: number) => value > 0,
      then: yup.number().required('Please enter previous brix reading').nullable(),
    })
    .test({
      test(value, context) {
        const formData = context.parent as PooledFormType;
        if (value && value < 0) {
          return context.createError({
            message:
              'First feeding brix reading used in previous calculation should be more than or equal to 1',
          });
        }

        // const to store form value for given key
        if (value === 0 && formData.noOfSecondFeedingCalves === 0) {
          return true;
        }
        return true;
      },
    })
    .nullable(),
  noOfFirstFeedingCalves: yup
    .number()
    .positive('Please enter value more than zero')
    .integer('Please enter positive integer value')
    .required('Please enter number of first feeding calves')
    .nullable(),
  noOfSecondFeedingCalves: yup
    .number()
    .min(0, 'Please enter value more than zero')
    .integer('Please enter positive integer value')
    .required('Please enter number of second feeding calves')
    .nullable(),
  // show referenceName field required only if all fields contain valid values & reference modal is open.
  referenceName: yup
    .string()
    .nullable()
    .when('$isReferenceModalOpen', {
      is: (isReferenceModalOpen: boolean) => isReferenceModalOpen,
      then: yup.string().required('Please enter reference name.'),
    }),
});

// const to define default value of pooled form
const pooledCalculatorDefaultValues = {
  unit: undefined,
  desiredMaxAmtForFirstFeeding: undefined,
  desiredMaxAmtForSecondFeeding: undefined,
  igGInColostrumReplacer: undefined,
  birthBodyWt: undefined,
  brixReading: 20,
  brixUsedInPrevCalculation: 0,
  noOfFirstFeedingCalves: 0,
  noOfSecondFeedingCalves: 0,
  referenceName: undefined,
};

// calling filterPooledFieldsBasedOnProperty to get input fields, fixed fields, section wise fields for pooled calculator
const {
  pooledInputFields,
  pooledPerCalfOutputFields,
  pooledPerNCalvesOutputFields,
  pooledFixedFields,
} = getPooledFieldsBasedOnProperty();

// react functional component
const ColostrumReplacerPooledCalculator: React.FC = () => {
  // navigate variable contain useNavigate hooks
  const navigate = useNavigate();

  // extracting farm id param from useParam
  const { farmId } = useParams();

  // useState to show loading indicator on modal save button
  const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false);

  // useState to show reference modal visibility
  const [isReferenceModalOpen, setIsReferenceModalOpen] = useState<boolean>(false);

  // useForm declaration
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<PooledFormType>({
    defaultValues: pooledCalculatorDefaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
    context: { isReferenceModalOpen },
  });

  // const to store current value of all form fields
  const { referenceName, ...allFieldsValue } = watch();

  // function that will return calculated fields for the pooled colostrum calculator every time when input fields value changes
  const pooledCalculatorFunc = useCallback(() => {
    // logic to call pooled calculator function when there are no form errors
    if (Object.keys(errors).length === 0) {
      try {
        // calling pooledColostrumReplacer calculator function
        const outputFields = pooledColostrumReplacer(allFieldsValue);
        return { result: outputFields };
      } catch (error) {
        if (error instanceof CustomError) {
          if (error.type === CustomErrorTypeEnum.Warning) {
            return { error: error.message };
          }
        }
      }
    }
    return undefined;
  }, [allFieldsValue, errors]);

  // Query to fetch all farms data
  const { loading, error, data: queryData } = useQuery<
    FarmSettingsByFarmIdQuery,
    FarmSettingsByFarmIdQueryVariables
  >(farmSettingsQuery, {
    variables: { id: farmId },
    onCompleted: ({ farm_by_pk, getIgGInColostrumReplacer }) => {
      // on successful completion of query set value of settings fields
      if (
        farm_by_pk &&
        farm_by_pk.col_rep_measurement_system_id &&
        farm_by_pk.colostrum_max_first_fdg &&
        farm_by_pk.colostrum_max_second_fdg &&
        getIgGInColostrumReplacer
      ) {
        setValue('unit', farm_by_pk.col_rep_measurement_system_id);
        setValue('desiredMaxAmtForFirstFeeding', farm_by_pk.colostrum_max_first_fdg);
        setValue('desiredMaxAmtForSecondFeeding', farm_by_pk.colostrum_max_second_fdg);
        setValue('igGInColostrumReplacer', getIgGInColostrumReplacer.igGInColostrumReplacer);
      }
    },
  });

  /* Mutation to add new calculation */
  const [addCalculation] = useMutation<AddCalculationMutation, AddCalculationMutationVariables>(
    addCalculationMutation,
  );

  // Showing loading indicator while fetching farm options
  if (loading) {
    return (
      <div className="loadingIndicator">
        <Spin size="large" />
      </div>
    );
  }

  // If any error occurs while fetching farm options data
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  // const to store no of first feeding calves
  const numberOfFirstFeedingCalves = allFieldsValue.noOfFirstFeedingCalves || 0;

  // const to store number of second feeding calves
  const numberOfSecondFeedingCalves = allFieldsValue.noOfSecondFeedingCalves || 0;

  // const to store unit value
  const unitValue = allFieldsValue.unit || Enum_Measurement_System_Enum.UsCustomary;

  // const to store calculated output by calling pooled calculator function
  const pooledCalculationOutput = pooledCalculatorFunc();

  // function that will be called on click of modal save button
  const handleModalSaveBtnClick = () => {
    setSaveBtnLoading(true);
    // calling mutation to add pooled calculation in database
    addCalculation({
      variables: {
        object: {
          name: referenceName,
          inputs: allFieldsValue,
          calculator: Enum_Calculator_Enum.ColRepPooled,
          farm_id: farmId,
        },
      },
      refetchQueries: [{ query: farmSettingsQuery, variables: { id: farmId } }],
    })
      .then(() => {
        setSaveBtnLoading(false);
        setIsReferenceModalOpen(false);
        reset();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Calculation added successfully');
        navigate('/colostrum-management/calculators');
      })
      .catch((err: ApolloError) => {
        setSaveBtnLoading(false);
        setIsReferenceModalOpen(false);
        logger(err);
      });
  };

  return (
    <div style={{ paddingLeft: 35, paddingTop: 10 }}>
      {/* --------- modal code start ----------- */}
      {isReferenceModalOpen ? (
        <Modal
          destroyOnClose
          visible={isReferenceModalOpen}
          closable={false}
          okText="Save"
          okButtonProps={{ className: 'buttonColorRed' }}
          onCancel={() => {
            setIsReferenceModalOpen(false);
            setValue('referenceName', undefined);
          }}
          confirmLoading={saveBtnLoading}
          onOk={handleSubmit(handleModalSaveBtnClick)}
          width={450}
        >
          <FormItem
            label="Reference Name"
            displayMode="column"
            isRequired
            errorText={errors ? errors.referenceName?.message : undefined}
          >
            <InputComponent
              placeholder="Please enter a name for this calculation"
              name="referenceName"
              rhfControllerProps={{ control }}
            />
          </FormItem>
        </Modal>
      ) : null}
      {/* --------- modal code end ------------- */}
      <Row>
        <Col span={24}>
          <h2>Pooled Calculator</h2>
        </Col>
      </Row>
      <Card>
        <Row gutter={50} style={{ paddingLeft: 20 }}>
          <Col span={11}>
            <form>
              <SectionHeaderComponent title="Inputs" />
              {/* render fixed fields */}
              <SettingsFixedFieldsComponent
                fieldData={pooledFixedFields.filter(
                  (item) => item.key !== 'igGInColostrumReplacer',
                )}
                settingsFieldValue={{
                  unit: unitValue,
                  desiredMaxAmtForFirstFeeding: allFieldsValue.desiredMaxAmtForFirstFeeding || 0,
                  desiredMaxAmtForSecondFeeding: allFieldsValue.desiredMaxAmtForSecondFeeding || 0,
                }}
              />

              {/* render input fields for form */}
              {pooledInputFields.map((ele) => (
                <FormItem
                  key={ele.key}
                  label={renderLabelBasedOnUnit(
                    ele.description.label,
                    unitValue,
                    ele.description.unitKey,
                  )}
                  isRequired={
                    !(ele.key === 'brixUsedInPrevCalculation' && numberOfSecondFeedingCalves === 0)
                  }
                  displayMode="column"
                  customStyle={{ marginBottom: 10 }}
                  errorText={errors ? errors[ele.key as InputKeysType]?.message : undefined}
                >
                  <InputNumber
                    rhfControllerProps={{ control }}
                    name={ele.key}
                    onChange={(value) => {
                      // if will be execute for "No of feeding calves" fields only. To prefilled value of previous brix reading field.
                      // this condition states that previously last calculations are performed
                      if (ele.key === 'noOfSecondFeedingCalves') {
                        if (
                          queryData &&
                          Array.isArray(queryData.calculation) &&
                          queryData.calculation.length > 0
                        ) {
                          // const use to store last performed calculation input values
                          const brixReadingOfLastCalculation = queryData.calculation[0]
                            .inputs as PooledColostrumReplacerInputsType;

                          // setting value of brixUsedInPrevCalculation
                          setValue(
                            'brixUsedInPrevCalculation',
                            value > 0 ? brixReadingOfLastCalculation.brixReading : 0,
                          );
                        }
                        // this condition states that there is no previous calculations performed
                        else {
                          setValue('brixUsedInPrevCalculation', value > 0 ? 20 : 0);
                        }
                        clearErrors('brixUsedInPrevCalculation');
                      }
                    }}
                    placeholder="Please enter value"
                  />
                </FormItem>
              ))}
              {pooledCalculationOutput && pooledCalculationOutput.error ? (
                <div style={{ marginTop: 20 }}>
                  <WarningText customStyles={{ display: 'flex', alignItems: 'center' }}>
                    {pooledCalculationOutput.error}
                  </WarningText>
                </div>
              ) : null}

              {/* function call to display warning message */}
              {allFieldsValue.brixUsedInPrevCalculation !== 0 &&
                renderBrixReadingValidationWarning(
                  allFieldsValue.brixReading,
                  allFieldsValue.brixUsedInPrevCalculation,
                )}

              <Button
                loading={saveBtnLoading && !isReferenceModalOpen}
                className={`${styles.saveBtn} buttonColorRed`}
                style={{ marginTop: 10 }}
                disabled={!!(pooledCalculationOutput && pooledCalculationOutput.error)}
                onClick={handleSubmit(() => {
                  setIsReferenceModalOpen(true);
                })}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  navigate('/colostrum-management/calculators');
                }}
              >
                Back
              </Button>
            </form>
          </Col>
          <Col span={12}>
            <SectionHeaderComponent title="Per Calf" />
            {/* render per calf fields */}
            <CalculatedOutputFieldsComponent
              fieldData={pooledPerCalfOutputFields}
              outputValues={pooledCalculationOutput && pooledCalculationOutput.result}
              selectedUnitValue={unitValue}
            />

            <SectionHeaderComponent
              title={`Per ${numberOfFirstFeedingCalves + numberOfSecondFeedingCalves} Calves`}
            />
            {/* render per n calves fields */}
            <CalculatedOutputFieldsComponent
              fieldData={pooledPerNCalvesOutputFields}
              outputValues={pooledCalculationOutput && pooledCalculationOutput.result}
              selectedUnitValue={unitValue}
            />
          </Col>
        </Row>
      </Card>
      <ResearchProviderInfoBar />
    </div>
  );
};

export default ColostrumReplacerPooledCalculator;
