import { Modal } from 'antd';
import React from 'react';
import CmrSetSolidsForm from '../forms/calculator/CmrSetSolidsForm';
import PmrbNoWaterForm from '../forms/calculator/PmrbNoWaterForm';
import PmWaterForm from '../forms/calculator/PmWaterForm';
import { Enum_Calculator_Enum } from '../graphql/graphql-types';
import { CalculatorFormsPropsType, FarmCalculationsDataType } from '../utils/types';

// editCalculatorModalComponentProp type
type EditCalculatorModalComponentPropType = {
  /* Prop to store calculation data */
  calculationData: FarmCalculationsDataType;
  /* setState to update calculation data that also used for visibility of modal */
  setCalculationData: React.Dispatch<React.SetStateAction<FarmCalculationsDataType | undefined>>;
};

// react functional component
const EditCalculatorModalComponent: React.FC<EditCalculatorModalComponentPropType> = ({
  calculationData,
  setCalculationData,
}) => {
  // function to decide which calculator form to open when user click edit button from history table
  const renderCalculatorFormBasedOnType = (data: FarmCalculationsDataType) => {
    /* Storing props that we have to pass to all types of calculator from */
    const calculatorFormProps: CalculatorFormsPropsType = {
      selectedFarmId: data.farm_id,
      setCalculationData,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      initialInputs: data.inputs,
      farmName: calculationData.farm.name,
    };

    if (data.calculator === Enum_Calculator_Enum.CmrSetSolids) {
      return <CmrSetSolidsForm {...calculatorFormProps} />;
    }

    if (data.calculator === Enum_Calculator_Enum.PmrbNoWater) {
      return <PmrbNoWaterForm {...calculatorFormProps} />;
    }

    if (data.calculator === Enum_Calculator_Enum.PmWater) {
      return <PmWaterForm {...calculatorFormProps} />;
    }

    return null;
  };
  return (
    <div>
      <Modal
        destroyOnClose
        visible={!!calculationData}
        footer={null}
        okText="Save"
        okButtonProps={{ className: 'buttonColorRed' }}
        onCancel={() => {
          setCalculationData(undefined);
        }}
        width={1100}
      >
        {renderCalculatorFormBasedOnType(calculationData)}
      </Modal>
    </div>
  );
};

export default EditCalculatorModalComponent;
