import {
  ByCalfColostrumReplacerDataType,
  byCalfColostrumReplacerAllFields,
} from '../../calculator/colostrum-replacer-calculator/ByCalf';

type FilterDataType = Record<
  'byCalfInputFields' | 'byCalfFixedFields' | 'byCalfOutputFields',
  ByCalfColostrumReplacerDataType[]
>;

// component use to get array of by-calf fields based on property
const getByCalfFieldsBasedOnProperty = (): FilterDataType => {
  // const which will return filtered data
  const filteredData: FilterDataType = {
    // this to store inputs fields for pooled calculator
    byCalfInputFields: [],
    // this to store fields which will be shown as static value this are setting specific fields
    byCalfFixedFields: [],
    // this to store output/calculated fields
    byCalfOutputFields: [],
  };

  // Iterating over pooled calculator all fields to create multiple array based on type and section key
  byCalfColostrumReplacerAllFields.forEach((ele) => {
    if (ele.type === 'input') {
      filteredData.byCalfInputFields.push(ele);
    }
    if (ele.type === 'fixed') {
      filteredData.byCalfFixedFields.push(ele);
    }
    if (ele.type === 'calculated') {
      filteredData.byCalfOutputFields.push(ele);
    }
  });
  return filteredData;
};

export default getByCalfFieldsBasedOnProperty;
