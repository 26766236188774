import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import {
  calculatedCMRSetSolidFields,
  CmrSetSolidsDataFieldsType,
  CmrSetSolidsInputsKeyType,
  cmrSetSolidsValues,
} from '../calculator/CMRSetSolids';
import {
  PmAndWaterDataFieldsType,
  pmAndWaterValues,
  calculatedPMAndWaterFields,
  PowderFeedingCountSelectionType,
  PowderType,
  PmAndWaterInputsKeyType,
} from '../calculator/PMAndWater';
import {
  PmrbNOWaterDataFieldsType,
  pmrbNoWaterValues,
  calculatedPMRBNoWaterFields,
  PmrbNoWaterInputsKeyType,
} from '../calculator/PMRBNoWater';
import { Enum_Calculator_Enum } from '../graphql/graphql-types';
import { filterArrayBasedOnType } from '../utils/helpers';
import { FarmCalculationsDataType, UnitType } from '../utils/types';
import SectionHeaderComponent from './SectionHeaderComponent';
import CalculatedFieldsListViewComponent from '../forms/calculator/CalculatedFieldsListViewComponent';
import ReportNameModalComponent, {
  ReportGenerationDataType,
} from '../forms/calculator/ReportNameModalComponent';

/* Type for CalculatorHistoryViewModalComponent props */
type CalculatorHistoryViewModalComponentPropsType = {
  /* Prop to store calculation data */
  calculationData: FarmCalculationsDataType;
  /* setState to update calculation data that also used for visibility of modal */
  updateCalculationData: React.Dispatch<React.SetStateAction<FarmCalculationsDataType | undefined>>;
};

/* Type for value which is common in all types of calculator */

/* Calculator all data fields type */
type CalculatorAllDataFieldsType =
  | CmrSetSolidsDataFieldsType
  | PmrbNOWaterDataFieldsType
  | PmAndWaterDataFieldsType;

/* React functional component */
const CalculatorHistoryViewModalComponent: React.FC<CalculatorHistoryViewModalComponentPropsType> = ({
  calculationData,
  updateCalculationData,
}) => {
  /* State to decide whether to show data with unit per calf per feeding or not */
  const [showPerCalfPerFdgData, setShowPerCalfPerFdgData] = useState<boolean>(false);

  /* State to decide whether to show data with unit per calf daily or not */
  const [showPerCalfDailyData, setShowPerCalfDailyData] = useState<boolean>(false);

  /* State used to store data that will be used for report generation */
  const [
    dataForReportGeneration,
    setDataForReportGeneration,
  ] = useState<ReportGenerationDataType | null>(null);

  // variable to store all static data fields of particular form define in respective form's ts file
  let staticDataFieldsArray: CalculatorAllDataFieldsType[] = [];

  // variable to store value of calculated fields
  let calculatedFieldsValue: Record<string, number | undefined> = {};

  /* Variable to store inputs data */
  const inputsData = calculationData.inputs as Record<string, string | number>;

  // destructuring no of calves ,unit, powderFeedingCount values from input
  const { noOfCalves, unit, powderFeedingCount, powderType } = inputsData;

  /* Variable to store updated inputs data based on conditions initially kept as original inputs data */
  let updatedInputData = inputsData;

  // if else statement set the value of staticDataFieldArray and calculatedFieldsValue based on calculator type
  if (calculationData.calculator === Enum_Calculator_Enum.CmrSetSolids) {
    staticDataFieldsArray = cmrSetSolidsValues;

    /* Destructuring inputs data */
    const { targetSolidsFinalSolution, targetSolidsOfFinalSolution, ...rest } = inputsData;

    /* Updating inputs data */
    updatedInputData = {
      ...rest,
      targetSolidsOfFinalSolution: targetSolidsOfFinalSolution || targetSolidsFinalSolution,
    };

    // calculatedCMRSetSolidFields is the function use to calculate the fields value based on input provided
    calculatedFieldsValue = calculatedCMRSetSolidFields(
      updatedInputData as Record<CmrSetSolidsInputsKeyType, number | undefined>,
    );
  } else if (calculationData.calculator === Enum_Calculator_Enum.PmWater) {
    staticDataFieldsArray = pmAndWaterValues;

    /* Destructuring inputs data */
    const {
      targetSolidsFinalSolution,
      targetSolidsOfFinalSolution,
      milkFedPerCalfPerFdg,
      milkFedPerCalPerFdg,
      totalBalancerNeededPerFdg,
      totalBalancerNeeded,
      totalVolAvailableGallonsPerFdg,
      totalVolAvailableGallons,
      lbOfSecondPowderPerHdPerDay,
      lbOfBalancerPerCalfPerDay,
      ...rest
    } = inputsData;

    /* Updating inputs data */
    updatedInputData = {
      ...rest,
      targetSolidsFinalSolution: targetSolidsFinalSolution || targetSolidsOfFinalSolution,
      milkFedPerCalfPerFdg: milkFedPerCalfPerFdg || milkFedPerCalPerFdg,
      totalBalancerNeededPerFdg: totalBalancerNeededPerFdg || totalBalancerNeeded,
      totalVolAvailableGallonsPerFdg: totalVolAvailableGallonsPerFdg || totalVolAvailableGallons,
      lbOfSecondPowderPerHdPerDay: lbOfSecondPowderPerHdPerDay || lbOfBalancerPerCalfPerDay,
    };

    // calculatedPMAndWaterFields is the function use to calculate the fields value based on input provided
    calculatedFieldsValue = calculatedPMAndWaterFields(
      updatedInputData as Record<PmAndWaterInputsKeyType, number | undefined>,
    );
  } else {
    staticDataFieldsArray = pmrbNoWaterValues;

    /* Destructuring inputs data */
    const { totalSolidsFed, totalSolidsFedPerDaily, ...rest } = inputsData;

    /* Updating inputs data */
    updatedInputData = {
      ...rest,
      totalSolidsFedPerDaily: totalSolidsFedPerDaily || totalSolidsFed,
    };

    // calculatedPMRBNoWaterFields is the function use to calculate the fields value based on input provided
    calculatedFieldsValue = calculatedPMRBNoWaterFields(
      updatedInputData as Record<PmrbNoWaterInputsKeyType, number | undefined>,
    );
  }

  // variable to store primary calculated fields using staticDataFieldsArray
  const primaryCalculatedFields = filterArrayBasedOnType(
    staticDataFieldsArray,
    'primary',
  ) as CalculatorAllDataFieldsType[];

  // variable to store secondary calculated fields using staticDataFieldsArray
  const secondaryCalculatedFields = filterArrayBasedOnType(
    staticDataFieldsArray,
    'secondary',
  ) as CalculatorAllDataFieldsType[];

  // const to store array of fields whose unit is per calf per feeding or has no unit
  const perCalfPerFdgDataFields: CalculatorAllDataFieldsType[] = [];

  // const to store array of fields whose unit is per calf daily or has no unit
  const perCalfDailyDataFields: CalculatorAllDataFieldsType[] = [];

  // logic to add values to perCalfPerFdgDataFields and perCalfDailyDataFields array
  secondaryCalculatedFields.forEach((item) => {
    if (
      (typeof item.description.unit === 'string' &&
        item.description.unit.includes('per calf per feeding')) ||
      item.description.unit === undefined
    ) {
      perCalfPerFdgDataFields.push(item);
    }
    if (
      typeof item.description.unit === 'string' &&
      item.description.unit.includes('per calf daily')
    ) {
      perCalfDailyDataFields.push(item);
    }
  });

  return (
    <>
      {dataForReportGeneration && (
        <ReportNameModalComponent
          showModal={!!dataForReportGeneration}
          setShowModal={setDataForReportGeneration}
          {...dataForReportGeneration}
        />
      )}

      <Modal
        destroyOnClose
        visible={!!calculationData}
        footer={null}
        okText="Save"
        okButtonProps={{ className: 'buttonColorRed' }}
        onCancel={() => {
          updateCalculationData(undefined);
        }}
        width={1100}
      >
        <Row gutter={20}>
          <Col span={11} style={{ marginLeft: 20 }}>
            {/* Input data */}
            <SectionHeaderComponent title="Inputs" />
            {staticDataFieldsArray.map((item) => {
              if (Object.keys(updatedInputData).includes(item.key)) {
                // function to render label of the input fields
                const displayLabelFunction = (
                  ele:
                    | CmrSetSolidsDataFieldsType
                    | PmAndWaterDataFieldsType
                    | PmrbNOWaterDataFieldsType,
                ): string => {
                  if (typeof ele.description.label === 'function') {
                    if (ele.key === 'powderOneCP' || ele.key === 'powderOneFat') {
                      const fieldLabel =
                        updatedInputData.powderType === 'milkBalancer' &&
                        updatedInputData.powderFeedingCount === 1
                          ? 'Balancer'
                          : 'Milk Replacer';
                      return ele.description.label(fieldLabel);
                    }
                    if (ele.key === 'labSolidsOrRefractometerSelection') {
                      return ele.description.label(
                        updatedInputData.labSolidsOrRefractometer as string,
                      );
                    }
                  }

                  return `${ele.description.label as string} ${
                    ele.description.unit ? (ele.description.unit as string) : ''
                  }`;
                };

                // function to return value of the field
                const displayValueFunction = (
                  ele:
                    | CmrSetSolidsDataFieldsType
                    | PmAndWaterDataFieldsType
                    | PmrbNOWaterDataFieldsType,
                ): string => {
                  if (ele.isSelectOption) {
                    const fieldValue = ele.isSelectOption.find(
                      (element) => element.value === updatedInputData[item.key],
                    );
                    return fieldValue ? fieldValue.label : '';
                  }
                  return updatedInputData[item.key] as string;
                };

                return (
                  <Row key={item.key} style={{ marginTop: 10 }}>
                    {updatedInputData[item.key] ? (
                      <>
                        <Col span={16}>{displayLabelFunction(item)}</Col>
                        <Col span={8} style={{ textAlign: 'end' }}>
                          <span style={{ color: 'grey' }}>{displayValueFunction(item)}</span>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                );
              }

              return null;
            })}
          </Col>
          <Col span={11} style={{ marginLeft: 30 }}>
            {/* calculated data section */}
            <SectionHeaderComponent title="FINAL RATION per feeding" />
            {/* component to render the list of calculated fields and its calculated value */}
            <CalculatedFieldsListViewComponent
              listData={primaryCalculatedFields}
              calculatedFields={calculatedFieldsValue}
              unit={unit as UnitType}
              noOfCalves={noOfCalves as number}
              powderFeedingCount={powderFeedingCount as PowderFeedingCountSelectionType}
              powderType={powderType as PowderType}
            />

            {/* per calf per feeding Calculated data section */}

            <SectionHeaderComponent
              title={
                calculationData.calculator !== Enum_Calculator_Enum.CmrSetSolids
                  ? 'PER CALF PER FEEDING'
                  : 'FINAL RATION per calf'
              }
              showToggleBtn
              isSwitchOn={showPerCalfPerFdgData}
              updateSwitchState={setShowPerCalfPerFdgData}
            />

            {/* component to render the list of calculated fields and its calculated value */}
            {showPerCalfPerFdgData ? (
              <CalculatedFieldsListViewComponent
                listData={
                  calculationData.calculator !== Enum_Calculator_Enum.CmrSetSolids
                    ? perCalfPerFdgDataFields
                    : secondaryCalculatedFields
                }
                calculatedFields={calculatedFieldsValue}
                unit={unit as UnitType}
                noOfCalves={noOfCalves as number}
                powderFeedingCount={powderFeedingCount as PowderFeedingCountSelectionType}
                powderType={powderType as PowderType}
              />
            ) : null}

            {/* per calf daily Calculated data section */}
            {calculationData.calculator !== Enum_Calculator_Enum.CmrSetSolids ? (
              <SectionHeaderComponent
                title="PER CALF DAILY"
                showToggleBtn
                isSwitchOn={showPerCalfDailyData}
                updateSwitchState={setShowPerCalfDailyData}
              />
            ) : null}
            {/* component to render the list of calculated fields and its calculated value */}
            {showPerCalfDailyData ? (
              <>
                {calculationData.calculator === Enum_Calculator_Enum.PmrbNoWater ? (
                  <div style={{ fontStyle: 'italic', marginTop: 7 }}>
                    *Daily values assumes same ration fed each feed
                  </div>
                ) : null}
                <CalculatedFieldsListViewComponent
                  listData={perCalfDailyDataFields}
                  calculatedFields={calculatedFieldsValue}
                  unit={unit as UnitType}
                  noOfCalves={noOfCalves as number}
                  powderFeedingCount={powderFeedingCount as PowderFeedingCountSelectionType}
                  powderType={powderType as PowderType}
                />
              </>
            ) : null}
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Button
              type="primary"
              className="buttonColorRed"
              style={{ marginTop: '40px' }}
              onClick={() => {
                setDataForReportGeneration({
                  inputsData: {
                    calculator: calculationData.calculator,
                    farmName: calculationData.farm.name,
                    ...inputsData,
                  },
                  calculatedFieldsValue,
                  fieldValues: staticDataFieldsArray,
                });
              }}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CalculatorHistoryViewModalComponent;
