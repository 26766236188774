import React from 'react';
import { Modal } from 'antd';
import { UseFormReset, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputComponent from '../../components/InputComponent';
import FormItem from '../../components/FormItem';
import { logger } from '../../utils/helpers';

// type definition for for type
type FormType = {
  // type definition for report name
  name: string;
};

type ColostrumReplacerReportNameModalPropType = {
  // type definition for done button loading
  isDoneBtnLoading: boolean;
  // type definition for generate report modal
  isGenerateReportModalOpen: boolean;
  // This function is called when user click on done button of modal
  onOk: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    formData: FormType,
    reset: UseFormReset<FormType>,
  ) => void;
  // This function is called when user click on cancel button of modal
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const ColostrumReplacerReportNameModal = ({
  isDoneBtnLoading,
  isGenerateReportModalOpen,
  onOk,
  onCancel,
}: ColostrumReplacerReportNameModalPropType): JSX.Element => {
  // useForm declaration
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: { name: '' },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Please enter a value and try again'),
      }),
    ),
  });

  return (
    <Modal
      visible={!!isGenerateReportModalOpen}
      confirmLoading={isDoneBtnLoading}
      closable={false}
      width={450}
      onOk={(e) => {
        handleSubmit((formData) => {
          onOk(e, formData, reset);
        })().catch((err: Error) => {
          logger(err);
        });
      }}
      onCancel={(e) => {
        onCancel(e);
        reset();
      }}
      okText="Done"
      okButtonProps={{ className: 'buttonColorRed' }}
    >
      <FormItem
        label="Report Name"
        displayMode="column"
        isRequired
        errorText={errors.name?.message}
      >
        <InputComponent
          placeholder="Please enter a name for this report"
          name="name"
          rhfControllerProps={{ control }}
        />
      </FormItem>
    </Modal>
  );
};

export default ColostrumReplacerReportNameModal;
