import React, { ChangeEvent } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import styles from './InputComponent.module.scss';

// This is the type of props coming from parent component
type InputComponentPropsType = {
  /* Name is the name of form field */
  name: string;
  // This is the default value of our input
  defaultValue?: string | undefined;
  /* This is the placeholder for text input */
  placeholder: string;
  // This props shows that input is password or not
  isPasswordInput?: boolean;
  // This is the controller props of react-hook-form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rhfControllerProps: Omit<UseControllerProps<any>, 'name' | 'defaultValue'>;
  // This is the custom style for input box
  customStyles?: React.CSSProperties;
  // This is the props for input field
  inputProps?: Omit<InputProps, 'onChange' | 'value' | 'onBlur' | 'ref'>;
  // This function is called when functionality is different from the regular onChange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (rhfOnChange: (...event: any[]) => void, val: string) => void;
  // This function is called when input loses its focus
  onBlur?: (val: React.FocusEvent<HTMLInputElement>) => void;
};

// This is the main functional component
const InputComponent: React.FC<InputComponentPropsType> = ({
  name,
  placeholder,
  customStyles = {},
  defaultValue = undefined,
  rhfControllerProps,
  inputProps = {},
  onChange,
  onBlur,
  isPasswordInput = false,
}) => {
  // destructuring fields from useController hook
  const { field } = useController({
    name,
    defaultValue,
    ...rhfControllerProps,
  });

  // destructuring onChange function, onBlur function & rhfFields, and then will pass it to input field
  const { onChange: rhfOnChange, onBlur: rhfOnBlur, ...rhfFields } = field;

  // This is a common props which is used in input password  and input
  const commonProps = {
    placeholder,
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(rhfOnChange, e.target.value);
      } else {
        rhfOnChange(e.target.value);
      }
    },
    onBlur: (value: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(value);
      }
      rhfOnBlur();
    },

    className: styles.textInput,
    style: { ...customStyles },
    ...rhfFields,
    ...inputProps,
  };

  return isPasswordInput ? <Input.Password {...commonProps} /> : <Input {...commonProps} />;
};

export default InputComponent;
