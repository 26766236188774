import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Row } from 'antd';
import {
  calculatedCMRSetSolidFields,
  CmrSetSolidsFormFieldsType,
  CmrSetSolidsFormFieldsWithoutReferenceNameType,
  CmrSetSolidsInputFieldsType,
  cmrSetSolidsValues,
} from '../../calculator/CMRSetSolids';
import {
  CalculatorFormsPropsType,
  LocalForageFarmIdType,
  NoOfCalvesFeedingsPerDayFormType,
} from '../../utils/types';
import {
  filterArrayBasedOnType,
  logger,
  readCalculatorInputs,
  removeCalculatorInputs,
  saveCalculatorInputs,
} from '../../utils/helpers';
import FormItem from '../../components/FormItem';
import InputNumberComponent from '../../components/InputNumberComponent';
import SectionHeaderComponent from '../../components/SectionHeaderComponent';
import CalculatedFieldsListViewComponent from './CalculatedFieldsListViewComponent';
import ReferenceNameModalComponent from './ReferenceNameModalComponent';
import WarningText from '../../components/WarningText';
import {
  noOfCalvesFeedingsPerDayFormValidationSchema,
  numberGreaterThanZeroSchema,
} from '../../utils/globals';
import { Enum_Calculator_Enum } from '../../graphql/graphql-types';
import styles from './CalculatorForms.module.scss';
import NoOfCalvesFeedingsPerDayForm from '../NoOfCalvesFeedingPerDayForm';
import ReportNameModalComponent, { ReportGenerationDataType } from './ReportNameModalComponent';

/* CMR set solids default values */
const cmrSetSolidsFormDefaultValues: CmrSetSolidsFormFieldsType = {
  targetSolidsOfFinalSolution: null,
  targetVolPerFdgQtPerCalf: null,
  referenceName: '',
};

/* CMR set solids Validation schema */
const validationSchema = yup.object().shape({
  targetSolidsOfFinalSolution: numberGreaterThanZeroSchema,
  targetVolPerFdgQtPerCalf: numberGreaterThanZeroSchema,
});

/* React functional component */
const CmrSetSolidsForm: React.FC<CalculatorFormsPropsType> = ({
  selectedFarmId,
  setSelectedFarmId,
  noOfCalves,
  feedingsPerDay,
  initialInputs,
  setCalculationData,
  farmName,
}) => {
  /* State used to decide whether to show additional calculated data or not */
  const [showAdditionalCalcData, setShowAdditionalCalcData] = useState<boolean>(false);

  /* State used to decide whether to show submit modal(modal with reference name field) or not */
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);

  /* State used to store data that will be used for report generation */
  const [
    dataForReportGeneration,
    setDataForReportGeneration,
  ] = useState<ReportGenerationDataType | null>(null);

  // variable to store form initial values when user want to update existing calculator from history screen
  let cmrSetSolidFormInitialValues: Record<string, string | number | undefined> = {};

  // variable to store form initial value of noOfCalves and  feedingsPerDay when user want to update existing calculator from history screen
  let noOfCalvesFeedingsPerDayInitialValue = {};

  // logic to get initialValues when form is called from history screen
  if ((noOfCalves === undefined || feedingsPerDay === undefined) && initialInputs) {
    Object.keys(initialInputs).forEach((input) => {
      if (input !== 'noOfCalves' && input !== 'feedingsPerDay') {
        cmrSetSolidFormInitialValues = {
          ...cmrSetSolidFormInitialValues,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          [input]: initialInputs[input],
        };
      } else {
        noOfCalvesFeedingsPerDayInitialValue = {
          ...noOfCalvesFeedingsPerDayInitialValue,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          [input]: initialInputs[input],
        } as NoOfCalvesFeedingsPerDayFormType;
      }
    });
  } else {
    noOfCalvesFeedingsPerDayInitialValue = {
      noOfCalves: undefined,
      feedingsPerDay: 2,
    };
  }

  /* Variable to store updated cmr form initial values if needed */
  let updatedKeysCmrSetSolidFormInitialValues = cmrSetSolidFormInitialValues;
  /**
   * This code block is written because some keys in web and mobile are spelled differently but
   * associated with the same field which is making issues while assigning default values to the form in edit mode
   * Hence here key is renamed to the correct one
   */
  if (Object.keys(cmrSetSolidFormInitialValues).length > 0) {
    /* Destructuring CMR set solids form initial values */
    const {
      targetSolidsFinalSolution,
      targetSolidsOfFinalSolution,
      ...rest
    } = cmrSetSolidFormInitialValues;
    /* Updating initial values */
    updatedKeysCmrSetSolidFormInitialValues = {
      ...rest,
      targetSolidsOfFinalSolution: targetSolidsOfFinalSolution || targetSolidsFinalSolution,
    };
  }

  /* useForm declaration for  form containing only noOfCalves and feedingsPerDay fields */
  const {
    control: noOfCalvesFeedingsPerDayFormControl,
    formState: { errors: noOfCalvesFeedingsPerDayFormErrors },
    watch: noOfCalvesFeedingsPerDayFormWatch,
    handleSubmit: noOfCalvesFeedingsPerDayHandleSubmit,
  } = useForm<NoOfCalvesFeedingsPerDayFormType>({
    defaultValues:
      noOfCalves && feedingsPerDay
        ? {
            noOfCalves: undefined,
            feedingsPerDay: 2,
          }
        : noOfCalvesFeedingsPerDayInitialValue,
    resolver: yupResolver(noOfCalvesFeedingsPerDayFormValidationSchema),
  });

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    reset,
    setValue,
    setError,
  } = useForm<CmrSetSolidsFormFieldsType>({
    defaultValues:
      noOfCalves && feedingsPerDay
        ? cmrSetSolidsFormDefaultValues
        : updatedKeysCmrSetSolidFormInitialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  // useEffect to fetch data stored in localforage
  useEffect(() => {
    // if condition because we don't want localforage value as initial values of form when user open form from history screen
    if (noOfCalves && feedingsPerDay) {
      // call to localforage readCalculatorInputs function to get data
      readCalculatorInputs(Enum_Calculator_Enum.CmrSetSolids)
        .then((res) => {
          if (res) {
            /* Local forage calculator inputs with farm id */
            const { farmId, ...restData } = res as CmrSetSolidsFormFieldsType &
              LocalForageFarmIdType;

            /* If current farm ID is equal to farm ID stored in localforage then only we have to show localforage values */
            if (farmId === selectedFarmId) {
              reset(restData);
            } else {
              reset(cmrSetSolidsFormDefaultValues);
            }
          }
        })
        .catch((err) => {
          logger(err);
        });
    }
  }, [feedingsPerDay, noOfCalves, reset, selectedFarmId, setValue]);

  /* Input fields for form */
  const inputFields = cmrSetSolidsValues.filter(
    (item) => item.type === 'input' && !['noOfCalves', 'feedingsPerDay'].includes(item.key),
  ) as CmrSetSolidsInputFieldsType[];

  /* Variable to store primary calculated fields using form input values */
  const primaryCalculatedFields = filterArrayBasedOnType(cmrSetSolidsValues, 'primary');

  /* Variable to store secondary calculated fields using form input values */
  const secondaryCalculatedFields = filterArrayBasedOnType(cmrSetSolidsValues, 'secondary');

  /* Function to submit form data */
  const onSubmit = () => {
    setShowSubmitModal(true);
  };

  /* Watch for targetVolPerFdgQtPerCalf fields */
  const watchTargetVolPerFdgQtPerCalf = watch('targetVolPerFdgQtPerCalf');
  /* Watch for targetSolidsOfFinalSolution fields */
  const watchTargetSolidsOfFinalSolution = watch('targetSolidsOfFinalSolution');

  /* Calculated fields for CMR set solids type */
  const cmrSetSolidFields = calculatedCMRSetSolidFields({
    noOfCalves: noOfCalves || noOfCalvesFeedingsPerDayFormWatch('noOfCalves'),
    feedingsPerDay: feedingsPerDay || noOfCalvesFeedingsPerDayFormWatch('feedingsPerDay'),
    targetVolPerFdgQtPerCalf:
      watchTargetVolPerFdgQtPerCalf && watchTargetVolPerFdgQtPerCalf > 0
        ? watchTargetVolPerFdgQtPerCalf
        : undefined,
    targetSolidsOfFinalSolution:
      watchTargetSolidsOfFinalSolution && watchTargetSolidsOfFinalSolution > 0
        ? watchTargetSolidsOfFinalSolution
        : undefined,
  });

  return (
    <>
      {/* Reference name modal to add name to the calculation */}
      <ReferenceNameModalComponent
        showModal={showSubmitModal}
        setShowModal={setShowSubmitModal}
        control={control}
        selectedFarmId={selectedFarmId}
        setSelectedFarmId={setSelectedFarmId}
        getValues={getValues}
        type={Enum_Calculator_Enum.CmrSetSolids}
        noOfCalves={noOfCalves || noOfCalvesFeedingsPerDayFormWatch('noOfCalves')}
        feedingsPerDay={feedingsPerDay || noOfCalvesFeedingsPerDayFormWatch('feedingsPerDay')}
        setValue={setValue}
        errorText={errors.referenceName?.message}
        setError={setError}
        setCalculationData={setCalculationData}
      />

      {dataForReportGeneration && (
        <ReportNameModalComponent
          showModal={!!dataForReportGeneration}
          setShowModal={setDataForReportGeneration}
          {...dataForReportGeneration}
        />
      )}

      <Row gutter={20}>
        <Col span={11} className={styles.inputDataContainer}>
          {/* Input data section */}
          <SectionHeaderComponent title="Input Data" />
          {noOfCalves && feedingsPerDay ? null : (
            <NoOfCalvesFeedingsPerDayForm
              control={noOfCalvesFeedingsPerDayFormControl}
              errors={noOfCalvesFeedingsPerDayFormErrors}
            />
          )}
          {Array.isArray(inputFields) && inputFields.length > 0
            ? inputFields.map((item, index) => (
                <div key={index.toString()} style={{ marginBottom: 10 }}>
                  <FormItem
                    label={`${item.description.label as string} ${
                      (item.description.unit as string) || ''
                    }`}
                    displayMode="column"
                    isRequired
                    errorText={errors[item.key]?.message}
                  >
                    <InputNumberComponent
                      placeholder="Please enter the value"
                      name={item.key as string}
                      rhfControllerProps={{ control }}
                      inputNumberProps={{ min: 0 }}
                    />
                  </FormItem>
                  {item.key === 'targetSolidsOfFinalSolution' &&
                  watchTargetSolidsOfFinalSolution &&
                  watchTargetSolidsOfFinalSolution > 16.5 ? (
                    <WarningText customStyles={{ marginTop: 10 }}>
                      <>Feeding high solids can be problematic.</>
                    </WarningText>
                  ) : null}
                </div>
              ))
            : null}
        </Col>

        <Col span={11} className={styles.calculatedDataContainer}>
          {/* Calculated data section */}
          <SectionHeaderComponent title="FINAL RATION per feeding" />

          <CalculatedFieldsListViewComponent
            listData={primaryCalculatedFields}
            calculatedFields={cmrSetSolidFields}
            noOfCalves={noOfCalves}
          />

          {/* Additional Calculated data section */}
          <SectionHeaderComponent
            title="FINAL RATION per calf"
            showToggleBtn
            isSwitchOn={showAdditionalCalcData}
            updateSwitchState={setShowAdditionalCalcData}
          />

          {showAdditionalCalcData ? (
            <CalculatedFieldsListViewComponent
              listData={secondaryCalculatedFields}
              calculatedFields={cmrSetSolidFields}
              noOfCalves={noOfCalves}
            />
          ) : null}
        </Col>
      </Row>

      <div className={styles.btnContainer}>
        <Button
          type="primary"
          className={`${styles.saveBtn} buttonColorRed`}
          htmlType="submit"
          onClick={
            () => {
              /* eslint-disable @typescript-eslint/no-floating-promises */
              if (noOfCalves && feedingsPerDay) {
                handleSubmit(onSubmit)();
              } else {
                noOfCalvesFeedingsPerDayHandleSubmit(() => {
                  handleSubmit(onSubmit)();
                })();
              }
            }
            /* eslint-enable */
          }
        >
          Save to History
        </Button>
        <Button
          type="primary"
          className={`${styles.saveBtn} buttonColorRed`}
          htmlType="submit"
          onClick={() => {
            // const to store all input values entered by user
            const userInputValues: CmrSetSolidsFormFieldsWithoutReferenceNameType &
              LocalForageFarmIdType = {
              targetVolPerFdgQtPerCalf: watchTargetVolPerFdgQtPerCalf,
              targetSolidsOfFinalSolution: watchTargetSolidsOfFinalSolution,
              farmId: selectedFarmId as string,
            };

            saveCalculatorInputs(
              JSON.stringify(userInputValues),
              Enum_Calculator_Enum.CmrSetSolids,
            ).catch((err) => logger(err));
          }}
        >
          Save Locally
        </Button>
        <Button
          className={styles.resetBtn}
          type="default"
          onClick={() => {
            removeCalculatorInputs(Enum_Calculator_Enum.CmrSetSolids)
              .then(() => {
                reset(cmrSetSolidsFormDefaultValues);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          className={`${styles.saveBtn} buttonColorRed`}
          onClick={() => {
            /* eslint-disable @typescript-eslint/no-floating-promises */
            if (noOfCalves && feedingsPerDay) {
              handleSubmit((formData) => {
                /* Destructuring form data */
                const { referenceName, ...restData } = formData;

                setDataForReportGeneration({
                  inputsData: {
                    calculator: Enum_Calculator_Enum.CmrSetSolids,
                    farmName,
                    noOfCalves,
                    feedingsPerDay,
                    ...restData,
                  },
                  calculatedFieldsValue: cmrSetSolidFields,
                  fieldValues: cmrSetSolidsValues,
                });
              })();
            } else {
              noOfCalvesFeedingsPerDayHandleSubmit((formData) => {
                handleSubmit((submittedData) => {
                  setDataForReportGeneration({
                    inputsData: {
                      calculator: Enum_Calculator_Enum.CmrSetSolids,
                      farmName,
                      ...formData,
                      ...submittedData,
                    },
                    calculatedFieldsValue: cmrSetSolidFields,
                    fieldValues: cmrSetSolidsValues,
                  });
                })();
              })();
            }
            /* eslint-enable */
          }}
        >
          Generate Report
        </Button>
      </div>
    </>
  );
};

export default CmrSetSolidsForm;
