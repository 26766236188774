import { CalculatorDataCommonType, ReferenceNameType } from '../utils/types';

/* Type for CMR set solids input keys */
export type CmrSetSolidsInputsKeyType =
  | 'noOfCalves'
  | 'targetSolidsOfFinalSolution'
  | 'targetVolPerFdgQtPerCalf'
  | 'feedingsPerDay';

/* Type for CMR set solids output keys */
export type CmrSetSolidsOutputsKeyType =
  | 'gallonsFedPerDay'
  | 'gallonsOfSolutionsPerFdg'
  | 'poundsOfPowderNeededPerFdg'
  | 'poundsOfPowderFedPerCalfPerFdg'
  | 'poundsOfPowderFedPerCalfDaily'
  | 'gallonsOfWaterAddedPerFdg'
  | 'lbPerGallon'
  | 'wtOfDryMatterPerDay'
  | 'wtOfDryMatterPerFdg'
  | 'quartsFedPerCalfDaily'
  | 'quartsFedPerCalfPerFdg';

/* Type for CMR set solids all data fields */
export interface CmrSetSolidsDataFieldsType extends CalculatorDataCommonType {
  key: CmrSetSolidsInputsKeyType | CmrSetSolidsOutputsKeyType;
}
/* Type for CMR set solids all input fields */
export interface CmrSetSolidsInputFieldsType extends CalculatorDataCommonType {
  key: Exclude<CmrSetSolidsInputsKeyType, 'noOfCalves' | 'feedingsPerDay'>;
}

// type for CMR set solids form fields excluding referenceName
export interface CmrSetSolidsFormFieldsWithoutReferenceNameType {
  /* Target solids final solution */
  targetSolidsOfFinalSolution: number | undefined | null;
  /* target volume per feeding qt per calf */
  targetVolPerFdgQtPerCalf: number | undefined | null;
}

/* Type of cmr set solids form fields type */
export interface CmrSetSolidsFormFieldsType
  extends ReferenceNameType,
    CmrSetSolidsFormFieldsWithoutReferenceNameType {}
/* CMR Set Solids static data array */
export const cmrSetSolidsValues: CmrSetSolidsDataFieldsType[] = [
  {
    key: 'noOfCalves',
    type: 'input',
    description: {
      label: 'Number of Calves',
    },
  },
  {
    key: 'feedingsPerDay',
    type: 'input',
    description: {
      label: 'Feedings',
      unit: 'per day',
    },
  },
  {
    key: 'targetSolidsOfFinalSolution',
    type: 'input',
    description: {
      label: 'Target % Solids of Final Solution',
    },
  },
  {
    key: 'targetVolPerFdgQtPerCalf',
    type: 'input',
    description: {
      label: 'Target volume per feeding Qt per calf',
      unit: 'per feeding',
    },
  },
  {
    key: 'poundsOfPowderNeededPerFdg',
    type: 'calculated',
    description: {
      label: 'Pounds of Powder Needed',
      unit: 'per feeding',
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'gallonsOfWaterAddedPerFdg',
    type: 'calculated',
    description: {
      label: 'Gallons of Water Added',
      unit: 'per feeding',
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'gallonsOfSolutionsPerFdg',
    type: 'calculated',
    description: {
      label: 'Gallons of Solution Needed',
      unit: 'per feeding',
    },
    calculatedType: 'primary',
    decimalPlaces: 1,
  },
  {
    key: 'quartsFedPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Quarts Fed',
      unit: 'per calf daily',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'poundsOfPowderFedPerCalfDaily',
    type: 'calculated',
    description: {
      label: 'Pounds of Powder Fed',
      unit: 'per calf daily',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'poundsOfPowderFedPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: 'Pounds of Powder Fed',
      unit: 'per calf per feeding',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'quartsFedPerCalfPerFdg',
    type: 'calculated',
    description: {
      label: 'Quarts Fed',
      unit: 'per calf per feeding',
    },
    calculatedType: 'secondary',
    decimalPlaces: 2,
  },
  {
    key: 'gallonsFedPerDay',
    type: 'calculated',
    description: {
      label: 'Gallons Fed',
      unit: 'per day',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },
  {
    key: 'lbPerGallon',
    type: 'calculated',
    description: {
      label: 'lb/gallon',
    },
    calculatedType: 'hidden',
    decimalPlaces: 4,
  },
  {
    key: 'wtOfDryMatterPerDay',
    type: 'calculated',
    description: {
      label: 'Wt of dry matter',
      unit: 'per day',
    },
    calculatedType: 'hidden',
    decimalPlaces: 2,
  },

  {
    key: 'wtOfDryMatterPerFdg',
    type: 'calculated',
    description: {
      label: 'Wt of dry matter',
      unit: 'per feeding',
    },
    calculatedType: 'hidden',
    decimalPlaces: 3,
  },
];

/* Function to calculate calculated CMR set solids field */
export const calculatedCMRSetSolidFields = (
  input: Record<CmrSetSolidsInputsKeyType, number | undefined>,
): Record<CmrSetSolidsOutputsKeyType, number | undefined> => {
  /* Object to store results after calculating fields based on provided logic */
  const results: Record<CmrSetSolidsOutputsKeyType, number | undefined> = {
    gallonsFedPerDay: undefined,
    gallonsOfSolutionsPerFdg: undefined,
    poundsOfPowderNeededPerFdg: undefined,
    poundsOfPowderFedPerCalfPerFdg: undefined,
    poundsOfPowderFedPerCalfDaily: undefined,
    gallonsOfWaterAddedPerFdg: undefined,
    lbPerGallon: undefined,
    wtOfDryMatterPerDay: undefined,
    wtOfDryMatterPerFdg: undefined,
    quartsFedPerCalfDaily: undefined,
    quartsFedPerCalfPerFdg: undefined,
  };

  if (
    input.targetVolPerFdgQtPerCalf &&
    input.feedingsPerDay &&
    input.noOfCalves &&
    input.targetSolidsOfFinalSolution
  ) {
    /* Variable to store converted percent value of 'targetSolidsOfFinalSolution' input field */
    const targetSolidsOfFinalSolutionPercentVal = input.targetSolidsOfFinalSolution / 100;

    /* Calculated fields according to calculations provided */
    results.gallonsFedPerDay = (input.targetVolPerFdgQtPerCalf * input.feedingsPerDay) / 4;

    results.quartsFedPerCalfDaily = 4 * results.gallonsFedPerDay;

    results.gallonsOfSolutionsPerFdg =
      (results.gallonsFedPerDay / input.feedingsPerDay) * input.noOfCalves;

    results.lbPerGallon =
      4 * (2.125 + 0.008 * (targetSolidsOfFinalSolutionPercentVal * 100 - 12.5));

    results.wtOfDryMatterPerDay =
      results.lbPerGallon * results.gallonsFedPerDay * targetSolidsOfFinalSolutionPercentVal;

    results.wtOfDryMatterPerFdg = results.wtOfDryMatterPerDay / input.feedingsPerDay;

    results.poundsOfPowderFedPerCalfPerFdg = results.wtOfDryMatterPerFdg / 0.97;

    results.poundsOfPowderFedPerCalfDaily =
      results.poundsOfPowderFedPerCalfPerFdg * input.feedingsPerDay;

    results.poundsOfPowderNeededPerFdg =
      (results.gallonsOfSolutionsPerFdg *
        results.lbPerGallon *
        targetSolidsOfFinalSolutionPercentVal) /
      0.9635;

    results.gallonsOfWaterAddedPerFdg =
      (results.gallonsOfSolutionsPerFdg * results.lbPerGallon -
        results.poundsOfPowderNeededPerFdg * 0.97) /
      8.272;

    results.quartsFedPerCalfPerFdg = input.targetVolPerFdgQtPerCalf;
  }

  return results;
};

export default null;
