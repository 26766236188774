import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Button } from 'antd';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import styles from './CaddieHeader.module.scss';
import useAuth from '../../utils/auth/useAuth';
import { logger } from '../../utils/helpers';
import logo from '../../assests/images/caddieLogo.png';

const CaddieHeader = () => {
  // location is object coming from useLocation hooks of react router dom
  const location = useLocation();

  // navigate variable contain useNavigate hooks
  const navigate = useNavigate();

  // extracting client from useApollo client
  const client = useApolloClient();

  // extracting logout function from useAuth hook
  const { logout } = useAuth();

  // When user click on the logo of the app then this function gets invoke
  const handleOnClickCaddie = () => {
    navigate('/');
  };

  return (
    <div className={styles.headerContainer}>
      {/* Title of the header bar */}
      <div
        onClick={handleOnClickCaddie}
        onKeyPress={handleOnClickCaddie}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <img width={150} src={logo} alt="caddie_logo" />
      </div>
      {/* Here is the menus which is display in the header */}
      <div className={styles.menuContainer}>
        <div className={styles.menuList}>
          <Link
            className={location.pathname.includes('farms') ? styles.linkActive : styles.link}
            to="/farms"
          >
            Farms
          </Link>
        </div>

        <Button
          onClick={async (): Promise<void> => {
            try {
              // reset apollo cache
              await client.resetStore();
              // Auth logout
              await logout();
              // navigating to root url, this is used to display proper route in url
              navigate('/');
            } catch (err) {
              logger(err as Error);
            }
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default CaddieHeader;
