import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import FarmForm from './forms/FarmForm';
import AllFarmsScreen from './screens/caddie/AllFarmsScreen';
import FarmLayout from './screens/caddie/FarmLayout';
import CalculatorsScreen from './screens/calf-milk-replacer/CalculatorsScreen';
import CalculatorsHistoryScreen from './screens/calf-milk-replacer/CalculatorsHistoryScreen';
import ColostrumReplacerLayout from './screens/colostrum-replacer/ColostrumReplacerLayout';
import CaddieHomeScreen from './screens/caddie/CaddieHomeScreen';
import CaddieHeader from './screens/caddie/CaddieHeader';
import CalfMilkReplacerLayout from './screens/calf-milk-replacer/CalfMilkReplacerLayout';
import ColostrumReplacerCalculator from './screens/colostrum-replacer/ColostrumReplacerCalculator';
import ColostrumReplacerHistory from './screens/colostrum-replacer/ColostrumReplacerHistory';
import ColostrumReplacerSettings from './screens/colostrum-replacer/ColostrumReplacerSettings';
import ColostrumReplacerPooledCalculator from './screens/colostrum-replacer/ColostrumReplacerPooledCalculator';
import ColostrumReplacerByCalfCalculator from './screens/colostrum-replacer/ColostrumReplacerByCalfCalculator';
import useAuth from './utils/auth/useAuth';
import RestrictedAccessScreen from './screens/caddie/RestrictedAccessScreen';

const AppRoutes: React.FC = () => {
  // destructuring from useAuth hook
  const { user } = useAuth();

  // render app routes only when user role includes caddie. else show restrictedAccessScreen
  if (
    user &&
    ((typeof user.role === 'string' && user.role.toLowerCase().includes('caddie')) ||
      (Array.isArray(user.role) &&
        user.role.length > 0 &&
        user.role.some((item) => item.toLowerCase().includes('caddie'))))
  ) {
    return (
      <BrowserRouter>
        <CaddieHeader />
        <Routes>
          <Route path="/" element={<CaddieHomeScreen />} />
          <Route path="/farms/*" element={<FarmLayout />}>
            <Route path="/" element={<AllFarmsScreen />} />
            <Route
              path="create"
              element={<FarmForm mode="add" inputsFlexDirection="row" paddingBottomInName={25} />}
            />
          </Route>

          {/* -------------------calf milk ration routes-------------------------- */}
          <Route path="/calf-milk-ration/*" element={<CalfMilkReplacerLayout />}>
            <Route path="/calculators/*" element={<CalculatorsScreen />}>
              <Route path="/" element={<CalculatorsScreen />} />
            </Route>
            <Route path="/history/*" element={<CalculatorsHistoryScreen />}>
              <Route path="/" element={<CalculatorsHistoryScreen />} />
            </Route>
            <Route path="*" element={<Navigate to="calculators" />} />
          </Route>

          {/* -------------------colostrum replacer routes-------------------------- */}
          <Route path="/colostrum-management/*" element={<ColostrumReplacerLayout />}>
            <Route
              path="/calculators/:farmId/pooled"
              element={<ColostrumReplacerPooledCalculator />}
            />
            <Route
              path="/calculators/:farmId/by-calf"
              element={<ColostrumReplacerByCalfCalculator />}
            />
            <Route path="/calculators" element={<ColostrumReplacerCalculator />} />
            <Route path="/history" element={<ColostrumReplacerHistory />} />
            <Route path="/settings/:farmId" element={<ColostrumReplacerSettings />} />
            <Route path="*" element={<Navigate to="calculators" />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }
  return <RestrictedAccessScreen />;
};

export default AppRoutes;
