import { Modal } from 'antd';
import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import FormItem from '../../components/FormItem';
import InputComponent from '../../components/InputComponent';
import {
  GenerateReportMutation,
  GenerateReportMutationVariables,
} from '../../graphql/graphql-types';
import { generatePdfFunc } from '../../utils/helpers';
import { CmrSetSolidsDataFieldsType } from '../../calculator/CMRSetSolids';
import { PmrbNOWaterDataFieldsType } from '../../calculator/PMRBNoWater';
import { PmAndWaterDataFieldsType } from '../../calculator/PMAndWater';

const generateReportMutation = loader('../../graphql/mutations/generateReportMutation.graphql');

/* Type for data which is required for report generation */
export type ReportGenerationDataType = {
  /* Store inputs data */
  inputsData: Record<string, string | number | undefined | null>;
  /* Stores calculated fields values */
  calculatedFieldsValue: Record<string, number | undefined>;
  /* Stores all field values based on calculator type */
  fieldValues: (
    | CmrSetSolidsDataFieldsType
    | PmrbNOWaterDataFieldsType
    | PmAndWaterDataFieldsType
  )[];
};

/* Type for ReportNameModalComponent prop */
type Props = {
  /* Prop to decide whether to show modal or not */
  showModal: boolean;
  /* Prop containing setState function to update visibility of modal */
  setShowModal: React.Dispatch<React.SetStateAction<ReportGenerationDataType | null>>;
} & ReportGenerationDataType;

/* Validation schema */
const schema = yup.object().shape({
  name: yup.string().required('Please enter a value and try again'),
});

/* React functional component */
const ReportNameModalComponent = ({
  showModal,
  setShowModal,
  inputsData,
  calculatedFieldsValue,
  fieldValues,
}: Props): JSX.Element => {
  /* State used to show loading indicator on save btn while mutation is in progress */
  const [showLoaderOnSave, setShowLoaderOnSave] = useState<boolean>(false);

  /* Mutation to generate current calculations report */
  const [generateReport] = useMutation<GenerateReportMutation, GenerateReportMutationVariables>(
    generateReportMutation,
  );

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>({ defaultValues: { name: '' }, resolver: yupResolver(schema) });

  return (
    <Modal
      destroyOnClose
      visible={showModal}
      closable={false}
      style={{ padding: 0 }}
      okText="Done"
      okButtonProps={{ className: 'buttonColorRed' }}
      onCancel={() => {
        setShowModal(null);
      }}
      confirmLoading={showLoaderOnSave}
      onOk={handleSubmit((formData) => {
        setShowLoaderOnSave(true);

        generatePdfFunc(
          setShowLoaderOnSave,
          { reportName: formData.name, ...inputsData },
          calculatedFieldsValue,
          fieldValues,
          generateReport,
          setShowModal,
        );
      })}
      width={450}
    >
      <FormItem
        label="Report Name"
        displayMode="column"
        isRequired
        errorText={errors.name?.message}
      >
        <InputComponent
          placeholder="Please enter a name for this report"
          name="name"
          rhfControllerProps={{ control }}
        />
      </FormItem>
    </Modal>
  );
};

export default ReportNameModalComponent;
