import { Enum_Measurement_System_Enum } from '@ecp32/calculations/src/graphql';
import { ByCalfColostrumReplacerDataType } from '../../calculator/colostrum-replacer-calculator/ByCalf';
import { PooledColostrumReplacerDataType } from '../../calculator/colostrum-replacer-calculator/Pooled';
import renderLabelBasedOnUnit from './renderLabelBasedOnUnit';

// type definition for colostrum replacer all fields label
type GetColostrumReplacerAllFieldsWithLabelsPropType = {
  // prop definition for all fields labels
  allFieldsLabels: PooledColostrumReplacerDataType[] | ByCalfColostrumReplacerDataType[];
};

// component to handle rendering of labels according to type of feeding done
// in by calf calculation, we have to render labels according to type of feeding done so we can render label for brix reading and previous brix reading by type of feeding which is handled in backend
const getColostrumReplacerAllFieldsWithLabels = ({
  allFieldsLabels,
}: GetColostrumReplacerAllFieldsWithLabelsPropType): {
  colostrumReplacerAllFieldsWithLabelsForUsCustomary: (
    | ByCalfColostrumReplacerDataType
    | PooledColostrumReplacerDataType
  )[];
  colostrumReplacerAllFieldsWithLabelsForMetric: (
    | ByCalfColostrumReplacerDataType
    | PooledColostrumReplacerDataType
  )[];
} => {
  // const to store input and output labels if unit is English which is used to display in report
  const colostrumReplacerAllFieldsWithLabelsForUsCustomary = allFieldsLabels.map(
    (item: ByCalfColostrumReplacerDataType | PooledColostrumReplacerDataType) => {
      if (typeof item.description.label === 'function') {
        return {
          ...item,
          description: {
            ...item.description,
            label: renderLabelBasedOnUnit(
              item.description.label,
              Enum_Measurement_System_Enum.UsCustomary,
              item.description.unitKey,
            ),
          },
        };
      }

      return item;
    },
  );

  // const to store input and output labels if unit is Metric which is used to display in report
  const colostrumReplacerAllFieldsWithLabelsForMetric = allFieldsLabels.map(
    (item: ByCalfColostrumReplacerDataType | PooledColostrumReplacerDataType) => {
      if (typeof item.description.label === 'function') {
        return {
          ...item,
          description: {
            ...item.description,
            label: renderLabelBasedOnUnit(
              item.description.label,
              Enum_Measurement_System_Enum.Metric,
              item.description.unitKey,
            ),
          },
        };
      }

      return item;
    },
  );

  return {
    colostrumReplacerAllFieldsWithLabelsForUsCustomary,
    colostrumReplacerAllFieldsWithLabelsForMetric,
  };
};

export default getColostrumReplacerAllFieldsWithLabels;
